import Chapter2_Darts1 from "../../BlockSpace/Exercises/Chapter2/Darts 1";
import C2_Errors from "../../BlockSpace/Exercises/Chapter2/Lessons/Introduction_Errors";
import C2_ML from "../../BlockSpace/Exercises/Chapter2/Lessons/Introduction_ML";
import C2_ML2 from "../../BlockSpace/Exercises/Chapter2/Lessons/Introduction_ML2";
import Chapter2_LinearParameter1 from "../../BlockSpace/Exercises/Chapter2/Linear Parameter 1";
import Chapter2_LinearParameter2 from "../../BlockSpace/Exercises/Chapter2/Linear Parameter 2";
import ExerciseScreen from "../../BlockSpace/Exercises/ExerciseScreen";
import { ChapterData } from "../../Data Structures";

const chapter2: ChapterData = {
    id: 2,
    title: "Parameters and Optimization",
    exercises: [
        { data: new Chapter2_Darts1() },
        { data: new Chapter2_LinearParameter1() },
        { data: new Chapter2_LinearParameter2() } 
    ],
    lessons: [
        <C2_Errors lessonNumber={1} />,
        <C2_ML />,
        <C2_ML2 />
    ],
    content: [
        { type: "lesson", itemIndex: 0, title: "Metric Functions" },
        { type: "exercise", itemIndex: 0, title: "Distance Metric" },
        { type: "exercise", itemIndex: 1, title: "Parameters" },
        // { type: "lesson", itemIndex: 1, title: "What is Machine Learning?" },
        { type: "exercise", itemIndex: 2, title: "Training a Model" }
    ]
}

export default chapter2;