import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import { Fragment, useEffect, useRef, useState } from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { DatasetMetadata, dataSource as fetchPublicDatasets } from "./DataSource"
import { TestCase } from "./Interfaces"
import { mnistData } from "../Datasets/MNIST_Dataset"
import * as tf from "@tensorflow/tfjs"
import TestTable from "./Exercises/TestTable"
import { cifar10Data } from "../Datasets/CIFAR10_Dataset"

interface Props {
    open: boolean
    existingSelection?: number[]
    onClose: (cases?: TestCase[]) => void
    datasetMetadata?: DatasetMetadata
}

function ExampleCaseSelector(props: Props) {
    const [testCases, setTestCases] = useState<TestCase[] | undefined>()
    const selection = useRef<number[]>(props.existingSelection ?? [])

    useEffect(() => {
        selection.current = []
        if (props.datasetMetadata?.id === "mnist") {
            mnistData(50).then(data => {
                setTestCases(data.map(example => ({
                    input: [tf.tensor(example.image)],
                    output: tf.tensor(example.label),
                    inputLabels: ["Image"],
                    outputLabel: "Digit",
                    inputTypes: ["grayscale_image"]
                })))
            })
        } else if (props.datasetMetadata?.id === "cifar-10") {
            cifar10Data(50).then(data => {
                setTestCases(data.map(example => ({
                    input: [tf.tensor(example.image)],
                    output: tf.tensor(example.label),
                    inputLabels: ["Image"],
                    outputLabel: "Class ID",
                    inputTypes: ["rgb_image"]
                })))
            })
        }
    }, [props.datasetMetadata?.id])

    if (props.datasetMetadata === undefined) { return <Fragment /> }
    return <Dialog open={props.open} onClose={() => props.onClose(selection.current.map(i => testCases![i]))} fullWidth maxWidth="md">
        <DialogTitle>{`Configure example cases for ${props.datasetMetadata.name}`}</DialogTitle>
        <Stack sx={{borderRadius: 2, minHeight: "200px", overflowY: "auto"}} mx={2} px={1}>
            <TestTable showDisplayOptions enableMultiselect selectionChanged={s => selection.current = s} testCases={testCases} currentSelection={selection.current} />
        </Stack>
    </Dialog>
}

export default ExampleCaseSelector;