import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";
import Markdown from "react-markdown";
import * as tf from "@tensorflow/tfjs"
import EmbeddedPlayground from "../../../EmbeddedPlayground/EmbeddedPlayground";

class C1_TensorDims extends LessonTemplate {
    
    constructor(props: LessonTemplateProps) {
        super(props, 3, "Tensor Shapes and Dimensions")
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            return <Fragment>
                <MarkdownTextView rawText={"### Dimension / Axis\nIn the previous lesson, you learned that tensors are blocks of numbers with an arbitrary number of dimensions. The flexibility of tensors allows us to reason about numbers abstractly and manipulate them in powerful ways. But first, we need to build some terminology around tensors. Let's consider this 2D tensor (i.e. matrix):"}/>
                <div className="inline-table">
                    <MarkdownTextView rawText="$M = \phantom{ }$"/> <table className="generic-table">
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <MarkdownTextView rawText={"Here are are things we can say about the structure of this tensor $M$:\n- It has a **shape** of $(2, 3)$. Note that when we talk about the shape of a matrix, the row always comes first, followed by the column. It's just a convention.\n- The **first dimension/axis** of $M$ has a length of 2 (dimension and axis mean the same thing in the context of tensors).\n- Dimension 0 of $M$ has a length of 2 (dimensions are indexed from 0, so dimension 0 is the first dimension).\n- The **second dimension/axis** of $M$ has a length of 3.\n- The **last dimension/axis** of $M$ has a length of 3 (because $M$ only has 2 dimensions, the second dimension *is* the last dimension).\n- $M$ has a **size** of 6. The **size** of a tensor is the number of elements it has."} />

                <MarkdownTextView rawText={"### Negative dimensions\nSometimes it's more convenient to count the dimensions backward, especially when the total number of dimensions of the tensor is uncertain. We use $-1$ to denote the last dimension, $-2$ to denote the second last dimension, etc. So for the tensor $M$ given above, we can say that *dimension -1 has a length of 3*."} />

                <MultipleChoiceQuestion prompt="What is the length of dimension -2 for $M$?" options={["1", "2", "3", "4"]} correctIndex={1} explanation="Dimension -2 is the same as dimension 0, or the first dimension, in this case. $M$ has two rows, so the length is 2." />

                <MultipleChoiceQuestion prompt="If dimension -2 of a tensor is the same as its dimension 2, what is the total number of dimensions the tensor has?" options={["2", "3", "4", "5"]} correctIndex={2} explanation="The tensor must have 4 dimensions, because only then is the third dimension (index 2) the same as the second-last dimension (-2)." />
            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <MarkdownTextView rawText={"### Reading shapes\nIn the Playground, there are two easy ways to view the shape of a tensor. One is to click on the eye icon to toggle between the shape view and the value view (which you just unlocked), and the other is to pass the tensor into a *Shape Reader* block, then display it.\n\nWhen building models, it's important to be aware of the shape of the tensor at each stage of the pipeline. You can't always tell the shape of a tensor by its values.\n\nHere, try viewing the shapes of those tensors. Does any result surprise you? Matrices have a shape of length 2, vectors have a shape of length 1, and constants have an empty shape (hence no shape value to display)."} />
                <EmbeddedPlayground id="shape_reader" title="Using the Shape Reader" savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 50, y: 100 },
                            quotaId: "constant",
                            customName: "Matrix",
                            value: {
                                value: [[1,2,3],[4,5,6]]
                            }
                        },
                        {
                            position: { x: 75, y: 215 },
                            quotaId: "constant",
                            customName: "Vector",
                            value: {
                                value: [1, 2]
                            }
                        },
                        {
                            position: { x: 110, y: 310 },
                            quotaId: "constant",
                            customName: "Scalar",
                            value: {
                                value: 10
                            }  
                        },
                        {
                            position: { x: 310, y: 150 },
                            quotaId: "shape_reader",
                        },
                        {
                            position: { x: 500, y: 178 },
                            quotaId: "tensor_viewer",
                            customName: "Output"
                        }
                    ]
                }} passCriterion={output => true} />
            </Fragment>
        } else if (index === 2) {
            return <Fragment>
                <MarkdownTextView rawText={"### Indexing an element\nAgain, here's the same tensor from last page:"} />
                <div className="inline-table">
                    <MarkdownTextView rawText="$M = \phantom{ }$"/> <table className="generic-table">
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <MarkdownTextView rawText={"Each element in a tensor resides at a unique address called an *index*. An index is a sequence of numbers used to specify a particular location in a tensor. The $i$-th number in the index sequence is the position of the element in the $i$-th dimension, starting from 0. For example, 6 is located at $(1, 2)$."} />
                <MultipleChoiceQuestion prompt="What is the index of 4 in the tensor $M$?" options={["$(1, 2)$", "$(2, 1)$", "$(0, 1)$", "$(1, 0)$"]} correctIndex={3} explanation="4 is on the second row (index 1 along first dimension) and first column (index 0 along second dimension), forming the index of $(1, 0)$." />
            </Fragment>
        } else {
            return <Fragment />
        }
    }
}

export default C1_TensorDims;