import * as React from 'react';
import PrivateComponent from '../PrivateComponent/PrivateComponent';

interface NotFoundProps {
    
}
 
interface NotFoundState {
    
}
 
class NotFound extends PrivateComponent<NotFoundProps, NotFoundState> {
    constructor(props: NotFoundProps) {
        super(props);
        this.state = { };
        this.requiresAuthentication = false;
        this.isLoading = false;
    }
    safeRender() { 
        return <div className='full-screen'>
            <div className='center-content'>
                Not Found
            </div>
        </div>;
    }
}
 
export default NotFound;