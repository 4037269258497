import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Pagination from "@mui/material/Pagination"
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";
import Markdown from "react-markdown";

interface State {
    currentPage: number
}

class C1_Sapwood_Rings extends LessonTemplate {
    
    currentError = Number.POSITIVE_INFINITY

    constructor(props: LessonTemplateProps) {
        super(props, 2, "Sapwood Rings")        
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            return <Fragment>
                <MarkdownTextView rawText="If you look into a cross section of a tree trunk, you'll see that the inside is divided into two areas, *sapwood* and *heartwood*."/>
                <img src="/assets/chapter1/tree-cross-section.jpg" width="70%" className="centered" />
                <MarkdownTextView rawText={"According to [a study](https://wfs.swst.org/index.php/wfs/article/view/2053), for a type of tree called *Populus Tremuloides*, the number of sapwood rings ($r$) can be predicted from tree age ($t$) according to the formula\n$$\nr = 4.26 + 0.4t.\n$$\n\nIn this exercise, we will practice using the AI Crafting Table to build a model that uses this formula to predict the number of sapwood rings a *Populus Tremuloides* tree has based on its age."} />
            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <MarkdownTextView rawText={"#### What is AI Crafting Table?\n\nThis is the tool we will be using to build and visualize AI models. Here's a very simple model that adds two numbers $a$ and $b$:"} />
                <img src="/assets/chapter1/add_network.png" width="75%" className="centered" />
                <p className="image-caption"><MarkdownTextView rawText="Visualization of $a+b$ in the crafting table. Inputs come in from the left and the result of the computation is sent to the output on the right." /></p>
                <MarkdownTextView rawText={"The output happens to be 151 in this case because the inputs (not shown in the screenshot) are $a=75, b=76$.\n\n#### Constants\nYou can also use fixed numbers, also known as *constants*, in your model. For example, the model below multiplies the given input $a$ by 2:"} />
                <img src="/assets/chapter1/multiply_constant.png" width="75%" className="centered" />
                <MarkdownTextView rawText="$a$ happens to be $45$ in this case, hence the output $90$." />
                <a href="/chapters/1/exercises/1" style={{textDecoration: "none"}}>
                    <button className="next-button">Open Exercise</button>
                </a>
            </Fragment>
        } else {
            return <Fragment />
        }
    }
}

export default C1_Sapwood_Rings;