import React, { useEffect, useState } from "react"
import Check from "@mui/icons-material/CheckCircle"
import Cross from "@mui/icons-material/Cancel"
import "./MultipleChoiceQuestion.css"
import MarkdownTextView from "../MarkdownTextView/MarkdownTextView"


interface Props {
    prompt: string
    options: string[]
    correctIndex: number
    explanation?: string
    onCorrect?: () => void
}

const checkmark = <Check htmlColor="#40a845" fontSize="small" sx={{marginLeft: "2px", height: "17px"}} />
const cross = <Cross htmlColor="#c0605a" fontSize="small" sx={{marginLeft: "2px", height: "17px"}} />

export default function MultipleChoiceQuestion(props: Props) {
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>()

    useEffect(() => {
        if (selectedIndex === props.correctIndex) {
            props.onCorrect?.()
        }
    }, [selectedIndex])

    return <div className="mcq">
        <div className="mcq-header">💡 Check Your Understanding</div>
        <div className="mcq-prompt">
            <MarkdownTextView rawText={props.prompt} /></div>
        <div className="mcq-options">
            {props.options.map((opt, i) => {
                const className = selectedIndex === props.correctIndex ? "correct-button" : "incorrect-button"
                const icon = selectedIndex === props.correctIndex ? checkmark : cross
                return <button key={i} className={selectedIndex === i ? className : ""} onClick={() => setSelectedIndex(i)}>
                    <MarkdownTextView rawText={opt}/>
                {selectedIndex !== undefined && selectedIndex === i ? icon : undefined}</button>
            })}
        </div>
        { props.explanation && selectedIndex === props.correctIndex && <div className="explanation">
            <MarkdownTextView rawText={"**Explanation**: " + props.explanation} />
        </div> }
    </div>
}