import Konva from "konva";
import Endpoint from "./Endpoint";
import Receptor from "./Receptor";

export default class Connection {
    start: Endpoint
    end: Receptor
    line: Konva.Arrow

    constructor(start: Endpoint, end: Receptor) {
        this.start = start
        this.end = end
        const { x: x1, y: y1 } = start.element.absolutePosition()
        const { x: x2, y: y2 } = end.element.absolutePosition()
        const { x: offsetX, y: offsetY } = start.parent.globalState.stage!.offset()
        this.line = new Konva.Arrow({
            points: [x1 + offsetX, y1 + offsetY, x2 + offsetX, y2 + offsetY],
            stroke: "#606060",
            fill: "#606060",
            pointerWidth: 6,
            pointerLength: 8,
            lineCap: "round",
            visible: Math.abs(x1 - x2) > 1 || Math.abs(y1 - y2) > 1
        })
        this.setActive(!!start.currentValue)
    }

    updateVisibility() {
        const { x: x1, y: y1 } = this.start.element.absolutePosition()
        const { x: x2, y: y2 } = this.end.element.absolutePosition()

        this.line.visible(Math.abs(x1 - x2) > 1 || Math.abs(y1 - y2) > 1)
    }

    setActive(active: boolean) {
        if (!active) {
            this.line.dash([5, 5])
            this.line.opacity(0.5)
        } else {
            this.line.dash([])
            this.line.opacity(1)
        }
    }
    
    get id() {
        return `${this.start.parent.id}[${this.start.index}]->${this.end.parent.id}[${this.end.index}]` 
    }
}