import Konva from "konva";
import Block from "../Block";
import ContentBlock from "../ContentBlock";
import { round } from "mathjs";

const rowHeight = 70

interface SliderParameter {
    name: string
    minValue: number
    maxValue: number
    initialValue: number
    onChange: (value: number) => void
}

const trackLeft = 80

class ParameterTuner extends ContentBlock {
    
    get blockName(): string {
        return "Parameter Tuner"
    }

    get displayedName(): string {
        return this.blockName
    }

    parameters: SliderParameter[]
    currentValues: number[]

    sliders: {
        track: Konva.Line
        knob: Konva.Circle
        name: Konva.Text
        value: Konva.Text
        group: Konva.Group
    }[] = []

    get trackRight(): number {
        return this.container.width() - 25
    }

    constructor(id: string, parameters: SliderParameter[]) {
        super(id, 450, 25 + parameters.length * rowHeight, {
            backgroundColor: "#e5f4d7",
            titleBarColor: "#40404020"
        })

        this.parameters = parameters
        this.currentValues = parameters.map(x => x.initialValue)

        this.sliders = parameters.map((p, i) => {
            const group = new Konva.Group({ x: 0, y: i * rowHeight + this.titleBar.height(), height: rowHeight, width: this.container.width() })
            const track = new Konva.Line({lineCap: "round"});
            const initialX = trackLeft + (this.trackRight - trackLeft) * (p.initialValue - p.minValue) / (p.maxValue - p.minValue)
            track.points([
                trackLeft, group.height() / 2,
                this.trackRight, group.height() / 2
            ])
            track.strokeWidth(3)
            track.stroke("#80808070")
            track.on("mousedown", e => {
                knob.x(e.target.getRelativePointerPosition()!.x)
                knob.fire("dragmove")
            })

            const knob = new Konva.Circle({draggable: true, shadowEnabled: true, shadowColor: "#5C9FC570", shadowBlur: 5, shadowOffsetY: 1})
            knob.fill("#4C8FB5")
            knob.radius(10)
            knob.x(initialX)
            knob.y(group.height() / 2)
            knob.on("dragmove", e => {
                knob.y(group.height() / 2)
                if (knob.x() < trackLeft || knob.x() > this.trackRight) {
                    knob.x(Math.max(trackLeft, Math.min(this.trackRight, knob.x())))
                }
                parameterValue.x(knob.x())
                const value = round(p.minValue + (knob.x() - trackLeft) / (this.trackRight - trackLeft) * (p.maxValue - p.minValue), 3)
                parameterValue.text(`${value}`)
                p.onChange(value)
            })
            knob.on("mouseup", e => {
                if (this.globalState) {
                    this.globalState.activeExercise?.saveToLocal(this.globalState)
                }
            })

            const parameterName = new Konva.Text({
                x: 10,
                y: group.height() / 2
            })
            parameterName.offsetY(parameterName.height() / 2)
            parameterName.text(p.name)

            const parameterValue = new Konva.Text({
                x: initialX,
                y: group.height() / 2 - 28,
                width: 100,
                offsetX: 50,
                align: "center",
                text: `${p.initialValue}`
            })

            group.add(track)
            group.add(knob)
            group.add(parameterName)
            group.add(parameterValue)
            this.element.add(group)

            return {
                track: track,
                knob: knob,
                name: parameterName,
                value: parameterValue,
                group
            }
        })
    }

    getDocumentation(): string {
        return `A parameter tuner is a device that uses sliders to control the values of parameters in a network.`
    }

    async loadStateDict(data: Record<string, any>): Promise<void> {
        try {
            this.sliders.forEach(({knob, group, name, track, value: parameterValue}, i) => {
                const p = this.parameters[i]
                const initialX = trackLeft + (this.trackRight - trackLeft) * ((data.values[i] ?? p.initialValue) - p.minValue) / (p.maxValue - p.minValue)
                knob.x(initialX)
                if (knob.x() < trackLeft || knob.x() > this.trackRight) {
                    knob.x(Math.max(trackLeft, Math.min(this.trackRight, knob.x())))
                }
                parameterValue.x(knob.x())
                const value = round(p.minValue + (knob.x() - trackLeft) / (this.trackRight - trackLeft) * (p.maxValue - p.minValue), 3)
                parameterValue.text(`${value}`)
            })
        } catch (error) {
            console.warn("Failed to load state dict for parameter tuner", data, error)
        }
    }

    async getStateDict() {
        return {
            values: this.sliders.map(({ knob }, i) => {
                const p = this.parameters[i]
                const value = p.minValue + (knob.x() - trackLeft) / (this.trackRight - trackLeft) * (p.maxValue - p.minValue)
                return value
            })
        }    
    }
}

export default ParameterTuner;