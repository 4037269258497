import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { grey } from "../Theme/palette"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import {Icon} from "@iconify/react"

interface Props {
    title: string
    collapsedTitle?: string
    expandTitle?: string
    onAddButtonPressed?: () => void
    isOpened: boolean
    setIsOpened: (opened: boolean) => void
    children: JSX.Element
}

export default function CollapsibleSection({ title, isOpened, setIsOpened, children, expandTitle, collapsedTitle, ...props }: Props) {
    return <Box sx={{
        marginTop: "10px",
    }}>
        <Button variant="soft" sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "32px",
            textAlign: "left",
            fontSize: 14,
            fontWeight: 500,
            color: "white",
            backgroundColor: "#f0f7ff60",
            "&:hover": {
                backgroundColor: "#f0f7ff6a"
            },
            borderRadius: 1.5,
            px: 1,
            alignItems: "center"
        }} disableFocusRipple onClick={(e) => setIsOpened(!isOpened)}>
            <Typography flexGrow={1}>{title}</Typography>
            <Typography variant="caption" fontSize={14} px={1} color={"#f0f0f0a0"}>{isOpened ? expandTitle ?? "" : collapsedTitle ?? expandTitle}</Typography>
            {props.onAddButtonPressed && isOpened && <IconButton onClick={e => {
                e.stopPropagation()
                props.onAddButtonPressed!()
            }} onMouseDown={e => e.stopPropagation()} sx={{mx: 1.5}}>
                <AddIcon scale={1.1} />
            </IconButton>}
            <Box
                width={18}
                height={18}
                className="arrow"
                sx={{rotate: isOpened ? "0deg" : "-180deg", transitionDuration: "0.2s"}}
                icon={'eva:arrow-ios-downward-fill'}
                component={Icon}
            />
        </Button>
        <Collapse in={isOpened}>
            {/* <Box px={0} py={2} pb={1} sx={{minHeight: "min-content"}}> */}
                {children}
            {/* </Box> */}
        </Collapse>
    </Box>
}