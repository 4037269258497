import { Rank, SymbolicTensor, Tensor } from "@tensorflow/tfjs"
import Block from "../Block";
import Konva from "konva";
import Endpoint from "../Endpoint";
import Receptor from "../Receptor";
import { TransposeLayer } from "../../Layers/Arithmetics";
import ArithmeticBlock from "./ArithmeticBlock";

class Transpose extends ArithmeticBlock {

    type_id = "transpose"
    layer = new TransposeLayer()

    get displayedName() { return "Transpose Gate" }

    constructor(id: string) {
        super(id)

        this.triangle.points([0, 0, 70, 35, 0, 70])
        this.element!.width(70)
        this.element!.height(70)

        Konva.Image.fromURL("/assets/blocks/transpose.svg", img => {
            img.scale({x: 1.2, y: 1.2})
            img.x(10)
            img.y(this.triangle.height() / 2)
            img.offsetY(img.height() / 2)
            this.element?.add(img)
        })

        this.inputs = [
            new Receptor(this, 0, 0, this.triangle.height() / 2),
        ]
        this.outputs = [
            new Endpoint(this, 0, this.triangle.width(), this.triangle.height() / 2)
        ]
    }

    onInputUpdated(index: number): boolean {
        if (this.allRequiredInputsProvided) {
            this.currentValue = this.layer.apply(this.inputs[0].currentValue!) as Tensor | SymbolicTensor
        } else {
            this.currentValue = this.inputs[0].currentValue
        }
        return this.outputs[0].propagate(this.currentValue)
    }
}

export default Transpose;