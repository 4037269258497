import axios from "axios"
import { shuffle } from "../Utils"

export interface HumanActivityData {
    input: number[][]
    label: number
}

let HumanActivity_Train_Images: HumanActivityData[] = []
let HumanActivity_Test_Images: HumanActivityData[] = []

export const CIFAR10_LABELS = ["walking", "walking upstairs", "walking downstairs", "sitting", "standing", "laying"]

export async function activityData(count: number, isEval = false) {
    const cachedData = isEval ? HumanActivity_Test_Images : HumanActivity_Train_Images
    if (cachedData.length > 0) {
        return cachedData.slice(0, count)
    }

    let promise: Promise<any[]>
    if (isEval) {
        promise = Promise.all([
            axios(`/datasets/activity_recognition/body_acc_x_test.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_acc_y_test.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_acc_z_test.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_gyro_x_test.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_gyro_y_test.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_gyro_z_test.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/y_test.txt`, { responseType: "text" })
        ])
    } else {
        let filesToLoad = [
            axios(`/datasets/activity_recognition/body_acc_x_train.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_acc_y_train.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_acc_z_train.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_gyro_x_train.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_gyro_y_train.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/body_gyro_z_train.txt`, { responseType: "text" }),
            axios(`/datasets/activity_recognition/y_train.txt`, { responseType: "text" })
        ]
        promise = Promise.all(filesToLoad)
    }
    const loadedImages = await promise.then(async results => {
        const totalAccX = results[0].data.split("\n") as string[]
        const totalAccY = results[1].data.split("\n") as string[]
        const totalAccZ = results[2].data.split("\n") as string[]
        const gyroX = results[3].data.split("\n") as string[]
        const gyroY = results[4].data.split("\n") as string[]
        const gyroZ = results[5].data.split("\n") as string[]
        const labels = results[6].data.split("\n") as string[]

        let allData: HumanActivityData[] = Array(labels.length);
        let order = labels.map((_, i) => i)
        if (isEval) {
            order = shuffle(order)
        }
        for (let i = 0; i < labels.length; i++) {
            allData[order[i]] = {
                input: [
                    totalAccX[i].trim().split(/\s+/).map(Number),
                    totalAccY[i].trim().split(/\s+/).map(Number),
                    totalAccZ[i].trim().split(/\s+/).map(Number),
                    gyroX[i].trim().split(/\s+/).map(Number),
                    gyroY[i].trim().split(/\s+/).map(Number),
                    gyroZ[i].trim().split(/\s+/).map(Number)
                ],
                label: Number(labels[i]) - 1 // So that the labels are 0-indexed
            }
        }
        console.log(allData)
        console.log(`loaded ${allData.length} examples from Human Activity dataset`)

        return allData
    })

    if (isEval) {
        HumanActivity_Test_Images = loadedImages
    } else {
        HumanActivity_Train_Images = loadedImages
    }
    
    return loadedImages.slice(0, count)
}