import { Layer } from "konva/lib/Layer";
import TabView from "../../../Components/TabView/TabView";
import { ValueStore, InspectorProps, TestResult, TestCaseResult } from "../../Interfaces";
import LibraryItem from "../../../Components/LibraryItem/LibraryItem";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { createBlockFromString } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { random, round } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { MultiplyConstantLayer, NegationLayer } from "../../Layers/Arithmetics";
import ParameterTuner from "../../Nodes/Training/Tuner";
import { v4 as uuid } from "uuid"
import Parameter from "../../Nodes/Training/Parameter";


class Chapter2_LinearParameter1 extends ExerciseData {

    title = "Parameter Tuning"
    exercise_id = "linear_parameter_1"
    
    defaultBlocks: {
        inputBlock?: SymbolicInput
        outputBlock?: OutputBlock
        A?: Parameter
        tuner?: ParameterTuner
    } = {}

    constructor() {
        super()

        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`When the correct values are not known, it can take a long time to find the correct parameter values by trial and error. Therefore, you bought a parameter tuner. This device has sliders that can easily adjust the parameter values, so you don't have to set them by hand.

In this exercise, your task is to approximate the true value of the parameter $A$. Let's practice using the parameter tuner to get this done fast.
`} />
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    async generateTestCases(count?: number) {
        const inputs = random([count ?? 5], -50, 50)
        const correctValue = -0.427
        this.trainingCases = inputs.map(x => { return { input: [tf.tensor(x)], output: tf.tensor(x * correctValue) } })
        return this.trainingCases
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "multiply": {count: 1},
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        // Test
        this.defaultBlocks.outputBlock = createBlockFromString("tensor_viewer", { customName: "Output 1" }, "outputBlock") as OutputBlock
        this.defaultBlocks.outputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.outputBlock, 700, 350)

        this.defaultBlocks.inputBlock = createBlockFromString("symbolic", { customName: "Input 1" }, "inputBlock") as SymbolicInput
        this.defaultBlocks.inputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.inputBlock, 200, 400)

        this.defaultBlocks.A = createBlockFromString("parameter", { value: { value: 0}, customName: "A" }, "A") as Parameter
        this.defaultBlocks.A.editable = false
        this.addBlockAtPosition(this.defaultBlocks.A, 270, 300)

        this.defaultBlocks.tuner = new ParameterTuner("tuner", [
            {
                name: "A",
                initialValue: 0,
                minValue: -1,
                maxValue: 1,
                onChange: (value) => {
                    if (this.defaultBlocks.A) {
                        const newTensor = tf.tensor(value)
                        this.defaultBlocks.A.setNewValue(newTensor)
                    }
                }
            }
        ])
        this.defaultBlocks.tuner.editable = false
        this.addBlockAtPosition(this.defaultBlocks.tuner, 400, 600)
    }

    onTestCasesUpdated(): void {
        if (this.defaultBlocks.inputBlock === undefined || this.trainingCases === undefined) {
            return
        }
        if (this.activeExampleIndices.length === 0) {
            this.defaultBlocks.inputBlock!.applyConcreteValue(undefined)
        } else {
            const filteredInputs = this.activeExampleIndices.map(i => this.trainingCases![i].input[0])
            const combined = tf.stack(filteredInputs, 0)
            this.defaultBlocks.inputBlock!.applyConcreteValue(combined)
        }
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        if (!this.defaultBlocks.inputBlock || !this.defaultBlocks.outputBlock) {
            console.warn("Exercise blocks not initialized")
            return
        }

        // Get model by passing in symbolic input
        this.defaultBlocks.inputBlock.applyConcreteValue(undefined)
        const symbolicOutput = this.defaultBlocks.outputBlock.inputs[0].currentValue as tf.SymbolicTensor
        this.onTestCasesUpdated()
        try {
            var model = tf.model({ inputs: [this.defaultBlocks.inputBlock.value], outputs: symbolicOutput }) 
            tf.serialization.registerClass(MultiplyConstantLayer)
            await model.save(`indexeddb://models/${this.exercise_id}`)
        } catch {
            alert("Output is not connected to inputs")
            return
        }
        

        this.worker = new Worker(new URL("./Linear Parameter 1 Grader.ts", import.meta.url))
        this.worker.onmessage = e => {
            onProgressUpdated(e.data)
        }
        this.worker.postMessage({ save_path: `indexeddb://models/${this.exercise_id}` })
    }
}

export default Chapter2_LinearParameter1;