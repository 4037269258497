let cn_mapping: Record<string, string> = {
    ".": "。",
    "Account Settings": "账号设置",
    "All": "所有内容",
    "Already have an account?": "已有账号？",
    "Announcements": "公告",
    "Assignments": "任务",
    "Archived Classes": "往期课程",
    "Back": "返回",
    "Back to Course Homepage": "回到课程主页",
    "Back to Questions": "返回练习主页",
    "Change Password": "更改密码",
    "Change": "更改",
    "Changing...": "更改中...",
    "Class Overview": "课程主页",
    "Coding Exercises": "编程练习题",
    "Create Account": "领航熊科技 - 注册",
    "Create your account": "创建新账号",
    "Current Password": "当前密码",
    "Dashboard": "课程主页",
    "Don't have an account?": "没有账号？",
    "Email": "邮箱",
    "Email address": "邮箱",
    "Email Sent": "邮件已发出",
    "Enter your email": "请输入邮箱",
    "Exit Full Screen": "退出全屏",
    "Finished": "已完成",
    "Forgot password?": "忘记密码?",
    "Full screen": "进入全屏",
    "General": "通用",
    "Help": "提问",
    "Help Center" : "帮助中心",
    "Inbox": "收件箱",
    "Incorrect username or password.": "用户名或密码错误。",
    "Instructions": "作业说明",
    "Loading...": "加载中...",
    "Late Submission": "迟交",
    "Loading Document...": "文档加载中...",
    "Loading slides...": "文档加载中...",
    "Lesson Plan": "教案",
    "Let's code the world together!": "科技筑梦，领航未来",
    "Login": "领航熊科技 - 登录",
    "Logout": "注销",
    "Materials": "课程",
    "Messages": "消息",
    "My Classes": "我的课程",
    "My Computer Science Classes": "我的计算机课程",
    "My Messages": "消息中心",
    "New Password": "新密码",
    "Next Up": "接下来",
    "No Announcements.": "暂无公告。",
    "No settings available.": "暂无设置选项。",
    "There are no messages to display.": "暂无消息。",
    "No Submission": "未提交",
    "No upcoming assignments.": "无即将来临的作业。",
    "No Upcoming Tasks.": "暂无任务。",
    "Not Released": "未发布",
    "No text to show.": "无内容可显示。",
    "Overview": "课程概述",
    "Page": "页码",
    "Password": "密码",
    "Password has been successfully updated.": "密码已成功更改。",
    "Password must be at least 8 characters long.": "密码需要至少包含 8 个字符。",
    "Released": "已发布",
    "Reset Password": "重置密码",
    "Remember me": "记住我",
    "Rendering slides...": "图片重新渲染中...",
    'Saved' : "已保存",
    'Saving...': "保存中...",
    "Send Email": "发邮件",
    "Sent": "已发送",
    "Set New Password": "重置密码",
    "Show Archived Classes": "显示往期课程",
    "Show Overview for Students": "显示学生的内容",
    "Sign In": "登录",
    "Signing in...": "正在登录...",
    "Sign in to Ursa Coding": "登录领航熊教学平台",
    "Sign in here": "点击这里登陆",
    "Sign up here": "点击这里注册",
    "Sign Up": "注册",
    "Student Overview": "学生看到的内容",
    "Student submissions": "学生作业",
    "Submit": "提交",
    "Submitted": "已提交",
    "Resubmit": "重新提交",
    "Ursa Coding": "领航熊科技",
    "View Slides": "展示幻灯片",
    "Welcome Home": "领航熊科技",
    "Welcome to Ursa Coding": "欢迎使用领航熊教学平台"
}

let linghangwan_mapping: Record<string, string> = {
    "Welcome Home": "领航湾科技",
    "/assets/logo.png": "/assets/leadnext.png",
    "Welcome to Ursa Coding": "欢迎使用领航湾教学平台",
    "Ursa Coding": "领航湾科技",
    "Login": "领航湾科技 - 登录",
    "Create Account": "领航湾科技 - 注册",
}

export function localizedString(s: string): string {
    if (window.location.hostname.endsWith('linghangwan.com')) {
        return linghangwan_mapping[s] || cn_mapping[s] || s
    } else if (window.location.hostname.endsWith('linghangxiong.com')) {
        return cn_mapping[s] || s
    } else {
        return s
    }
}

export function isChinese(): boolean {
    return window.location.hostname.endsWith('linghangxiong.com') || window.location.hostname.endsWith('linghangwan.com');
}

export function domain(df?: string): string {
    let components = (df ?? window.location.hostname).split(".")
    return components[components.length - 2] + "." + components[components.length - 1]
}