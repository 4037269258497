import React, { useEffect, useRef } from 'react';
import "./LibraryItem.css"

export default function LibraryItem(props: { title: string, type: string, data?: any, count: number }) {
    const itemRef = useRef<HTMLDivElement>(null)
    
    useEffect(() => {
        itemRef.current!.addEventListener("dragstart", e => {
            e.dataTransfer?.setData("text/plain", props.type)
            if (props.data !== undefined) {
                e.dataTransfer?.setData("application/json", JSON.stringify(props.data))
            }
        })
    }, [])
    
    const draggable = props.count === undefined || props.count > 0
    // return <div className='library-item' draggable={draggable} style={{cursor: draggable ? "grab" : "not-allowed"}} ref={itemRef}>
    //     <span style={{ opacity: draggable ? 1.0 : 0.5 }}>{props.count === Infinity ? props.title : `${props.title} (${props.count})`}</span>
    // </div>
    let iconPath = "stack.png"
    switch (props.type) {
        case "add":
            iconPath = "add.png"
            break;
        default:
            break;
    }

    return <div className='library-item' draggable={draggable} style={{cursor: draggable ? "grab" : "not-allowed"}} ref={itemRef}>
        <img
            src={`/icons/${iconPath}`}
            width={36}
            draggable={false}
        />
        <div
            className="item-details"
            style={{
            flexGrow: 1,
            rowGap: 3,
            minWidth: 0 /* Ensures it can shrink */,
            }}
        >
            <div style={{ fontWeight: 700, fontSize: 15 }}>
                {props.title}
            </div>
            <div
                style={{
                    fontSize: "12px",
                    opacity: 0.8,
                    maxWidth: "100%", // Ensure this element cannot exceed its parent’s width
                }}
            >
                {"Description of the block.\nCan span multiple lines."}
            </div>
        </div>
        <div style={{ opacity: draggable ? 1.0 : 0.5, textAlign: "right", marginRight: 10 }}>{props.count}</div>
    </div>
}