import Konva from "konva";
import TabView from "../../Components/TabView/TabView";
import LibraryItem from "../../Components/LibraryItem/LibraryItem";
import { BLOCK_TYPES, InspectorProps, SaveState, TestCase, TestCaseResult, TestResult, ValueStore } from "../Interfaces";
import { Layer } from "konva/lib/Layer";
import Block from "../Nodes/Block";
import demoModel from "./demo_model.json"
import { addBlockAtPosition, restoreFromSaveState, serializeToJSON } from "../Utils";
import Collapse from "@mui/material/Collapse"
import CollapsibleSection from "../../Components/collapsible-section";
import { useBoolean } from "../../use-boolean";
import { axiosInstance } from "../../Utils";
import { useParams } from "react-router-dom";

export interface ItemInfo { count: number, data?: any, displayedTitle?: string }

export default class ExerciseData {

    layer!: Layer
    valueStore!: ValueStore
    setShowInspector!: (value: boolean) => void
    setInspectorView!: (view?: InspectorProps) => void
    instructions: JSX.Element
    trainingCases?: TestCase[]
    cachedTestCases?: TestCaseResult[]
    activeExampleIndices: number[] = []
    title = "Example Exercise"
    exercise_id = "exercise_id"
    defaultBlocks: Record<string, any> = {}
    worker?: Worker
    quota = this.getInitialQuota()
    showDisplayOptions = true
    enableCaseMultiselect = true

    constructor() {
        const quota = this.getInitialQuota()

        this.instructions = <div className='instructions'>
            <h3>Exercise 1</h3>
        </div>
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {}
    }

    getLibraryViewWithQuota(quota: Record<string, ItemInfo>) {
        let ops: JSX.Element[] = []
        let inputs: JSX.Element[] = []
        let outputs: JSX.Element[] = []
        let layers: JSX.Element[] = []
        let tabs: { title: string, view: JSX.Element[] }[] = []
        let length = 0

        // Basic Operations
        for (const key in quota) {
            const { count, data, displayedTitle } = quota[key];   
            if (key in BLOCK_TYPES.basicOps) {
                ops.push(<LibraryItem data={data} title={displayedTitle ?? BLOCK_TYPES.basicOps[key]} type={key} count={count} key={key} />)
            } else if (key in BLOCK_TYPES.inputs) {
                inputs.push(<LibraryItem data={data} title={displayedTitle ?? BLOCK_TYPES.inputs[key]} type={key} count={count} key={key} />)
            } else if (key in BLOCK_TYPES.outputs) {
                outputs.push(<LibraryItem data={data} title={displayedTitle ?? BLOCK_TYPES.outputs[key]} type={key} count={count} key={key} />)
            } else if (key in BLOCK_TYPES.layers) {
                layers.push(<LibraryItem data={data} title={displayedTitle ?? BLOCK_TYPES.layers[key]} type={key} count={count} key={key} />)
            }
        }
        if (ops.length > 0) {
            tabs.push({ title: "Basic Operations", view: ops })
        }

        if (inputs.length > 0) {
            tabs.push({ title: "Inputs and Data", view: inputs })
        }

        if (outputs.length > 0) {
            tabs.push({ title: "Outputs and Visualization", view: outputs })
        } if (layers.length > 0) {
            tabs.push({ title: "Neural Network Layers", view: layers })
        }

        return <>
            {tabs.map(tab => <View title={tab.title} view={tab.view} key={tab.title}/>)}
        </>

        // return <TabView tabs={tabs}/>
    }

    async generateTestCases(): Promise<TestCase[]> {
        this.trainingCases = []
        console.warn("Did not override generateTestCases()")
        return []
    }

    getClassLabels(): string[] | undefined {
        return undefined
    }

    setup(layer: Konva.Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps) => void) {
        this.layer = layer
        this.valueStore = store
        this.setShowInspector = setShowInspector
        this.setInspectorView = setInspectorView
        document.title = this.title
    }

    addBlockAtPosition(block: Block, targetX?: number, targetY?: number, disableOffset?: boolean) {
        return addBlockAtPosition(this.valueStore, this.layer, block, this.setInspectorView, this.setShowInspector, targetX, targetY, disableOffset)
    }

    onTestCasesUpdated() {
        console.warn("Subclass did not override onTestCasesUpdated()")
    }

    /**
     * API for running a test suite for an exercise.
     * @param onProgressUpdated Callback function for testing progress updates.
     */
    async assess(onProgressUpdated: (result: TestResult) => void) {
        console.warn("Subclass did not override assess()")
    }

    stop() {
        this.worker?.terminate()
    }

    // async restoreFromLocal() {
    //     let saved = localStorage.getItem(`saved_data/${this.exercise_id}`)
    //     if (saved === null && this.exercise_id === "demo") {
    //         saved = JSON.stringify(demoModel)
    //     }
    //     if (saved !== null) {
    //         try {
    //             this.quota = await restoreFromSaveState(JSON.parse(saved), this.layer, this.getInitialQuota(), this.valueStore, this.setInspectorView, this.setShowInspector, this.defaultBlocks)
    //         } catch (err) {
    //             console.warn(err)
    //             console.warn("Cache corrupted. Skipping restoration.")
    //         }
    //     }
    // }

    async restoreFromCloud(saveState: SaveState | null) {
        if (saveState) {
            try {
                this.quota = await restoreFromSaveState(saveState, this.layer, this.getInitialQuota(), this.valueStore, this.setInspectorView, this.setShowInspector, this.defaultBlocks)
            } catch (err) {
                console.warn(err)
                console.warn("Saved state corrupted. Skipping restoration.")
            }
        }
    }

    async saveToLocal(valueStore: ValueStore) {
        const obj = await serializeToJSON(valueStore)
        localStorage.setItem(`saved_data/${this.exercise_id}`, JSON.stringify(obj))
    }

    async saveToCloud(valueStore: ValueStore) {
        const obj = await serializeToJSON(valueStore)
        await axiosInstance.post(`/chapters/${valueStore.chapterId}/exercises/${this.exercise_id}/save`, {
            state: obj
        }).catch((error) => console.warn(error))
    }

    // async restoreFromJSON(obj: SaveState) {
    //     this.quota = await restoreFromSaveState(obj, this.getInitialQuota(), this.valueStore, this.defaultBlocks)
    // }
    
    
}

function View(props: { title: string, view: JSX.Element[] }) {
    const isOpened = useBoolean(true)
    return <div>
        <CollapsibleSection isOpened={isOpened.value} setIsOpened={isOpened.setValue} title={props.title} expandTitle={`${props.view.length}`} >
            <div style={{transitionDuration: "0.2s", "opacity": isOpened.value ? 1.0 : 0.0}}>
                {props.view}
            </div>
        </CollapsibleSection>
    </div>
}