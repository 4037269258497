import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Pagination from "@mui/material/Pagination"
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";

interface State {
    currentPage: number
}

const Arrow = () => {
    return (
      <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
        {/* Arrow line */}
        <line x1="10" y1="50" x2="90" y2="50" stroke="black" strokeWidth="2" />
  
        {/* Arrowhead */}
        <polygon points="95,50 80,45 80,55" fill="black" />
      </svg>
    );
};

function ExampleTabs() {
    const [currentTab, setCurrentTab] = useState(0)
    return <Box my={3} borderRadius={3} sx={{backgroundColor: "#f0f0f0"}} p={2} pt={1} minHeight={300}>
            <Tabs centered value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
                <Tab label="Example 1" id="tab1" />
                <Tab label="Example 2" id="tab2" />
                <Tab label="Example 3" id="tab3" />
            </Tabs>
            <div className='example-view'>
                {((i: number) => {
                    if (i === 0) {
                        return <div>
                            <h4>Chatbots</h4>
                            <div style={{display: "flex", height: "100%", alignItems: "center", justifyContent: "space-evenly"}}>
                                <div>"Hi"</div>
                                <Arrow />
                                <img src="/assets/gpt-3.5-turbo.png" width="100" />
                                <Arrow />
                                <div>"Hello! How can I assist you today?"</div>
                            </div>
                        </div>
                    } else if (i === 1) {
                        return <div>
                            <h4>Speech Recognition</h4>
                            Input: (sound wave) <br />
                            Output: "Turn off the lights."
                        </div>
                    } else {
                        return <div>
                            <h4>Medical Diagnosis</h4>
                            Input: X-ray image<br />
                            Output: diagnosis result (Cancer / no cancer)
                        </div>
                    }
                    return <Fragment />
                })(currentTab)}
            </div>
        </Box>
}

class C0_Intro extends LessonTemplate {
    
    currentError = Number.POSITIVE_INFINITY

    constructor(props: LessonTemplateProps) {
        super(props, 3, "What is AI?")     
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            const weightColor = "#6085bf"
            const scoreColor = "#f7b020"
            return <Fragment>
                <p>
                    Artificial Intelligence has led to incredible technological achievements in the 21th century. Let's look at some examples:
                </p>
                <ExampleTabs />
                <MarkdownTextView rawText="An **AI model** is an intelligent, data-processing machine. Some data go in, and some other data come out. All of the tasks above can be achieved an AI model. An **AI system** is a computer system that contains one or more models. For example, ChatGPT not only has a model for generating text, but also has a model for checking whether the generated text is safe for the user to see. If the AI's reply contains inappropriate content, then it cannot be shown to the user." />
            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <MarkdownTextView rawText={"### How is AI different from computer programs\n\nAn AI model distinguishes itself from a computer program in that it is *learned* not *taught*. The fact that AI can learn and improve itself by observing and extracting patterns from its data is what makes it *AI*. The process of learning from data is called **machine learning**."} />
                
                <img src="/assets/chapter1/rabbit.webp" width={"70%"} className="centered" />
                <p className="image-caption">A rabbit playing guitar, rendered by stable diffusion. [<a href="https://www.reddit.com/r/StableDiffusion/comments/15x8t2q/psa_the_dev_branch_of_a1111_is_now_good_at_sdxl/">Source</a>]</p>
                <MarkdownTextView rawText={"Machine learning has led to countless tremendous breakthroughs in the 21th century, such as *AlphaGo*, *ChatGPT*, and *Stable Diffusion*. None of these models are programmed by explicit rules:\n\n- AlphaGo learned Go by watching millions of matches;\n- ChatGPT learned to chat by reading virtually all the text on the internet, plus human feedback;\n- Stable Diffusion learned to generate images by re-creating them from their captions.\n\n ### What is not AI\n\nAny technology whose functionality is explicitly programmed (i.e. not learned from data), is not AI. Popular examples that are not AI include:\n- *Google Chrome*\n- *Minecraft*\n- *Adobe Photoshop*, prior to version 25 (in version 25 it started to have AI tools)\n- *Microsoft Word*"} />

                <MultipleChoiceQuestion prompt="Which app on an iPhone uses (the most) AI technology? Take a guess." correctIndex={1} options={["Weather", "Photos", "Calculator", "Calendar"]} explanation="The Photos app uses face recognition technology to identify people in your photos and help you group photos by who's in them. [\[Read more\]](https://machinelearning.apple.com/research/recognizing-people-photos)" />
            </Fragment>
        } else if (index === 2) {
            return <Fragment>
                <MarkdownTextView rawText="At *AI Playground*, you will learn how AI models work by building them to solve problems! Prior coding experience is not needed." />
                <a href="/chapters/1/lessons/2" style={{textDecoration: "none"}}>
                    <button className="next-button">Next</button>
                </a>
            </Fragment>
        } else {
            return <Fragment />
        }
    }
}

export default C0_Intro;