import { Layer } from "konva/lib/Layer";
import TabView from "../../../Components/TabView/TabView";
import { ValueStore, InspectorProps, TestResult, TestCaseResult } from "../../Interfaces";
import LibraryItem from "../../../Components/LibraryItem/LibraryItem";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { createBlockFromString } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { randomInt } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { AddConstant, MultiplyConstantLayer, NegationLayer } from "../../Layers/Arithmetics";
import Literal from "../../Nodes/Inputs/Literal";

class Chapter1_SapwoodRings extends ExerciseData {

    title = "Sapwood Rings"
    exercise_id = "sapwood_rings"

    defaultBlocks: {
        inputBlock1?: SymbolicInput
        coefficient?: Literal
        intercept?: Literal
        outputBlock?: OutputBlock
    } = {}

    constructor() {
        super()

        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`Construct a model that predicts the number of sapwood rings a tree has using the formula \n$$\nr = 0.4t + 4.26.\n$$\nThe $0.4$ in the equation is often called the *slope*, and the $4.26$ is often called the *intercept*.`} />
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    // async generateTestCases() {
    //     const inputs = randomInt([5], -999, 999)
    //     this.currentTestCases = inputs.map(x => { return { input: tf.tensor(x), output: tf.tensor(x + 1) } })
    //     return this.currentTestCases
    // }

    async generateTestCases(count?: number) {
        const input1 = randomInt([count ?? 5], 1, 100)
        this.trainingCases = input1.map(x => {
            const in1 = tf.tensor(x)
            const out = in1.mul(0.4).add(4.26)
            return { input: [in1], output: out, inputLabels: ["Tree Age"], outputLabel: "# of Rings" }
        })
        return this.trainingCases
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "add": {count: 2},
            "multiply": {count: 2},
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        // Test
        this.defaultBlocks.outputBlock = createBlockFromString("tensor_viewer", { customName: "Output (r)" }, "outputBlock") as OutputBlock
        this.defaultBlocks.outputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.outputBlock, 700, 250 + this.defaultBlocks.outputBlock.contentHeight / 2)

        this.defaultBlocks.inputBlock1 = createBlockFromString("symbolic", {customName: "t"}, "inputBlock1") as SymbolicInput
        this.defaultBlocks.inputBlock1.editable = false
        this.addBlockAtPosition(this.defaultBlocks.inputBlock1, 200, 250)

        this.defaultBlocks.coefficient = createBlockFromString("constant", {customName: "slope", value: { value: 0.4} }, "slope") as Literal
        this.defaultBlocks.coefficient.editable = false
        this.addBlockAtPosition(this.defaultBlocks.coefficient, 220, 350 + this.defaultBlocks.coefficient.contentHeight / 2)

        this.defaultBlocks.intercept = createBlockFromString("constant", {customName: "intercept", value: { value: 4.26} }, "intercept") as Literal
        this.defaultBlocks.intercept.editable = false
        this.addBlockAtPosition(this.defaultBlocks.intercept, 220, 440 + this.defaultBlocks.intercept.contentHeight / 2)
    }

    onTestCasesUpdated(): void {
        if (this.defaultBlocks.inputBlock1 === undefined || this.trainingCases === undefined) {
            return
        }
        if (this.activeExampleIndices.length === 0) {
            this.defaultBlocks.inputBlock1!.applyConcreteValue(undefined)
        } else {
            let inputs: tf.Tensor[][] = [[]]
            const filteredInputs = this.activeExampleIndices.map(i => this.trainingCases![i].input)
            filteredInputs.forEach((tsArray, i) => {
                tsArray.forEach((ts, j) => {
                    inputs[j].push(ts)
                })
            })
            this.defaultBlocks.inputBlock1!.applyConcreteValue(tf.stack(inputs[0], 0))
        }
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        super.assess(onProgressUpdated)
        if (!this.defaultBlocks.inputBlock1 || !this.defaultBlocks.outputBlock) {
            console.warn("Exercise blocks not initialized")
            return
        }

        // Get model by passing in symbolic input
        const inputList = [this.defaultBlocks.inputBlock1]
        inputList.forEach(input => input.applyConcreteValue(undefined))
        const symbolicOutput = this.defaultBlocks.outputBlock.inputs[0].currentValue as tf.SymbolicTensor
        this.onTestCasesUpdated()
        try {
            var model = tf.model({ inputs: inputList.map(x => x.value), outputs: symbolicOutput }) 
            tf.serialization.registerClass(AddConstant)
            tf.serialization.registerClass(MultiplyConstantLayer)
            await model.save(`indexeddb://models/${this.exercise_id}`)
            // console.log(model.call(tf.tensor([1]), {}))
        } catch {
            alert("Output is not connected to inputs")
            return
        }

        this.worker = new Worker(new URL("./SapwoodRingsGrader.ts", import.meta.url))
        this.worker.onmessage = e => {
            onProgressUpdated(e.data)
        }
        this.worker.postMessage({ save_path: `indexeddb://models/${this.exercise_id}` })
    }
}

export default Chapter1_SapwoodRings;