import * as React from 'react';
import PrivateComponent from '../../PrivateComponent/PrivateComponent';
import { ChapterPreview } from '../../Data Structures';
import ChapterPreviewCell from './ChapterPreviewCell/ChapterPreviewCell';
import "./HomePage.css"
import chapters from '../Content/Chapters';
import { useAuth } from '../../Auth/auth-provider';
import useSWR from 'swr';
import { fetcher } from '../../Utils';
import LoadingScreen from '../../Components/LoadingScreen';

interface HomePageProps {
    
}
 
function HomePage(props: HomePageProps) {
    const { data, isLoading: isLoading, error } = useSWR("/chapters/list", fetcher);
    
    if (isLoading) {
        return <LoadingScreen />
    }

    const chapters = data as ChapterPreview[]

    return <div className='full-screen' style={{padding: '20px'}}>
        <h3>My Learning Progress</h3>
        <div className='chapter-container'>
            {chapters!.map((c, i) => <ChapterPreviewCell key={i} index={i} data={c} locked={!c.unlocked} />)}
        </div>
        {/* <h3>My Playgrounds</h3>
        <div className='chapter-container'>
            <div>
                <button style={{ height: 180, width: 180, border: "none", borderRadius: 8, fontWeight: 600, backgroundColor: "#f0f0f0", fontSize: 16 }} onClick={e => window.location.assign("/playground/new")}>
                    + New
                </button>
            </div>
        </div> */}
    </div>;
}
 
export default HomePage;