import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Pagination from "@mui/material/Pagination"
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";
import Markdown from "react-markdown";
import EmbeddedPlayground from "../../../EmbeddedPlayground/EmbeddedPlayground";

interface State {
    currentPage: number
}

class C0_Tutorial1 extends LessonTemplate {
    
    constructor(props: LessonTemplateProps) {
        super(props, 4, props.title ?? "Welcome to AI Playground")   
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            return <Fragment>
                <MarkdownTextView rawText={"#### Introduction\n\nThe Playground is a visual programming interface designed for creating AI models easily by assembling *blocks*. There are many block types out there, including numbers, math operations, and even AI algorithms! As you progress, you will gradually unlock more and block types, enabling you to build more powerful AI systems.\n\nIn the playground, you can connect two blocks, say `A` and `B`, by dragging a red dot on the *right* of block `A` (called an outlet) to a red dot on the *left* of block `B` (called an receptor). This action will create an arrow from `A` to `B`. **The direction of the arrow is the flow of information**, meaning that the result of block `A` is passed to block `B`, like an assembly line."} />

                <MarkdownTextView rawText="Without further ado, try connecting the block with value 3 to the output block on the right:" />

                <EmbeddedPlayground height={350} id="tutorial1" title="Connecting Blocks" savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 100, y: 75 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 1
                            },
                            customName: "A",
                        },
                        {
                            position: { x: 100, y: 175 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 2
                            },
                            customName: "B",
                        },
                        {
                            position: { x: 100, y: 275 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 3
                            },
                            customName: "C",
                        },
                        {
                            position: { x: 450, y: 175 },
                            quotaId: "tensor_viewer",
                            customName: "Output",
                            value: {
                                enableToggle: false
                            }
                        }
                    ]
                }} passCriterion={output => output === 3} />

                <MarkdownTextView rawText={`What you just created is an extremely simple AI model that always predicts 3 among the possible choices 1, 2, and 3. You could imagine this model being a AI player for rock-paper-scissors that always plays scissors, where rock = 1, paper = 2, and scissors = 3. Of course, it's not very intelligent, and you shouldn't expect it to be given that you just started. But we'll get there.\n\nLet's look at the two types of blocks that are present here, **constant** and **output**. A constant block holds any value that you assign to it, but its value **cannot change on its own**, hence the name *constant*. The **output** block is also a very important because it should contain the final result of your model's prediction, and will be used to check the correctness of your model in later exercises.\n\n#### Replacing & removing connections\nYou can replace a connection by dragging another outlet to the same receptor. Try dragging 2 to the output and see what happens.\n\nRemoving a connection is the same as creating a connection, just drag 2 to the output block again, and the arrow will disappear.`} />
            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <MarkdownTextView rawText={"### Binary Operations\nSome blocks require two inputs, like *Add* and *Multiply*. We call them **binary operations**. Here, try using an Add block to send the number **6** to the output by combining two of the existing numbers. *You can use the same number twice*."} />
                <EmbeddedPlayground height={450} id="tutorial2" title="Using the Add block" savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 100, y: 75 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 1
                            },
                            customName: "A",
                        },
                        {
                            position: { x: 100, y: 175 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 2
                            },
                            customName: "B",
                        },
                        {
                            position: { x: 100, y: 275 },
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 3
                            },
                            customName: "C",
                        },
                        {
                            position: { x: 100, y: 375 },
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 4
                            },
                            customName: "D",
                        },
                        {
                            position: { x: 250, y: 200 },
                            quotaId: "add"
                        },
                        {
                            position: { x: 450, y: 225 },
                            quotaId: "tensor_viewer",
                            customName: "Output",
                            value: {
                                enableToggle: false
                            }
                        }
                    ]
                }} passCriterion={answer => answer === 6} />
            </Fragment>
        } else if (index === 2) {
            return <Fragment>
                <MarkdownTextView rawText={"#### Combining Operations\nYou can feed the output of a block as the input of another block, assembling them to form more complex operations. You might also find it helpful to rearrange the blocks by dragging them.\n\nGiven the following blocks, can you figure out how to output **15**? Again, remember you can feed the same value to multiple receptors."} />
                <EmbeddedPlayground height={450} id="tutorial3" title="Using multiple operations" savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 100, y: 75 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 1
                            },
                            customName: "A",
                        },
                        {
                            position: { x: 100, y: 175 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 2
                            },
                            customName: "B",
                        },
                        {
                            position: { x: 100, y: 275 },
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 3
                            },
                            customName: "C",
                        },
                        {
                            position: { x: 100, y: 375 },
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 4
                            },
                            customName: "D",
                        },
                        {
                            position: { x: 250, y: 90 },
                            quotaId: "add"
                        },
                        {
                            position: { x: 250, y: 290 },
                            quotaId: "multiply"
                        },
                        {
                            position: { x: 450, y: 225 },
                            quotaId: "tensor_viewer",
                            customName: "Output",
                            value: {
                                enableToggle: false
                            }
                        }
                    ]
                }} passCriterion={output => output === 15} />
            </Fragment>
        } else if (index === 3) {
            return <Fragment>
                <MarkdownTextView rawText={"#### Non-commutative operations\nSome binary operations like subtraction and division care about the order of the inputs. Specifically, notice how $a+b = b+a$ but $a-b \\ne b-a$. In the playground, the receptors of those operations are ordered *from top to bottom*. So to compute $a-b$, you would link $a$ to the top receptor of a Subtract block and $b$ to the bottom receptor.\n\nUsing this information, let's practice using subtraction and division. In this mini exercise, your goal is to get to **2.5** using the provided blocks."} />
                <EmbeddedPlayground height={350} id="noncommutative" title="Using subtraction and division" savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 100, y: 75 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 1
                            },
                            customName: "A",
                        },
                        {
                            position: { x: 100, y: 175 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 2
                            },
                            customName: "B",
                        },
                        {
                            position: { x: 100, y: 275 },
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 3
                            },
                            customName: "C",
                        },
                        {
                            position: { x: 250, y: 90 },
                            quotaId: "subtract"
                        },
                        {
                            position: { x: 250, y: 200 },
                            quotaId: "divide"
                        },
                        {
                            position: { x: 450, y: 175 },
                            quotaId: "tensor_viewer",
                            customName: "Output",
                            value: {
                                enableToggle: false
                            }
                        }
                    ]
                }} passCriterion={output => output === 2.5} />
            </Fragment>
        } else {
            return <Fragment />
        }
    }
}

export default C0_Tutorial1;