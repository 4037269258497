import Chapter0_Tutorial from "../../BlockSpace/Exercises/Chapter0/C0_tutorial";
import C0_Intro from "../../BlockSpace/Exercises/Chapter0/Lessons/Intro";
import C0_Tutorial1 from "../../BlockSpace/Exercises/Chapter0/Lessons/Tutorial 1";
import C0_Unary from "../../BlockSpace/Exercises/Chapter0/Lessons/Unary operations";
import Chapter0_ScalarNegation from "../../BlockSpace/Exercises/Chapter0/ScalarNegation";
import Chapter1_AverageTotalScore from "../../BlockSpace/Exercises/Chapter1/AverageTotalScore";
import C1_NumberRepresentation from "../../BlockSpace/Exercises/Chapter1/Lessons/Number Representation";
import C1_SumAndMean from "../../BlockSpace/Exercises/Chapter1/Lessons/Sum and mean";
import C1_TensorDims from "../../BlockSpace/Exercises/Chapter1/Lessons/Tensor Dimensions";
import Chapter1_TensorCreation from "../../BlockSpace/Exercises/Chapter1/TensorCreation";
import Chapter2_LinearParameter2 from "../../BlockSpace/Exercises/Chapter2/Linear Parameter 2";
import Chapter3_MNIST1 from "../../BlockSpace/Exercises/Chapter3/MNIST";
import ExerciseData from "../../BlockSpace/Exercises/ExerciseData";

const LESSON_MAP: Record<string, JSX.Element> = {
    ai_intro: <C0_Intro />,
    playground_tutorial: <C0_Tutorial1 />,
    unary_operations: <C0_Unary />,

    numbers: <C1_NumberRepresentation />,
    tensor_dims: <C1_TensorDims />,
    sum_and_mean: <C1_SumAndMean />
}

export const EXERCISE_MAP: Record<string, typeof ExerciseData> = {
    tutorial_exercise: Chapter0_Tutorial,
    plus_one_negate: Chapter0_ScalarNegation,
    tensor_creation: Chapter1_TensorCreation,
    average_total_score: Chapter1_AverageTotalScore,

    train_single_parameter: Chapter2_LinearParameter2,

    mnist1: Chapter3_MNIST1
}

export default LESSON_MAP;