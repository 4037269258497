import * as React from 'react';

interface NotFoundProps {
    
}
 
interface NotFoundState {
    
}
 
class NotFound extends React.Component<NotFoundProps, NotFoundState> {
    constructor(props: NotFoundProps) {
        super(props);
        this.state = { };
    }
    render() { 
        return <div className='full-screen'>
            <div className='center-content'>
                Maintenance
            </div>
        </div>;
    }
}
 
export default NotFound;