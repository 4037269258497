import Chapter1_DotProduct from "../../BlockSpace/Exercises/Chapter1/DotProduct";
import C1_DotProduct from "../../BlockSpace/Exercises/Chapter1/Lessons/Dot_Product";
import C0_Intro from "../../BlockSpace/Exercises/Chapter0/Lessons/Intro";
import C1_NumberRepresentation from "../../BlockSpace/Exercises/Chapter1/Lessons/Number Representation";
import C1_Sapwood_Rings from "../../BlockSpace/Exercises/Chapter1/Lessons/Sapwood Rings";
import Chapter1_SapwoodRings from "../../BlockSpace/Exercises/Chapter1/SapwoodRings";
import ExerciseScreen from "../../BlockSpace/Exercises/ExerciseScreen";
import { ChapterData } from "../../Data Structures";

const chapter1: ChapterData = {
    id: 1,
    title: "Foundation of Computation",
    exercises: [
        { data: new Chapter1_SapwoodRings() },
        { data: new Chapter1_DotProduct() }
    ],
    lessons: [
        <C0_Intro />,
        <C1_NumberRepresentation />,
        <C1_Sapwood_Rings />,
        <C1_DotProduct />
    ],
    content: [
        { type: "lesson", itemIndex: 0, title: "Welcome to AI Playground" },
        { type: "lesson", itemIndex: 1, title: "Understanding Numbers" },
        { type: "lesson", itemIndex: 2, title: "1. Sapwood Rings" },
        { type: "exercise", itemIndex: 0, title: "1. Sapwood Rings" },
        { type: "lesson", itemIndex: 3, title: "2. Calculating Student Grades" },
        { type: "exercise", itemIndex: 1, title: "2. Calculating Student Grades" }
    ]
}

export default chapter1;