import { Layer } from "konva/lib/Layer";
import TabView from "../../../Components/TabView/TabView";
import { ValueStore, InspectorProps, TestResult, TestCaseResult, SaveState } from "../../Interfaces";
import LibraryItem from "../../../Components/LibraryItem/LibraryItem";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { createBlockFromString } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { random, randomInt, round } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { MultiplyConstantLayer, NegationLayer } from "../../Layers/Arithmetics";
import ParameterTuner from "../../Nodes/Training/Tuner";
import { v4 as uuid } from "uuid"
import Parameter from "../../Nodes/Training/Parameter";
import Literal from "../../Nodes/Inputs/Literal";
import { LossLayer } from "../../Layers/Loss Functions";

// Computes distances to origin
class Chapter2_Darts1 extends ExerciseData {

    defaultBlocks: {
        input1?: SymbolicInput
        input2?: SymbolicInput
        output?: OutputBlock
    } = {}
    title = "Distance Metric"
    exercise_id = "distance_metric1"

    constructor() {
        super()
        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`Build a model that helps Alice, Bob, and Charlie calculate their darts' distance to the origin.\n\n In each example below, $X$ is a length-3 vector describing the x-coordinates of the three darts, and $Y$ is a length-3 vector describing their y-coordinates. Your model should output a length-3 vector with the three darts' distance to the origin.`} />
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    async generateTestCases(count?: number) {
        const inputs = randomInt<number[][]>([[count ?? 5], [3], [2]], -20, 20)
        this.trainingCases = inputs.map(x => {
            const in1 = tf.tensor(x)
            const col1 = in1.slice([0, 0], [3, 1]).flatten()
            const col2 = in1.slice([0, 1], [3, 1]).flatten()
            const out = col1.square().add(col2.square()).sqrt()
            return { input: [col1, col2], output: out, inputLabels: ["X", "Y"], outputLabel: "Distance" }
        })

        return this.trainingCases
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "add": {count: 2},
            "sum": {count: 1},
            "negate": { count: 2},
            "mean": { count: 1 },
            "sqrt": { count: 1},
            "square": { count: 2},
            "multiply": {count: 1},
            "tensor_viewer": {count: 1},
            "divide": {count: 1},
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        this.defaultBlocks.output = createBlockFromString("tensor_viewer", { customName: "Output" }, "output") as OutputBlock
        this.defaultBlocks.output.editable = false
        this.addBlockAtPosition(this.defaultBlocks.output, 700, 300)

        this.defaultBlocks.input1 = createBlockFromString("symbolic", { value: { shape: [3]}, customName: "X" }, "input1") as SymbolicInput
        this.defaultBlocks.input1.editable = false
        this.addBlockAtPosition(this.defaultBlocks.input1, 200, 300)
        
        this.defaultBlocks.input2 = createBlockFromString("symbolic", { value: { shape: [3]}, customName: "Y" }, "input2") as SymbolicInput
        this.defaultBlocks.input2.editable = false
        this.addBlockAtPosition(this.defaultBlocks.input2, 200, 400)
    }

    onTestCasesUpdated(): void {
        if (this.defaultBlocks.input1 === undefined || this.trainingCases === undefined) {
            return
        }
        if (this.activeExampleIndices.length === 0) {
            this.defaultBlocks.input1!.applyConcreteValue(undefined)
            this.defaultBlocks.input2!.applyConcreteValue(undefined)
        } else {
            const filteredInputs1 = this.activeExampleIndices.map(i => this.trainingCases![i].input[0])
            const combined1 = tf.stack(filteredInputs1, 0)
            this.defaultBlocks.input1!.applyConcreteValue(combined1)

            const filteredInputs2 = this.activeExampleIndices.map(i => this.trainingCases![i].input[1])
            const combined2 = tf.stack(filteredInputs2, 0)
            this.defaultBlocks.input2!.applyConcreteValue(combined2)
        }
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        if (!this.defaultBlocks.input1 || !this.defaultBlocks.input2 || !this.defaultBlocks.output) {
            console.warn("Exercise blocks not initialized")
            return
        }

        // Get model by passing in symbolic input
        this.defaultBlocks.input1.applyConcreteValue(undefined)
        this.defaultBlocks.input2.applyConcreteValue(undefined)
        const symbolicOutput = this.defaultBlocks.output.inputs[0].currentValue as tf.SymbolicTensor
        // this.onTestCasesUpdated()
        try {
            var model = tf.model({ inputs: [this.defaultBlocks.input1.value, this.defaultBlocks.input2.value], outputs: symbolicOutput }) 
            this.onTestCasesUpdated()
            tf.serialization.registerClass(LossLayer)
            await model.save(`indexeddb://models/${this.exercise_id}`)
        } catch (error) {
            console.warn(error)
            alert("Output is not connected to inputs")
            return
        }
        

        this.worker = new Worker(new URL("./Darts 1 Grader.ts", import.meta.url))
        this.worker.onmessage = e => {
            onProgressUpdated(e.data)
        }
        this.worker.postMessage({ save_path: `indexeddb://models/${this.exercise_id}` })
    }
}

export default Chapter2_Darts1;