import * as Konva from "konva"
import Block from "./Block";
import Endpoint from "./Endpoint";
import Connection from "./Connection";
import { distance } from "../Utils";
import { LayersModel, SymbolicTensor, Tensor, TensorLike } from "@tensorflow/tfjs";
import Predictor from "./Outputs/Predictor";
import { CustomTFDataset, ReceptorType } from "../Interfaces";

export default class Receptor {
    parent: Block
    index: number
    isRequired: boolean
    connection?: Connection
    connectionList: Connection[] = [] // add-on for receptors supporting multiple inputs
    deletedConnection?: Connection // If a connection is just deleted, will appear here during propagation
    tentativeConnection?: Connection // Connection object before it is confirmed
    currentValue?: Tensor | SymbolicTensor | null
    currentModel?: LayersModel | null
    currentDataset?: CustomTFDataset | null
    flowId?: string
    name?: string
    type: ReceptorType
    allowMultiple: boolean

    element = new Konva.default.Group()
    ring = new Konva.default.Circle({
        radius: 6,
        visible: false,
        stroke: "#d05040",
        opacity: 0.5,
        strokeWidth: 1.2
    })

    circle = new Konva.default.Circle({
        fill: "#d05040",
        opacity: 0.7,
        radius: 4
    })

    line = new Konva.default.Line({
        stroke: "#a0a0a0",
        opacity: 0.4,
        dash: [10, 10],
        lineCap: "round"
    })

    anchor = new Konva.default.Circle({
        fill: "#d05040",
        opacity: 0,
        radius: 6,
        draggable: true,
        visible: false
    })

    mouseIn = false

    constructor(parent: Block, parentIndex: number, x: number, y: number, name?: string, type?: ReceptorType, isRequired?: boolean, allowMultiple = false) {
        this.parent = parent
        this.index = parentIndex
        this.name = name
        this.type = type ?? "tensor"
        this.isRequired = isRequired ?? true
        this.element.add(this.ring)
        this.element.add(this.circle)
        this.element.add(this.anchor)
        this.element.add(this.line)
        this.element.x(x)
        this.element.y(y)
        this.allowMultiple = allowMultiple
        this.element.on("mouseenter", (e) => {
            this.parent.element?.draggable(false)
            this.mouseIn = true
            if (e.evt.buttons === 0) {
                // Case 1: user is not dragging
                this.ring.visible(true)
                this.ring.opacity(0.5)
                this.circle.opacity(1)
                this.anchor.visible(true)
                this.showToolTip()
            } else {
                // Case 2: user enters receptor while pressing down the mouse
            }
        })
        this.element.on("mousedown", () => {
            this.ring.opacity(0.75)
        })
        this.element.on("mouseup", () => {
            this.ring.opacity(0.5)
        })
        this.element.on("mouseleave", (e) => {
            this.ring.visible(false)
            this.anchor.visible(false)
            this.parent.element?.draggable(true)
            if (e.evt.buttons === 0) this.circle.opacity(0.7)
            this.mouseIn = false
            this.parent.globalState.hideTooltip!()
        })

        this.anchor.on("dragstart", () => {
            this.line.visible(true)
        })
        this.anchor.on("dragmove", (e) => {
            this.line.points([0, 0, this.anchor.x(), this.anchor.y()])

            const target = (this.parent.globalState.availableEndpoints ?? []).find(r => {
                if (r.parent.id === this.parent.id) { return false }
                const dist = distance(this.anchor.absolutePosition(), r.circle.absolutePosition()) // TODO: adjust for offset
                return dist < r.ring.radius() * 1.5
            });
            (this.parent.globalState.availableEndpoints ?? []).forEach(r => {
                r.ring.visible(false)
            })
            if (target) {
                target.ring.visible(true)
                target.ring.opacity(1.0)
                this.parent.globalState.activeEndpoint = target
                target.showToolTip()
            } else {
                this.parent.globalState.activeEndpoint = undefined
            }
        })

        this.anchor.on("dragend", () => {
            this.line.points([])
            this.line.visible(false)
            this.anchor.x(0)
            this.anchor.y(0)
            this.ring.visible(false)
            if (!this.mouseIn) this.circle.opacity(0.7)
            if (this.parent.globalState.activeEndpoint) {
                this.parent.globalState.activeEndpoint.addConnectionToReceptor(this)
            }
            this.parent.globalState.activeReceptor = undefined
        })
    }

    propagate(data?: Tensor | SymbolicTensor | null): boolean {
        this.currentValue = data
        return this.parent.onInputUpdated(this.index)
    }
    
    propagateModel(model?: LayersModel | null) {
        this.currentModel = model
        try {
            return this.parent.onInputUpdated(this.index)
        } catch (error) {
            console.warn(error)
            return true
        }  
    }

    propagateDataset(dataset?: CustomTFDataset | null) {
        this.currentDataset = dataset
        try {
            return this.parent.onInputUpdated(this.index)
        } catch (error) {
            console.warn(error)
            return true
        }
    }

    showToolTip() {
        this.parent.globalState.showTooltip!({
            text: this.name ?? `Input ${this.index + 1}`,
            position: this.element.absolutePosition()
        })
    }

}