import { useCallback, useEffect, useRef, useState } from "react"
import "./EmbeddedPlayground.css"
import Konva from "konva"
import { Layer, Stage } from "react-konva"
import { useBoolean } from "../../use-boolean"
import { SaveState, ValueStore } from "../Interfaces"
import Source from "../Nodes/SourceBlock"
import { addBlockAtPosition, adjustOffset, createBlockFromString, serializeToJSON } from "../Utils"
import { axiosInstance } from "../../Utils"
import { useParams } from "react-router-dom"
import * as tf from "@tensorflow/tfjs"
import Check from "@mui/icons-material/Check"
import Settings from "@mui/icons-material/Settings"
import { IconButton, Menu, MenuItem } from "@mui/material"

interface Props {
    height?: number
    id: string
    title: string
    savedStates?: any

    /** The last element of the state must be the output for assessing correctness */
    initialState: SaveState
    passCriterion?: (output: number | number[] | number[][] | number[][][]) => boolean
}

const checkmark = <Check htmlColor="#40a845" fontSize="small" sx={{marginLeft: "2px", height: "19px"}} />

function EmbeddedPlayground(props: Props) {
    const { chapter, index } = useParams()
    const containerRef = useRef<HTMLDivElement | null>(null)
    const valueStoreRef = useRef<ValueStore | undefined>(undefined);
    const stageRef = useRef<Konva.Stage | null>(null)
    const mainLayerRef = useRef<Konva.Layer | null>(null)
    const isFocused = useRef(false)

    // 0 = syncing, 1 = synced, 2 = failed
    const [syncStatus, setSyncStatus] = useState(1)
    const exercisePassed = useRef(props.savedStates?.[props.id]?.passed === 1)
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | undefined>()

    const deselectAll = () => {
        for (const uuid in valueStoreRef.current!.selection) {
            valueStoreRef.current!.everything[uuid]?.unselect()
        }
        valueStoreRef.current!.selection = {}
    }

    const sync = async (passed: number) => {
        setSyncStatus(0)
        exercisePassed.current ||= passed === 1
        const data = await serializeToJSON(valueStoreRef.current!)
        await axiosInstance.post(`/chapters/${chapter}/lessons/${index}/interactions/${props.id}`, {
            passed, state: data
        })
        .then(() => {
            setSyncStatus(1)
        })
        .catch(error => {
            console.warn(error)
            setSyncStatus(2)
        })
    }

    const reset = async () => {
        setMenuAnchor(undefined)
        exercisePassed.current = false
        setSyncStatus(0)
        valueStoreRef.current?.hideTooltip?.()
        await axiosInstance.delete(`/chapters/${chapter}/lessons/${index}/interactions/${props.id}`)
        .then(() => {
            setSyncStatus(1)
        })
        .catch(error => {
            console.warn(error)
            setSyncStatus(2)
        })
        .finally(() => {
            loadFromState(props.initialState)
            stageRef.current?.offsetX(0)
            stageRef.current?.offsetY(0)
            stageRef.current?.width(containerRef.current!.clientWidth)
            stageRef.current?.height(props.height ?? 400)
        })
    }

    const loadFromState = (state: SaveState) => {
        stageRef.current?.removeEventListener("dragend")
        stageRef.current?.removeEventListener("click")

        if (valueStoreRef.current) {
            Object.values(valueStoreRef.current.everything).forEach(block => block.destroy(true))
            valueStoreRef.current.connections.forEach(conn => conn.line.remove())
        }

        const layer = mainLayerRef.current!
        const tooltip = new Konva.Group({visible: false})
        const tooltipRect = new Konva.Rect({
            fill: "#fffde8",
            cornerRadius: 5,
            width: 150,
            height: 50,
            shadowEnabled: true,
            shadowColor: "#101010",
            shadowOpacity: 0.12,
            shadowOffsetY: 2,
            shadowBlur: 10
        })
        tooltip.add(tooltipRect)
        const tooltipText = new Konva.Text({
            padding: 6,
            fontSize: 13,
            fill: "#303030"
        })
        tooltip.add(tooltipText)
        layer.add(tooltip)
        valueStoreRef.current = {
            mainLayer: layer,
            stage: stageRef.current!,
            availableEndpoints: [],
            availableReceptors: [],
            connections: new Map(),
            visitedReceptorCount: new Map(),
            showTooltip: (props) => {
                //@ts-ignore
                tooltipText.width(null)
                tooltipText.text(props.text)
                tooltipText.width(Math.min(200, tooltipText.width()))
                tooltipRect.width(tooltipText.width())
                tooltipRect.height(tooltipText.height())
                tooltip.x(props.position.x + stageRef.current!.offsetX())
                tooltip.y(props.position.y + stageRef.current!.offsetY() + 10)
                tooltip.visible(true)
                tooltip.moveToTop()
            },
            hideTooltip: () => {
                tooltip.visible(false)
            },
            selection: {},
            everything: {},
            viewSize: { width: containerRef.current!.clientWidth - 2, height: props.height ?? 400 },
            autoOffsetPadding: 10
        }
        const valueStore = valueStoreRef.current

        // Add blocks from initial state
        for (const [id, block] of Object.entries(state.objects)) {
            if (block.quotaId === "block") {
                console.warn("Do not use `block` as save entry type id")
            }
            const newBlock = createBlockFromString(block.quotaId!, block, id) // if quotaId is nonnull, so must be typeId
            newBlock.editable = false
            addBlockAtPosition(valueStore, layer, newBlock, () => {}, () => {}, block.position?.x, block.position?.y, true)
        }

        // Restore connections
        for (const connection of state.connections) {
            const outgoing = valueStore.everything[connection.from.blockId]?.outputs[connection.from.index]
            const incoming = valueStore.everything[connection.to.blockId]?.inputs[connection.to.index]
            if (outgoing && incoming) {
                outgoing.addConnectionToReceptor(incoming, true)
            }
        }
        
        // Finally, rerun the network
        for (const block of Object.values(valueStore.everything)) {
            // Propagate from all sources
            (block as Source)?.propagate?.()
        }

        setTimeout(() => adjustOffset(valueStore), 1)

        // Handle selection
        stageRef.current!.on("click", e => {
            if (e === null || e.target instanceof Konva.Stage && !e.evt.shiftKey) {
                deselectAll()
            }
        })

        stageRef.current!.on("dragend", e => {
            if (!(e.target instanceof Konva.Stage)) {
                const outputTensor = valueStore.everything[Object.keys(valueStore.everything).length - 1].currentValue
                let passed = 0
                if (outputTensor instanceof tf.Tensor && props.passCriterion && props.passCriterion(outputTensor.arraySync() as number)) {
                    passed = 1
                }
                sync(passed)
            }
        })
    }
    
    useEffect(() => {
        stageRef.current?.height(props.height ?? 400)
        stageRef.current?.width(containerRef.current!.clientWidth)
        
        const state: SaveState = props.savedStates?.[props.id]?.state ?? props.initialState
        
        loadFromState(state)
    }, [])
    

    return <>
        <div className="embedded-playground-root">
            <div className="embedded-playground-header">
                <div style={{display: "flex", alignItems: "center"}}>{props.title}{exercisePassed.current ? checkmark : undefined}</div>
                <div style={{color: "gray", fontSize: 13, flexShrink: 0, fontWeight: 400}}>{["Syncing...", "Saved to Cloud", "Sync Failed"][syncStatus]}</div>
            </div>
            <div className="embedded-playground-container" style={{height: props.height ?? 400}} ref={containerRef}>
                <Stage width={760} height={props.height ?? 400} className="embedded-canvas" ref={stageRef} onMouseDown={e => {
                    isFocused.current = true
                    e.evt.stopPropagation()
                }}>
                    <Layer ref={mainLayerRef}/>
                </Stage>
                <IconButton sx={{position: "absolute", right: 2, bottom: 2, padding: "4px"}} onClick={(e) => setMenuAnchor(e.currentTarget)}>
                    <Settings sx={{fontSize: 16, color: "gray"}} fontSize="small" />
                </IconButton>
                <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={() => setMenuAnchor(undefined)} MenuListProps={{
                    sx: {padding: 0.3, "& li": { borderRadius: 1.5 }}
                }} slotProps={{paper: { sx: { borderRadius: 1.5, boxShadow: "1px 4px 9px 2px rgba(0, 0, 0, 0.15)" } }}} >
                    <MenuItem onClick={reset}>Reset</MenuItem>
                </Menu>
            </div>
        </div>
    </>
}

export default EmbeddedPlayground;