import Konva from "konva"
import Endpoint from "../Endpoint"
import Block from '../Block';
import ContentBlock from '../ContentBlock'
import * as tf from "@tensorflow/tfjs"
import { adjustOffset, getNativeValue, tensorDescription, tensorToString } from '../../Utils';
import { InspectorProps } from "../../Interfaces";

const cellWidth = 55
const cellHeight = 35
const gapSize = 5

class TensorDisplay extends ContentBlock {
    nativeValue?: Float32Array | Uint8Array | Int32Array

    /** Concrete tensor value for TensorDisplay */
    value?: tf.Tensor
    text: Konva.Text

    cellRects: Konva.Rect[] = []
    cellLabels: Konva.Text[] = []
    dotsVertical: Konva.Image = new Konva.Image({image: undefined})
    dotsHorizontal: Konva.Image[] | null = null
    showIcon!: Konva.Image
    hideIcon!: Konva.Image
    displayData = true
    isInitialRender = true

    editAreaRef: HTMLTextAreaElement | null = null
    editArea = <textarea placeholder="Enter Data" className="custom-textarea" style={{maxWidth: "100%", minWidth: "100%", minHeight: "100px", border: "1px solid #d0edff50"}} ref={(e) => {
        if (e) {
            this.editAreaRef = e
            e.readOnly = !this.editable
            this.editAreaRef!.defaultValue = (this.value === undefined ? "" : JSON.stringify(getNativeValue(this.value)))
        }
    }} />

    constructor(id: string, public allowHidingValues = true) {
        
        let width = 100, height = 80
        // if (value !== undefined) {
        //     const dim1 = value.shape.length > 0 ? value.shape[0] : 1
        //     const dim2 = (value.shape.length > 1) ? value.shape[1] ?? 1 : 1
        //     width = Math.min(7, dim2) * (cellWidth + gapSize) + gapSize
        //     height = 25 + Math.min(7, dim1) * (cellHeight + gapSize) + gapSize
        // }

        super(id, width, height)
        
        this.text = new Konva.Text({
            width: this.container.width(),
            lineHeight: 1.1,
            height: height - this.titleBar.height(),
            verticalAlign: "middle",
            align: "center",
            padding: 5,
            fontSize: 14,
            fontFamily: "monospace",
            y: this.titleBar.height(),
            text: this.value === undefined ? "No Value" : "Loading..."
        })
        this.element.add(this.text)

        for (let i = 0; i < 49; i++) { // Maximum display: 6 * 6
            const newRect = new Konva.Rect({
                fill: "white",
                width: cellWidth,
                height: cellHeight,
                visible: false,
                cornerRadius: 3
            })
            this.cellRects.push(newRect)
            this.element.add(newRect)

            const newLabel = new Konva.Text({
                align: "right",
                fontFamily: "monospace",
                width: cellWidth,
                height: cellHeight,
                padding: 2,
                verticalAlign: "middle",
                visible: false
            })
            this.cellLabels.push(newLabel)
            this.element.add(newLabel)
        }

        Konva.Image.fromURL("/assets/blocks/dots_vertical.svg", img => {
            img.width(18).height(18)
            img.offsetX(9).offsetY(9)
            img.opacity(0.6)
            img.visible(false)
            this.dotsVertical = img
            this.element.add(img)
            
            this.dotsHorizontal = []
            for (let i = 0; i < 7; i++) {
                const newImg: Konva.Image = img.clone()
                newImg.rotate(90)
                this.dotsHorizontal.push(newImg)
                this.element.add(newImg)
            }

            this.updateReceptorsAndEndpoints()
            this.element.visible(true)
        })

        Konva.Image.fromURL("/assets/blocks/hide.svg", img => {
            img.width(15).height(15)
            img.visible(!this.displayData && this.allowHidingValues)
            img.offsetX(img.width())
            img.setPosition({ x: this.element.width() - 5, y: 5 })
            this.element.add(img)
            this.hideIcon = img
            img.on("click", (ev) => {
                //@ts-ignore
                ev.train = 1 // this flag stops propagation

                this.displayData = true
                this.updateReceptorsAndEndpoints()
                img.visible(false)
                this.showIcon.visible(true)
            })
        })

        Konva.Image.fromURL("/assets/blocks/show.svg", img => {
            img.width(15).height(15)
            img.visible(this.displayData && this.allowHidingValues)
            img.offsetX(img.width())
            img.setPosition({ x: this.element.width() - 5, y: 5 })
            this.element.add(img)
            this.showIcon = img
            img.on("click", (ev) => {
                //@ts-ignore
                ev.train = 1 // this flag stops propagation

                this.displayData = false
                this.updateReceptorsAndEndpoints()
                img.visible(false)
                this.hideIcon.visible(true)
            })
        })

        this.element.offsetY(this.element.height() / 2)
    }

    setNewValue(value: tf.Tensor, propagate = true) {
        this.value = value
        this.nativeValue = value.dataSync()
        this.updateReceptorsAndEndpoints()
        this.outputs[0].propagate(value, !propagate)
        this.globalState.visitedReceptorCount.clear()
    } 

    setEnableToggle(enable: boolean) {
        this.allowHidingValues = enable
        if (enable) {
            this.showIcon?.visible(this.displayData)
            this.hideIcon?.visible(!this.displayData)
        } else {
            this.showIcon?.visible(false)
            this.hideIcon?.visible(false)
        }
    }

    onClickMenu(): InspectorProps {
        if (this.editAreaRef)
            this.editAreaRef!.defaultValue = (this.value === undefined ? "" : JSON.stringify(this.value.arraySync()))
        return {
            title: this.displayedName,
            settings: this.editArea,
            buttons: [
                {
                    title: "Save",
                    type: "normal",
                    disabled: !this.editable,
                    onClick: () => {
                        try {
                            const raw = JSON.parse(this.editAreaRef!.value)
                            const data = tf.tensor(raw)
                            this.nativeValue = data.dataSync()
                            this.value = this.currentValue = data
                            this.updateReceptorsAndEndpoints()
                            this.outputs[0].propagate(data)
                            this.globalState.visitedReceptorCount.clear()
                            return true
                        } catch (error) {
                            this.globalState.visitedReceptorCount.clear()
                            console.log(error)
                            alert("Invalid data format.")
                            return false
                        }
                    }
                }
            ],
            docs: this.documentation
        }
    }

    updateReceptorsAndEndpoints(): void {
        const oldHeight = this.element.height()
        if (this.value === undefined || this.nativeValue === undefined || this.value.shape[0] === 0) {
            this.text.visible(true)
            this.text.text("No Value")
            this.text.width(110)
            this.text.height(85 - this.titleBar.height())
            this.container.width(110)
            this.titleBar.width(110)
            this.titleLabel.width(this.container.width() - (this.allowHidingValues ? 20 : 0))
            this.container.height(85)
            this.element.width(this.container.width())
            this.element.height(this.container.height())

            for (let i = 0; i < this.cellRects.length; i++) {
                this.cellRects[i].visible(false)
                this.cellLabels[i].visible(false)
            }
            this.dotsVertical.visible(false)
            this.dotsHorizontal?.forEach(img => img.visible(false))
            this.element.offsetY(this.element.height() / 2)
            this.hideIcon?.setPosition({ x: this.element.width() - 5, y: 5 })
            this.showIcon?.setPosition({ x: this.element.width() - 5, y: 5 })
            return
        } else if (!this.displayData) {
            this.text.visible(true)
            this.text.text("(" + this.value!.shape.map(v => v ?? "B").join(" x ") + ")")
            this.text.width(120)
            this.text.height(100 - this.titleBar.height())
            this.container.width(120)
            this.titleBar.width(120)
            this.titleLabel.width(this.container.width() - (this.allowHidingValues ? 20 : 0))
            this.container.height(100)
            this.element.width(this.container.width())
            this.element.height(this.container.height())

            for (let i = 0; i < this.cellRects.length; i++) {
                this.cellRects[i].visible(false)
                this.cellLabels[i].visible(false)
            }
            this.dotsVertical.visible(false)
            this.dotsHorizontal?.forEach(img => img.visible(false))
            this.element.offsetY(Math.max(this.element.height(), this.text.height() + this.titleBar.height()) / 2)
            this.hideIcon?.setPosition({ x: this.element.width() - 5, y: 5 })
            this.showIcon?.setPosition({ x: this.element.width() - 5, y: 5 })
            return
        } else if (this.value.shape.length > 2 && this.value.shape.filter(d => d > 1).length > 2) {
            //@ts-ignore
            this.text.height(null)
            this.element.width(165)
            this.text.text(tensorDescription(this.value))
            if (this.text.height() > 300) {
                this.text.height(300)
            } else if (this.text.height() < 120) {
                this.text.height(120)
            }
            this.container.height(this.text.height() + this.titleBar.height())
            this.titleLabel.width(this.container.width() - (this.allowHidingValues ? 20 : 0))
            this.container.width(this.element.width())
            this.titleBar.width(this.element.width())
            this.text.visible(true)

            for (let i = 0; i < this.cellRects.length; i++) {
                this.cellRects[i].visible(false)
                this.cellLabels[i].visible(false)
            }
            this.dotsVertical.visible(false)
            this.dotsHorizontal?.forEach(img => img.visible(false))
            this.element.offsetY(Math.max(this.element.height(), this.text.height() + this.titleBar.height()) / 2)
            this.hideIcon?.setPosition({ x: this.element.width() - 5, y: 5 })
            this.showIcon?.setPosition({ x: this.element.width() - 5, y: 5 })
            return
        } else {
            this.text.text("")
            this.text.visible(false)
        }
        let dim1 = 1, dim2 = 1
        if (this.value.shape.length === 2) {
            dim1 = this.value.shape[0]
            dim2 = this.value.shape[1]
        } else {
            const nontrivialDims = this.value.shape.filter(d => d > 1)
            if (nontrivialDims.length === 1) {
                if (this.value.shape[this.value.shape.length - 1] === 1) {
                    dim1 = nontrivialDims[0]
                    dim2 = 1
                } else {
                    dim1 = 1
                    dim2 = nontrivialDims[0]
                }
            } else if (nontrivialDims.length === 2) {
                dim1 = nontrivialDims[0]
                dim2 = nontrivialDims[1]
            }
        }

        this.element.height(this.titleBar.height() + Math.min(7, dim1) * (cellHeight + gapSize) + gapSize)
        this.container.height(this.element.height())
        this.element.width(Math.min(7, dim2) * (cellWidth + gapSize) + gapSize)
        this.container.width(this.element.width())
        this.titleBar.width(this.container.width())
        this.titleLabel.width(this.titleBar.width() - (this.allowHidingValues ? 20 : 0))
        for (let i = 0; i < 49; i++) {
            const row = Math.floor(i / 7)
            const column = i % 7
            const rect = this.cellRects[i]
            const label = this.cellLabels[i]
            if (dim1 <= 7 && row >= dim1 || dim1 > 7 && row >= 6 || dim2 <= 7 && column >= dim2 || dim2 > 7 && column >= 6) {
                rect.visible(false)
                label.visible(false)
            } else {
                rect.visible(true)
                label.visible(true)
                const queryRow = (dim1 <= 7 || row < 3) ? row : dim1 - (7 - row) + 1
                const queryColumn = (dim2 <= 7 || column < 3) ? column : dim2 - (7 - column) + 1
                const cellValue = this.nativeValue.at(queryRow * dim2 + queryColumn)
                if (dim1 > 7 && row >= 3) {
                    rect.y(this.titleBar.height() + gapSize + (row + 1) * (gapSize + cellHeight))
                } else {
                    rect.y(this.titleBar.height() + row * (gapSize + cellHeight) + gapSize)
                }
                if (dim2 > 7 && column >= 3) {
                    rect.x(gapSize + (column + 1) * (gapSize + cellWidth))
                } else {
                    rect.x(column * (gapSize + cellWidth) + gapSize)
                }
                label.x(rect.x())
                label.y(rect.y())
                if (cellValue === undefined) {
                    label.text("?")
                } else if (this.value.dtype === "bool") {
                    label.text(cellValue === 0 ? "false" : "true")
                } else if (Math.abs(cellValue) >= 100) {
                    label.text(cellValue.toFixed(2))
                } else {
                    label.text(cellValue.toFixed(3))
                }
            }
        }

        this.element.offsetY(this.element.height() / 2)
        this.hideIcon?.setPosition({ x: this.element.width() - 5, y: 5 })
        this.showIcon?.setPosition({ x: this.element.width() - 5, y: 5 })

        // Assets haven't been loaded, stop rendering the ellipses
        if (!this.dotsVertical || !this.dotsHorizontal) { return }

        if (dim1 > 7) {
            this.dotsVertical.visible(true)
            this.dotsVertical.x(this.container.width() / 2)
            this.dotsVertical.y(this.titleBar.height() + this.contentHeight / 2)
        } else {
            this.dotsVertical.visible(false)
        }
        if (dim2 > 7) {
            for (let row = 0; row < 7; row++) {
                this.dotsHorizontal[row].visible(dim1 <= 7 && row < dim1 || dim1 > 7 && row < 6)
                this.dotsHorizontal[row].x(this.container.width() / 2)
                if (dim1 > 7 && row >= 3) {
                    this.dotsHorizontal[row].y(this.titleBar.height() + (row + 1) * (cellHeight + gapSize) + gapSize + cellHeight / 2)
                } else {
                    this.dotsHorizontal[row].y(this.titleBar.height() + row * (cellHeight + gapSize) + gapSize + cellHeight / 2)
                }
            }
        } else {
            this.dotsHorizontal.forEach((img) => img.visible(false))
        }

        adjustOffset(this.globalState)
        this.redrawConnections()
    }
}

export default TensorDisplay;