import { Layer } from "konva/lib/Layer";
import { ValueStore, InspectorProps, TestResult, TestCaseResult } from "../../Interfaces";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { adjustOffset, createBlockFromString } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { randomInt } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { NegationLayer } from "../../Layers/Arithmetics";
import Literal from "../../Nodes/Inputs/Literal";

class Chapter1_TensorCreation extends ExerciseData {

    title = "Tensor Creation"
    exercise_id = "tensor_creation"
    enableCaseMultiselect = false
    showDisplayOptions = false

    defaultBlocks: {
        constant?: Literal
        outputBlock?: OutputBlock
    } = {}

    constructor() {
        super()

        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`Create a tensor with the following values, then connect it to the output.`} />
            <table className="grid">
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        {[5,6,7,8].map(i => <td key={i}>{i}</td>)}
                    </tr>
                    <tr>
                        {[9,10,11,12].map(i => <td key={i}>{i}</td>)}
                    </tr>
                </tbody>
            </table>
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    // async generateTestCases() {
    //     const inputs = randomInt([5], -999, 999)
    //     this.currentTestCases = inputs.map(x => { return { input: tf.tensor(x), output: tf.tensor(x + 1) } })
    //     return this.currentTestCases
    // }

    async generateTestCases(count?: number) {
        return []
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "constant": { count: 1 },
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        // Test
        this.defaultBlocks.outputBlock = createBlockFromString("tensor_viewer", { customName: "Output" }, "outputBlock") as OutputBlock
        this.defaultBlocks.outputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.outputBlock, 700, 320)
    }

    onTestCasesUpdated(): void {
        
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        if (!this.defaultBlocks.outputBlock) {
            alert("Output is not connected to an input!")
            return
        }

        // Get model by passing in symbolic input
        const output = this.defaultBlocks.outputBlock.inputs[0].currentValue as tf.Tensor
        const expected = tf.tensor([[1,2,3,4],[5,6,7,8],[9,10,11,12]])
        const diff = output.sub(expected).sum().arraySync() as number
        // const outputValue = output.arraySync() as number[][]
        this.onTestCasesUpdated()
        
        onProgressUpdated({
            current: 1,
            result: [{
                case: {
                    input: [],
                    output: expected.arraySync(),
                    inputNames: ["Constant"],
                    
                },
                pred: { value: output.arraySync() },
                status: diff === 0 ? "correct" : "incorrect"
            }],
            status: "done",
            passed: diff === 0 ? 1 : 0
        })
    }
}

export default Chapter1_TensorCreation;