import PrivateComponent from '../../PrivateComponent/PrivateComponent';
import ExerciseView from './Exercise';
import ExerciseData from './ExerciseData';
import DemoExercise from './Chapter4/Demo';

interface ExerciseProps {
}
 
interface ExerciseState {
    data?: ExerciseData
}
 
class DemoExerciseScreen extends PrivateComponent<ExerciseProps, ExerciseState> {
    constructor(props: ExerciseProps) {
        super(props);
        this.state = { };
    }

    async loadData(resolve: (statusCode: number) => void) {
        this.setState({ data: new DemoExercise() })
    }

    safeRender() { 
        return <ExerciseView data={this.state.data!} exerciseId='' saveState={null} next={null} />;
    }
}

export default DemoExerciseScreen;