import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack";
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";
import "./Introduction_Errors.css"

interface State {
    currentPage: number
}

function SpamTable() {
    const [topLeft, setTopLeft] = useState(50)
    const [topRight, setTopRight] = useState(5)
    const [bottomLeft, setBottomLeft] = useState(4)
    const [bottomRight, setBottomRight] = useState(12)
    return <div>

        <table className="spam-table">
            <tbody>
                <tr>
                    <th colSpan={2}></th>
                    <th colSpan={2} style={{textAlign: "center", fontWeight: 700, fontSize: 17}}>Decision</th>
                    <th rowSpan={4} style={{width: "60px"}}/>
                </tr>
                <tr>
                    <th colSpan={2}/>
                    <th>Normal</th>
                    <th>Spam</th>
                </tr>
                <tr>
                    <th rowSpan={2} style={{position: "relative", width: "30px"}}>
                        <div className="center-content" style={{transform: "translate(-50%, -50%) rotate(-90deg)", fontWeight: 700, fontSize: 17}}>
                            Reality
                        </div>
                    </th>
                    <th>Normal</th>
                    <td style={{backgroundColor: "#ffe7bd"}}>
                        <input type="number" placeholder="0" value={topLeft} onChange={e => setTopLeft(Number(e.target.value))} />
                    </td>
                    <td style={{backgroundColor: "#ffffcd"}}>
                        <input type="number" placeholder="0" value={topRight} onChange={e => setTopRight(Number(e.target.value))}/>
                    </td>
                </tr>
                <tr>
                    <th>Spam</th>
                    <td style={{backgroundColor: "#dcffe0"}}>
                        <input type="number" placeholder="0" value={bottomLeft} onChange={e => setBottomLeft(Number(e.target.value))}/>
                    </td>
                    <td style={{backgroundColor: "#caddff"}}>
                        <input type="number" placeholder="0" value={bottomRight} onChange={e => setBottomRight(Number(e.target.value))} />
                    </td>
                </tr>
            </tbody>
        </table>
        <table className="stats-table">
            <colgroup>
                <col style={{width: "200px"}} />
                <col style={{minWidth: "60px"}} />
                <col style={{width: "220px"}} />
            </colgroup>
            <thead>
                <tr>
                    <th style={{textAlign: "left"}}>Metric Name</th>
                    <th style={{textAlign: "right"}}>Value</th>
                    <th style={{textAlign: "right"}}>Formula</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Precision</td>
                    <td>{round(bottomRight / (topRight + bottomRight), 3)}</td>
                    <td>
                        <div className="color-token" style={{backgroundColor: "#caddff"}} />
                        /
                        (<div className="color-token" style={{backgroundColor: "#caddff"}} />
                        +
                        <div className="color-token" style={{backgroundColor: "#faf7c8"}} />)
                    </td>
                </tr>
                <tr>
                    <td>Recall</td>
                    <td>{round(bottomRight / (bottomRight + bottomLeft), 3)}</td>
                    <td>
                        <div className="color-token" style={{backgroundColor: "#caddff"}} />
                        /
                        (<div className="color-token" style={{backgroundColor: "#caddff"}} />
                        +
                        <div className="color-token" style={{backgroundColor: "#dbfedf"}} />)
                    </td>
                </tr>
                <tr>
                    <td>Specificity</td>
                    <td>{round(topLeft / (topLeft + topRight), 3)}</td>
                    <td>
                        <div className="color-token" style={{backgroundColor: "#ffe7bd"}} />
                        /
                        (<div className="color-token" style={{backgroundColor: "#ffe7bd"}} />
                        +
                        <div className="color-token" style={{backgroundColor: "#faf7c8"}} />)
                    </td>
                </tr>
                <tr>
                    <td>False Discovery Rate</td>
                    <td>{round(topRight / (topLeft + topRight), 3)}</td>
                    <td>
                        <div className="color-token" style={{backgroundColor: "#faf7c8"}} />
                        /
                        (<div className="color-token" style={{backgroundColor: "#ffe7bd"}} />
                        +
                        <div className="color-token" style={{backgroundColor: "#faf7c8"}} />)
                    </td>
                </tr>
                <tr>
                    <td>Accuracy</td>
                    <td>{round((topLeft + bottomRight) / (topLeft + topRight + bottomLeft + bottomRight), 3)}</td>
                    <td>
                        (<div className="color-token" style={{backgroundColor: "#ffe7bd"}} />
                        +
                        <div className="color-token" style={{backgroundColor: "#caddff"}} />)
                        /
                        (<div className="color-token" style={{backgroundColor: "#ffe7bd"}} />
                        + <div className="color-token" style={{backgroundColor: "#faf7c8"}} />
                        + <div className="color-token" style={{backgroundColor: "#dcffe0"}} />
                        + <div className="color-token" style={{backgroundColor: "#caddff"}} />)
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}

function Interaction({img}: {img: string}) {
    const targetDiv = useRef<HTMLDivElement | null>(null)
    const tooltip = useRef<HTMLDivElement | null>(null)
    const cursorPoint = useRef<HTMLDivElement | null>(null)
    const currentError = useRef(Number.POSITIVE_INFINITY)
    const [lossType, setLossType] = useState<"mse" | "rmse" | "mae">("rmse")
    const target = { x: 450/2, y: 450/2 }

    function updateCursorPosition(x: number, y: number) {
        if (!targetDiv.current || !tooltip.current || !cursorPoint.current) return
        tooltip.current.style.transform = `translate(calc(${x}px - 50%), ${y+15}px)`
        cursorPoint.current.style.transform = `translate(calc(${x}px - 50%), calc(${y}px - 50%))`
        let error = 0
        if (lossType === "rmse") {
            error = Math.sqrt(((x - target.x) / 10) ** 2 + ((y - target.y) / 10) ** 2)
        } else if (lossType === "mse") {
            error = ((x - target.x) / 10) ** 2 + ((y - target.y) / 10) ** 2
        } else {
            error = Math.abs((x - target.x) / 10) + Math.abs((y - target.y) / 10)
        }
        tooltip.current.innerText = `${round(error, 2)}`
        currentError.current = Math.sqrt(((x - target.x) / 10) ** 2 + ((y - target.y) / 10) ** 2)
    }

    return <div style={{width: 450, height: 450, position: 'relative', margin: "20px auto", overflow: "visible"}} ref={targetDiv}
        onMouseMove={e => {
            if (!targetDiv.current || !tooltip.current || !cursorPoint.current) return
            const containerRect = e.currentTarget.getBoundingClientRect()
            updateCursorPosition(e.clientX - Math.round(containerRect.left), e.clientY - Math.round(containerRect.top))
            if (currentError.current > 22.5) {
                tooltip.current.style.visibility = "hidden"
                cursorPoint.current.style.visibility = "hidden"
                targetDiv.current.style.cursor = "default"
            } else {
                tooltip.current.style.visibility = "visible"
                cursorPoint.current.style.visibility = "visible"
                targetDiv.current.style.cursor = "none"
            }
        }}
        onMouseLeave={() => {
            if (!targetDiv.current || !tooltip.current || !cursorPoint.current) return
            tooltip.current.style.visibility = "hidden"
            cursorPoint.current.style.visibility = "hidden"
            targetDiv.current.style.cursor = "default"
        }} 
    >
        <img src={img} width="100%" height="100%" style={{position: "absolute", userSelect: "none"}} />
        <div ref={tooltip} style={{backgroundColor: 'rgba(0.1, 0.1, 0.1, 0.6)', color: 'white', borderRadius: 5, position: 'absolute', padding: '2px 5px', visibility: "hidden", fontSize: 13, minWidth: 40, textAlign: "center", zIndex: 1, pointerEvents:"none"}}> tooltip </div>
        <div ref={cursorPoint} style={{backgroundColor: "#f03060", borderRadius: 10, width: 6, height: 6, visibility: "hidden", zIndex: 1}} />
    </div>
}

class C2_Errors extends LessonTemplate {
    
    currentError = Number.POSITIVE_INFINITY

    constructor(props: LessonTemplateProps) {
        super(props, 4, "Metric Functions")        
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            return <Fragment>
                <p>
                    Alice, Bob, and Charlie are playing darts. After some practice, they are ready to play a match to find out who’s the best. They each threw one dart onto a target, resulting in the following positions:
                </p>

                <img src="/assets/dart.png" width={400} className="centered"/>

                <p>They face a problem: they all think they did the best, and are unable to agree on a ranking. You overheard the conversation and wanted to help them out.</p>
                <MultipleChoiceQuestion prompt="What should you do to settle their argument? Choose one option below:" options={["Judging by eye which dart is closest to the bullseye, which is second closest, etc.", "Use a ruler to measure each dart’s distance to the bullseye, then rank the players by distance in descending order (biggest to smallest).", "Use a ruler to measure each dart’s distance to the bullseye, then rank the players by distance in ascending order (smallest to biggest)."]} correctIndex={2} explanation="Since the player with the closest distance to the bullseye wins, we want to rank the distances in ascending order." />
            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <p>Next, use your mouse to measure the distances from the three darts to the center. Then, decide on how to rank the three players.</p>
                
                <Interaction img="/assets/dart.png" />
                
                <MultipleChoiceQuestion correctIndex={1} options={["Alice, Bob, Charlie", "Bob, Alice, Charlie", "Bob, Charlie, Alice", "Charlie, Alice, Bob"]} prompt="Based on the data, how would you rank the three players?" explanation="According to the measurements, Bob's distance is about 8.8, Alice's distance is about 9.7, and Charlie's distance is about 11.1. Therefore, Bob is first place, followed by Alice." />
            </Fragment>
        } else if (index === 2) {
            return <Fragment>
                <MarkdownTextView rawText={`Let's brush up how 2D distances are calculated. As you remember from Pythagorean theorem, distance from some location $(x, y)$ to the origin is\n$$\n\\large {\\color{#81B3C9} d} = \\sqrt{{\\color{#82B877}x}^2 + {\\color{#9697C5}y}^2}\n$$`} />
                <Interaction img="/assets/dart_annotated.png" />
                <MarkdownTextView rawText={`Alice's dart has an x-coordinate of around $-8.5$, a y-coordinate of around $4.6$, so its distance to the origin is roughly $\\sqrt{(-8.5)^2 + 4.6^2 } \\approx 9.66$. Don't worry about getting it exact.\n\nLet's now use this technique to build a model that helps Alice, Bob, and Charlie compute their darts' distances in parallel.`}  />
            </Fragment>
        } else if (index === 3) {
            return <Fragment>
                <MarkdownTextView rawText={"In this scenario, distance is used as a standard to give a score to each player in order for them to be compared. In the world of AI, we use *metric functions* to measure the quality of AI models. Using metrics, we can assign scores to AI models and compare them."} />
                
                <MarkdownTextView rawText={"As a concrete example, let's say we want to assess the quality of an email spam detector. We can collect data on how the spam detector does by counting how many emails fall into one of the 4 categories in the table, based on the actual type of the email (**reality**) and what the detector thinks (**decision**). Feel free to change the numbers in the table."} />

                <SpamTable />

                <MarkdownTextView rawText={"Here's an explanation of what those metrics mean:\n- **Accuracy**: The proportion of emails that are correctly classified.\n- **Precision**: among emails which are classified as spam, what proportion are actually spam?\n- **Recall**: what proportion of spam emails are actually classified as spam?\n\n- **Specificity**: what proportion of non-spam emails are actually classified as non-spam?\n- **False discovery rate**: What is the proportion of non-spam emails that are misclassified as spam?\n\nThe list can go on and on, but you got the idea. Basically there are so many metrics you can use to assess the same task. So how do you pick which one to use? It depends on what you value. If you are fine with seeing some spam emails as long as you don't miss the important ones, you may want to minimize the false discovery rate or maximize precision. If you are somebody who would rather miss some emails in order to avoid seeing spam emails, you may want to maximize recall."} />

                <MultipleChoiceQuestion correctIndex={3} prompt="Among the metrics mentioned above, for which metric are smaller values better?" options={["Precision", "Recall", "Specificity", "False discovery rate"]} explanation="A lower false discovery rate means that fewer normal emails are accidentally treated as spam, which is good because we don't want to miss important emails." />
            </Fragment>
        } else {
            return <Fragment />
        }
    }
}

export default C2_Errors;