import Konva from "konva";
import Block from "../Block";
import * as tf from "@tensorflow/tfjs"
import Receptor from "../Receptor";
import Endpoint from "../Endpoint";
import 'rc-dropdown/assets/index.css';
import { KonvaEventObject } from "konva/lib/Node";

class ShapeReader extends Block {

    type_id = "shape_reader"
    ring: Konva.Circle
    titleLabel: Konva.Text

    constructor(id: string) {
        super(id)
        this.element = new Konva.Group({
            draggable: true,
            width: 80,
            height: 89,
            offsetX: -40,
            offsetY: -40
        })

        this.ring = new Konva.Circle({
            stroke: "#e2a0e4",
            strokeWidth: 3,
            radius: this.element.width() / 2,
            fill: "#fef0ff"
        })

        this.titleLabel = new Konva.Text({
            text: "Shape",
            fontSize: 12,
            align: "center",
            width: this.ring.width(),
            x: 0,
            y: 10
        })
        this.titleLabel.x(-this.titleLabel.width() / 2)
        Konva.Image.fromURL("/assets/blocks/shape.svg", img => {
            img.width(26)
            img.height(26)
            img.x(2)
            img.y(-6)
            img.offsetX(img.width() / 2)
            img.offsetY(img.height() / 2)

            this.element?.add(img)
        })

        this.element.add(this.ring)
        this.element.add(this.titleLabel)

        this.inputs = [
            new Receptor(this, 0, -this.ring.radius(), 0),
        ]
        this.outputs = [
            new Endpoint(this, 0, this.ring.radius(), 0)
        ]
    }

    onInputUpdated(index: number): boolean {
        if (this.allRequiredInputsProvided) {
            this.currentValue = tf.tensor(this.inputs[0].currentValue!.shape.map(v => v ?? 1) as number[])
        } else {
            this.currentValue = undefined
        }
        return this.outputs[0].propagate(this.currentValue)
    }

    select(e: KonvaEventObject<MouseEvent>): void {
        super.select(e)

        this.ring.shadowColor("#70a5fd")
        this.ring.shadowBlur(7)
        this.ring.shadowOpacity(0.9)
    }

    unselect(): void {
        super.unselect()

        this.ring.shadowOpacity(0)
    }

    async getStateDict(): Promise<Record<string, any>> {
        return {
            
        }
    }

    async loadStateDict(data: Record<string, any>): Promise<void> {
        
    }

}

export default ShapeReader;