import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack";
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import LessonTemplate, { LessonState, LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";

interface State extends LessonState {
    currentPage: number
    unlockPage2: boolean
}

class C2_ML extends LessonTemplate<State> {
    
    currentError = Number.POSITIVE_INFINITY

    constructor(props: LessonTemplateProps) {
        super(props, 7, "What is Machine Learning?")

        this.state = {
            currentPage: 1,
            unlockPage2: false
        }
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            return <Fragment>
                <MarkdownTextView rawText={"### Diagnostic test\nBefore you start reading, please fill out the [diagnostic quiz](https://docs.google.com/forms/d/e/1FAIpQLSfzCy3qjcJ2Dkv_SRa0Xrpejb4LYgGbxYpxqJP9J-PYF1Sewg/viewform?usp=sf_link). This is meant to see where your starting point is and provide a basis for measuring your learning. It is fine to skip any question that you are unsure of."} />
            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <MarkdownTextView rawText={`Put aside what you've been told about machine learning. Instead, let's begin with a story.\n\nImagine that you suddenly appear on a small boat, alone and lost in the middle of the Pacific Ocean. You are hungry and thirty, and desperately want to get to some land inhabited by humans. However, you don't know which direction is the closest.`} />

                <img src="/assets/chapter2/boat-in-the-ocean.jpg" alt="Boat in the ocean" className="centered" width={520} />

                <MarkdownTextView rawText={`Fortunately, you are not empty-handed. You have two special devices.\n1. A mysterious *depth meter* that accurately measures the *squared* depth (in some unknown unit) of the sea beneath you (so if the actual depth is 10, it reads 100), and will display 0 when you're on land.\n2. A *locator* that tells accurately reads your current 2D location as a pair of numbers ($x, y$) in some unknown coordinate system.\n\nCurrently, the depth meter reads $1234.21$. Initially you're frustrated that the depth meter doesn't tell you your actual depth (because you don't know the unit and have to take square root to recover the actual value), but then you realize that it doesn't matter, since **smaller measurements are guaranteed to be shallower**. You just need to focus on reducing its value and get it to 0.`} />

                <img src="/assets/chapter2/meter.svg" width={410} alt="Land meter" className="centered" />
                <div className="image-caption">Your depth meter. Currently, it reads 1234.21.</div>
 
                <MarkdownTextView rawText={`Your need to come up with a strategy that gets you to land with a reasonable probability.`} />

                <MultipleChoiceQuestion correctIndex={2} options={[
                    "Go in some random direction and watch the depth change. If it increases, reverse the direction.",
                    "Go in some random direction and watch the depth change. If it increases, turn 90 degrees left.",
                    "Repeatedly do the following: Stop your boat. Then move the depth meter in a circle on some flat surface and watch how the depth change. Then go in the direction that minimizes the depth for some distance of your choosing.",
                ]} prompt="What would be a reliable strategy?" />
            </Fragment>
        } else if (index === 2) {
            return <Fragment>
                <MarkdownTextView rawText={"You figured out that you should be going in the direction that minimizes the depth measurement, because if you keep decreasing the depth measurement, then eventually it should reach 0, in which case, you will have arrived at some land.\nThe strategy of moving the depth meter in a circle works because any change in depth can be attributed to the slope of the seafloor.\n\nYou started executing the strategy. To make sure that you are moving in the right direction, you check your direction frequently. Every time you check for the new direction, you move for about 100 feet (let’s call this your *step size*) in that direction, then recheck."} />

                <MarkdownTextView rawText="For the most part, this strategy has worked. After a few hours, you successfully decreased the depth reading from $1234.21$ to $417.09$. But then the strategy seems to have stopped working. Somehow your depth oscillates between $400 \sim 420$ but doesn’t get any smaller. You tried increasing your step size to 200 ft, but that only caused the depth to increase significantly more." />

                <MultipleChoiceQuestion prompt="What could explain why you are unable to decrease your depth further?" correctIndex={1} options={["You reached a plateau (a large flat region).", "You reached an underwater peak.", "The depth meter has malfunctioned."]} onCorrect={() => this.setState({ unlockPage2: true })} explanation="Underground peak is the best explanation because it both explains why the depth can't get smaller beyond a value and the fluctuation in depth resulting from performing the strategy around this problematic region." />

                {this.state.unlockPage2 && <>
                    <MarkdownTextView rawText={"It turns out you are on top of an underwater peak whose minimum depth is 400, which explains why you can't reduce the depth further."} />

                    <img src="/assets/chapter2/steep-underwater-cliff.jpg" width={520} className="centered" />
                    <div className="image-caption">An underwater peak.</div>

                    <MarkdownTextView rawText="To solve this problem, you decided to increase your step size to 1000 ft in order to “escape” this region. It caused a lot of back and forth and made you zigzag around in the ocean, but eventually you made your way to the shore!"/>
                </>}
            </Fragment>
        } else if (index === 3) {
            return <Fragment>
                <MarkdownTextView rawText="As you might have guessed, what you just read is not merely a story. It is an analogy of how *machine learning* works. <span style='color:#4069a0'>**Machine learning is the process through which an AI system gains its intelligence from data**</span>. An AI system like ChatGPT is not born with intelligence. Instead, it's intelligence is gradually gained as it sips through tons of data." enableHTML />
                <MarkdownTextView rawText={"#### Machine Learning at a High Level\nLearning from data is not as straightforward as it sounds. An AI system is a huge machine with lots of controls (e.g. knobs, switches) called **parameters**. Each control has a numerical state. Some combinations of those control states lead to intelligent behaviors, and is what we want to get at. But such states are very rare to find. The challenge of machine learning is to come up with reliable and efficient strategies to arrive at those optimal states for different kinds of AI systems, using data. This process is called **training**. To be clear, different AI systems (e.g. decision tree, support vector machine, linear regressor, neural network) learn from data in different ways, and they are all part of the field of machine learning."} />

                <img src="/assets/chapter2/knobs.jpg" width={500} className="centered" />
                <div className="image-caption">A professional speaker has lots of controls. Imagine an AI system having billions of such controls. Machine learning is the science of how to find an optimal combination of values for those controls.</div>

                <MultipleChoiceQuestion prompt="Do you know how many parameters ChatGPT (GPT 3.5 version) has? Take a guess." options={["Fewer than a million", "Between 1 million and 1 billion", "Between 1 billion and 100 billion", "More than 100 billion"]} correctIndex={3} explanation="GPT 3.5 has 175 billion parameters. Natural language is hard for machines to learn. Today's chatbots are typically powered by hundreds of billions of parameters. Look, if they are not this large, they wouldn't be called “Large Language Models” right?"/>
            </Fragment>
        } else if (index === 4) {
            return <Fragment>
                <MarkdownTextView rawText={"### Neural Networks\nThe story illustrates how a particular type of AI system — **neural networks** — is trained. In neural networks, many simple units, called *neurons*, are interconnected into larger structures in a way that loosely resembles the human brain. The network’s behavior is determined by the weights of the links that interconnect the neurons. These weights make up the parameters of the neural network. When a neural network is first created, we start by **initializing** its parameters to random values. This is just like how you are spawned at a random location in the ocean. In the ocean, your location was represented by two values, $x$ and $y$, so we say that your location was described by two parameters."}/>

                <MarkdownTextView rawText={"### Loss and Loss Functions\nAny location in the ocean is associated with a true depth determined by the seabed. This isn't what the depth meter measures though. The depth meter actually measures how *bad* your current depth is relative to the target depth, 0. More specifically, it is a function that quantifies the *badness* of the current depth $d$ given the target depth $t$ ($t$ happens to be 0 for the ocean analogy): \n$$\nf(d, t) = \\begin{cases}\n(d-t)^2 & d > t\\\\0 & \\text{otherwise}.\\end{cases}\n$$\n"} />
                
                <div className="image-caption" style={{marginTop: "5px"}}>{"When the current depth is positive, the depth meter displays the square of the depth. Otherwise (you are not in water), it is 0."}</div>

                <MarkdownTextView rawText={"Similarly, we assess a neural network by comparing its **prediction** with the correct answer, a.k.a. the **label**. For example, in sentiment prediction, the neural network needs to give a score between -1 to 1 indicating how positive it thinks a product review is. Say that for one review, the model predicts 0.2 and the actual label is 1 (meaning that the review is positive but the model is right but not very confident). The prediction is like the your true depth in the ocean. To quantify how bad the current prediction is, we need something called a **loss function** — like the depth meter — to measure the difference between the prediction 0.2 and target 1. A popular choice is the square function because it has nice mathematical properties, although other ones exist. After taking the square, we get $(0.2 - 1)^2 = 0.64$, which is called the **loss**."} />

                <MultipleChoiceQuestion prompt="Based on the text above, what do you think is true about loss functions?" options={["Loss can be negative", "Loss functions have at least two inputs", "Large loss is bad", "All of the above"]} correctIndex={3} explanation="There is no strict rule that the loss can't be negative, as long as it's bounded below. A loss function need at least 2 inputs, one for the prediction, one for the actual value, in order to measure their distance. Large loss is bad because we want the error of the prediction to be as small as possible."/>
            </Fragment>
        } else if (index === 5) {
            return <Fragment>
                <MarkdownTextView rawText={"### Gradient Descent and Loss Minimization\nYour strategy for minimizing your current water depth was to iteratively observe which direction led to the largest decrease in depth, and move in that direction for some amount. The goal is to arrive at an optimal location (i.e. land). This is exactly how **gradient descent** minimizes the loss of neural networks. Given the current state of a neural network, the algorithm calculates (through automatic differentiation) which direction it should adjust each of its parameters in order to arrive at the sharpest decrease in loss, and move a small step in that direction. This procedure of searching for an optimal state according to some objective is called **optimization**. The step size, called **learning rate**, is chosen by the programmer. A large large learning rate means that the neural network is taking large steps when searching for better parameter values, analogous to you moving for a long distance before rechecking where to move next.\n\nWe can visualize how a loss function looks like by plotting its value (on the vertical axis) against each possible combination of the model's parameters. Then gradient descent is like a ball rolling down the hill, as it always follows the direction of steepest descent."} />

                <img src="/assets/chapter2/gradient descent.png" width={500} className="centered"/>
                <div className="image-caption">Illustration of gradient descent. The surface represents the value of the loss function for different state of the neural network model. The model starts somewhere and keeps moving in the direction with the steepest slope in small steps.</div>

                <MarkdownTextView rawText="The value of the loss function for different model states is called the **loss landscape**. The direction of slope at a point is called **gradient**, hence the name *gradient* descent." />

                <MarkdownTextView rawText={"### The Danger of Local Minima and Relationship with Learning Rate\nAs you navigated the sea, you got trapped by a large underground peak. Within its proximity, the gradient descent procedure kept directing you to it. Underground peaks appear in the loss landscapes of neural networks too, and are called a **local minimum**.\n\nA local minimum is a point that has a minimal value compared to its surrounding region (forming a pocket in the loss landscape), but not necessarily the smallest among all possible points. When doing gradient descent, our goal is always to find the **global minimum**, but sometimes, unavoidably, we end up in a local minimum. This is a limitation of gradient descent. Increasing the learning rate can reduce the chance of getting stuck in small local minima pockets, but at the risk of reduced precision when narrowing down on a global minimum. If the only global minimum of a neural network is in a tiny pocket, then with a large learning rate, the neural network could take forever to land inside it."} />

                <img src="/assets/chapter2/local minimum.png" width={500} className="centered" />
                <div className="image-caption">While a larger learning rate can reduce the risk of getting stuck in a local minimum, it increases the risk of not able to land precisely at a global minimum.</div>
            </Fragment>
        } else if (index === 6) {
            // put real definition
            return <Fragment>
                <MarkdownTextView rawText={"### Analogy Correspondence\nThe overwhelming amount of jargon in machine learning can make it intimidating to learn. But here comes the fun part. With the help of analogies, let's review some of the key concepts you've learned using a correspondence table with their analogies that appeared in the story."}/>

                <div className="inline-table">

                    <table className="generic-table">
                        <thead>
                            <th style={{width: "250px", padding: "10px 0px"}}>Analogy</th>
                            <th style={{width: "250px"}}>Key Concept</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Getting spawned at a random initial location in the ocean</td>
                                <td>Model parameter initialization</td>
                            </tr>
                            <tr>
                                <td><MarkdownTextView rawText={"Your current $(x,y)$ location in the ocean"} /></td>
                                <td>Model state / parameter values</td>
                            </tr>
                            <tr>
                                <td>True depth</td>
                                <td>Prediction</td>
                            </tr>
                            <tr>
                                <td>Depth meter</td>
                                <td>Loss function</td>
                            </tr>
                            <tr>
                                <td>Depth measurement as seen on depth meter</td>
                                <td>Loss</td>
                            </tr>
                            <tr>
                                <td>Step size</td>
                                <td>Learning Rate</td>
                            </tr>
                            <tr>
                                <td>Underwater peak</td>
                                <td>Local minimum</td>
                            </tr>
                            <tr>
                                <td>Land</td>
                                <td>Global minimum. For the depth meter, its minimum value is 0, so any land is considered a global minimum.</td>
                            </tr>
                            <tr>
                                <td>Moving the depth meter to find direction of steepest descent</td>
                                <td>(Automatic) Differentiation</td>
                            </tr>
                            <tr>
                                <td>Moving toward land by iterating taking steps in the direction of steepest descent</td>
                                <td>Gradient Descent</td>
                            </tr>
                            <tr>
                                <td>Sea floor</td>
                                <td>Loss Landscape</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <MarkdownTextView rawText={"### Post-completion Quiz\nPlease now complete the [quiz](https://docs.google.com/forms/d/e/1FAIpQLSc7Ba5-iGo7CayBazTTIYQLvcBmJdbBzXje06OREpBzR5Xz5A/viewform?usp=sf_link). It contains 10 multiple choice questions and are the same as those in the diagnostic test."}/>
            </Fragment>
        } else {
            return <Fragment>

            </Fragment>
        }
    }
}

export default C2_ML;