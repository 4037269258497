import axios, { AxiosRequestConfig } from 'axios'
import * as Constants from './Constants'
import { User } from './Data Structures'
import Cookies from 'react-cookies'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export const axiosInstance = axios.create({baseURL: "http://127.0.0.1:3016"});

axiosInstance.interceptors.request.use(req => {
	req.headers.token = localStorage.getItem("accessToken") ?? sessionStorage.getItem("token");
	return req;
});

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
	const [url, config] = Array.isArray(args) ? args : [args];

	const res = await axiosInstance.get(url, {...config});

	return res.data;
};

export interface UserResponse {
    successful: boolean
    token: string
    user_data?: User
}

/**
 * Tries to authenticate the current user based on `localStorage`. Returns the server’s response.
 * @returns A `Promise<UserResponse>`.
 */
export async function authenticate(): Promise<UserResponse> {
    return new Promise<UserResponse>((resolve, reject) => {
        // axios.post(Constants.SERVER + '/accounts/login', undefined, {
        //     headers: {
        //         'token': Cookies.load('token')
        //     },
        // }).then(response => {
        //     if (response.data.successful) {
        //         Cookies.save('token', response.data.token, {
        //             domain: '/',
        //             expires: new Date(Date.now() + 30 * 86400 * 1000)
        //         })
        //     }
        //     resolve(response.data)
        // }).catch(reason => {
        //     reject(reason)
        // })
        resolve({
            successful: true,
            token: "",
            user_data: {
                id: 1,
                name: "Bob",
                email: "bob@abc.edu",
                is_student: true,
                reg_date: "2023-10-30T14:46:54"
            }
        })
    })
}

export function formatDate(date: string | Date | null): string {
    if (!date) {
        return "No Date"
    }
    const originalDate = dayjs(date)
    let formatString = 'MMM D [at] h:mm A'
    if (originalDate.year() !== dayjs().year()) {
        formatString = 'MMM D, YYYY [at] h:mm A'
    }
    
    return originalDate.tz(dayjs.tz.guess()).format(formatString)
}

export function formatTimeInterval(ms: number): string {
    const days = Math.floor(ms / 86400000)
    const hours = Math.floor(ms / 3600000)
    const minutes = Math.floor(ms / 60000)
    const seconds = Math.floor(ms / 1000)
    if (days >= 1) {
        return `${days} Day${days === 1 ? "" : "s"}`
    } else if (hours >= 1) {
        return `${hours} Hour${hours === 1 ? "" : "s"}`
    } else if (minutes >= 1) {
        return `${minutes} Minute${minutes === 1 ? "" : "s"}`
    } else {
        return `${seconds} Second${seconds === 1 ? "" : "s"}`
    }
}

export function getDataUrlFromArr(arr: number[], w: number, h: number, returnCanvas = false) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
  
    canvas.width = w;
    canvas.height = h;

    let formattedArray = []
    for (const pixel of arr) {
        formattedArray.push(pixel, pixel, pixel, 255) // RGBA
    }

    const imgData = ctx.createImageData(w, h);
    imgData.data.set(formattedArray);
    ctx.putImageData(imgData, 0, 0);
    if (returnCanvas) {
        return canvas;
    } else {
        return canvas.toDataURL();
    }
}

export function getDataUrlFrom2DColorArr(arr: number[][][], returnCanvas = false) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
  
    canvas.height = arr[0].length;
    canvas.width = arr[0][0].length;

    let formattedArray: number[] = []
    for (let row = 0; row < canvas.height; row++) {
        for (let col = 0; col < canvas.width; col++) {
            formattedArray.push(arr[0][row][col], arr[1][row][col], arr[2][row][col], 255) // RGBA
        }
    }
  
    const imgData = ctx.createImageData(canvas.width, canvas.height);
    imgData.data.set(formattedArray);
    ctx.putImageData(imgData, 0, 0);
  
    if (returnCanvas) {
        return canvas
    } else {
        return canvas.toDataURL();
    }
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const shuffle = ([...arr]) => {
    let m = arr.length;
    while (m) {
      const i = Math.floor(Math.random() * m--);
      [arr[m], arr[i]] = [arr[i], arr[m]];
    }
    return arr;
};
  
export function colorInterpolate(colorA: number[], colorB: number[], intval: number) {
    const colorVal = (i: number) => Math.round(colorA[i] * (1 - intval) + colorB[i] * intval);
    return {
      r: colorVal(0),
      g: colorVal(1),
      b: colorVal(2),
    }
}
  