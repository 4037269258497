import React, { Fragment, useEffect, useRef, useState } from 'react';
import ExerciseData from './ExerciseData';
import * as tf from "@tensorflow/tfjs"
import Konva from 'konva';
import { InspectorProps, SaveState, TestResult, ValueStore } from '../Interfaces';
import Block from '../Nodes/Block';
import { createBlockFromString } from '../Utils';
import LibraryItem from '../../Components/LibraryItem/LibraryItem';
import InspectorMenuBar from '../MenuBar';
import TabView from '../../Components/TabView/TabView';
import { Layer, Stage } from 'react-konva';
import '../BlockSpace.css'
import "./Exercise.css"
import Split from 'react-split';
import MarkdownTextView from '../../Components/MarkdownTextView/MarkdownTextView';
import TestTable from './TestTable';
import BottomBar from '../../Components/BottomBar/BottomBar';
import { useParams } from 'react-router-dom';
import TensorDisplay from '../Nodes/Inputs/TensorDisplay';
import { ArrowBack } from "@mui/icons-material";
import Loading from '../../Components/Loading';
import { useBoolean } from '../../use-boolean';
import Check from "@mui/icons-material/Check";

interface Props {
    data: ExerciseData
    exerciseId: string
    saveState: SaveState | null
    next: {
        type: "lesson" | "exercise"
        index: number
    } | null
}

const INSPECTOR_MIN_WIDTH = 260
const INSPECTOR_MAX_WIDTH = 400
const INSPECTOR_DEFAULT_SIZE = 320
const LIBRARY_MIN_WIDTH = 280
const LIBRARY_MAX_WIDTH = 500
const LIBRARY_DEFAULT_SIZE = 340

const checkmark = <Check htmlColor="#40a845" fontSize="small" sx={{marginLeft: "2px", height: "19px"}} />

export default function ExerciseView(props: Props) {

    const stageRef = useRef<Konva.Stage | null>(null);
    const [stageWidth, setStageWidth] = useState(1000)
    const mainLayerRef = useRef<Konva.Layer>(null);
    const sidebarRef = useRef<Split | null>(null);
    const rightSidebarRef = useRef<HTMLDivElement | null>(null);
    const valueStoreRef = useRef<ValueStore | undefined>(undefined);
    const canvasSize = useRef<[number, number]>([0, 0]);
    const [showInspector, setShowInspector] = useState<boolean>(false);
    const [inspectorView, setInspectorView] = useState<InspectorProps | undefined>(undefined);
    const autoShowInspectorRef = useRef(true);
    const [inspectorTab, setInspectorTab] = useState<number>(0);
    const isFocused = useRef<boolean>(false);
    // 0 = saving, 1 = saving success, 2 = failed to save
    const [savingStatus, setSavingStatus] = useState(1);
    const savingTimestamp = useRef(0)

    const [toolbarExpanded, setToolbarExpanded] = useState(false);
    const [testResult, setTestResult] = useState<TestResult | undefined>(undefined);

    // SplitView related information
    const splitRef = useRef<Split | null>(null)
    const hsplitSizesRef = useRef<number[]>([0, 0, 0])
    const vsplitSizesRef = useRef<number[]>([58, 42])

    const [currentQuota, setCurrentQuota] = useState(props.data.quota);
    const [testCases, setTrainingExamples] = useState(props.data.trainingCases);
    const [classLabels, setClassLabels] = useState<string[] | undefined>()
    
    const expandCanvas = () => {
        stageRef.current?.width(Math.max(1000, stageRef.current!.container().clientWidth, canvasSize.current[0]))
        stageRef.current?.height(Math.max(1000, stageRef.current!.container().clientHeight, canvasSize.current[1]))
    }

    const deselectAll = () => {
        for (const uuid in valueStoreRef.current!.selection) {
            valueStoreRef.current!.everything[uuid]?.unselect()
        }
        valueStoreRef.current!.selection = {}
        setInspectorView(undefined)
    }

    const params = useParams()

    // useEffect(() => {
    //     console.log(hsplitSizesRef.current)
    //     setTimeout(() => {
    //         const sidebar = document.querySelector('.sidebar') as HTMLDivElement
    //         sidebar.style.width = `${sidebar.clientWidth}px`
    //     }, 1000)
    // }, [hsplitSizesRef.current])

    const saveToCloud = () => {
        if (valueStoreRef.current) {
            setSavingStatus(0)
            // props.data.saveToLocal(valueStoreRef.current)
            const currentTime = Date.now()
            savingTimestamp.current = currentTime
            try {
                props.data.saveToCloud(valueStoreRef.current).then(() => {
                    if (savingTimestamp.current === currentTime) {
                        setSavingStatus(1)
                    }
                }).catch(() => {
                    setSavingStatus(2)
                })
            } catch (error) {
                console.warn(error)
            }
        }
    }

    useEffect(() => {
        const split = document.querySelector('.split') as HTMLDivElement
        const sidebar = document.querySelector('.sidebar') as HTMLDivElement
        //@ts-ignore
        if (showInspector) {
            //@ts-ignore
            const [left, middle, right] = splitRef.current!.split.getSizes()
            const newRight = INSPECTOR_DEFAULT_SIZE / split.clientWidth * 100
            
            const newSizes = [
                left, 100 - left - newRight, newRight
            ]
            hsplitSizesRef.current = newSizes
            const inspectorWidth = newSizes[2] / 100 * split.clientWidth - 2.5;
            // @ts-ignore
            // splitRef.current.split.setSizes(newSizes);
            (split.children.item(3) as HTMLDivElement).style.display = ''
            rightSidebarRef.current!.style.width = `${inspectorWidth}px`
            sidebar.style.width = `${left / 100 * split.clientWidth - 2.5}px`
        } else {
            //@ts-ignore
            const [left, middle, right] = hsplitSizesRef.current!
            const newRight = 2.5 / split.clientWidth * 100
            const newSizes = [
                left, 100 - left - newRight, newRight
            ]
            hsplitSizesRef.current = newSizes;
            // @ts-ignore
            // splitRef.current.split.setSizes(newSizes);
            
            (split.children.item(3) as HTMLDivElement).style.display = 'none'
            rightSidebarRef.current!.style.width = "0px"
            sidebar.style.width = `${left / 100 * split.clientWidth - 2.5}px`
        }
    }, [showInspector])
    
    // Canvas setup code, execute only once
    useEffect(() => {
        const layer = mainLayerRef.current!
        const tooltip = new Konva.Group({visible: false})
        const tooltipRect = new Konva.Rect({
            fill: "#fffde8",
            cornerRadius: 5,
            width: 150,
            height: 50,
            shadowEnabled: true,
            shadowColor: "#101010",
            shadowOpacity: 0.12,
            shadowOffsetY: 2,
            shadowBlur: 10
        })
        tooltip.add(tooltipRect)

        const tooltipText = new Konva.Text({
            padding: 6,
            fontSize: 13,
            fill: "#303030"
        })
        tooltip.add(tooltipText)
        layer.add(tooltip)

        // Handle selection
        stageRef.current!.on("click", e => {
            if (e === null || e.target instanceof Konva.Stage && !e.evt.shiftKey) {
                deselectAll()
            }
        })

        // Expand canvas if needed
        expandCanvas()
        window.addEventListener("resize", expandCanvas)
        window.addEventListener("resize", () => {
            const split = document.querySelector('.split') as HTMLDivElement
            const sidebar = document.querySelector('.sidebar') as HTMLDivElement
            let inspectorWidth = (rightSidebarRef.current?.clientWidth ?? 0) + 2.5
            let totalWidth = split.clientWidth
            let libraryWidth = sidebar.clientWidth + 2.5
            let contentWidth = Math.max(0, totalWidth - inspectorWidth - libraryWidth)
            const newSizes = [
                libraryWidth / totalWidth * 100,
                contentWidth / totalWidth * 100,
                inspectorWidth / totalWidth * 100
            ]
            hsplitSizesRef.current = newSizes
        })

        // Delete button
        window.addEventListener("keydown", (e) => {
            if (e.key === "Backspace" && isFocused.current) {
                let newQuota = Object.assign({}, props.data.quota)
                let changed = false
                for (const uuid in valueStoreRef.current!.selection) {
                    const obj = valueStoreRef.current!.everything[uuid]
                    if (obj.destroy() && currentQuota[obj.quotaId]?.count !== undefined) {
                        newQuota[obj.quotaId].count += 1
                        changed = true
                    } else if (obj.quotaId === "block") {
                        console.warn("did not override type_id for", obj)
                    }
                }
                if (changed) {
                    setCurrentQuota(newQuota)
                    saveToCloud()
                }
            }
        })

        valueStoreRef.current = {
            mainLayer: layer,
            stage: stageRef.current!,
            availableEndpoints: [],
            availableReceptors: [],
            connections: new Map(),
            visitedReceptorCount: new Map(),
            showTooltip: (props) => {
                //@ts-ignore
                tooltipText.width(null)
                tooltipText.text(props.text)
                tooltipText.width(Math.min(200, tooltipText.width()))
                tooltipRect.width(tooltipText.width())
                tooltipRect.height(tooltipText.height())
                tooltip.x(props.position.x + stageRef.current!.offsetX())
                tooltip.y(props.position.y + stageRef.current!.offsetY() + 10)
                tooltip.visible(true)
                tooltip.moveToTop()
            },
            hideTooltip: () => {
                tooltip.visible(false)
            },
            selection: {},
            everything: {},
            activeExercise: props.data,
            chapterId: params.chapter
        }
        
        const canvas = document.querySelector(".blockspace-canvas")!
        canvas.addEventListener("dragover", e => e.preventDefault())
        canvas.removeEventListener("drop", (e) => {})
        canvas.addEventListener("drop", (e) => {
            const dragEvent = e as DragEvent
            const targetX = dragEvent.clientX - canvas.getBoundingClientRect().left + canvas.scrollLeft
            const targetY = dragEvent.clientY - canvas.getBoundingClientRect().top + canvas.scrollTop
            
            const blockType = dragEvent.dataTransfer!.getData("text/plain")
            const jsonData = dragEvent.dataTransfer!.getData("application/json")

            
            const block = createBlockFromString(blockType, jsonData ? JSON.parse(jsonData) : undefined)
            block.globalState = valueStoreRef.current!
            if (block.element) {
                block.element.x(targetX - block.element.width() / 2 + stageRef.current!.offsetX())
                if (block instanceof TensorDisplay) {
                    block.element.y(targetY + stageRef.current!.offsetY())
                } else {
                    block.element.y(targetY - block.element.height() / 2 + stageRef.current!.offsetY())
                }
                layer.add(block.element)
                valueStoreRef.current!.everything[block.id] = block
                block.finishSetup((newValue) => {
                    setInspectorView(newValue)
                    if (newValue !== undefined && !showInspector) {
                        onShowInspector(true)
                    }
                }, setInspectorView)
                deselectAll()
                block.select()
                isFocused.current = true
                if (blockType in props.data.quota && currentQuota[blockType].count !== undefined) {
                    let newQuota = Object.assign({}, props.data.quota)
                    newQuota[blockType].count -= 1
                    setCurrentQuota(newQuota)
                    saveToCloud()
                }
            }
        })

        stageRef.current?.on("dragend", e => {
            saveToCloud()
        })

        // Call setup from Exercise
        props.data.setup(layer, valueStoreRef.current!, onShowInspector, setInspectorView)
        // props.data.restoreFromLocal().then(() => setCurrentQuota(props.data.quota))
        props.data.restoreFromCloud(props.saveState).then(() => {
            setCurrentQuota(props.data.quota)
        })

        // Fetch training examples
        props.data.generateTestCases().then(examples => {
            setTrainingExamples(examples)
            setClassLabels(props.data.getClassLabels())
            props.data.onTestCasesUpdated()
            console.log(props.data.defaultBlocks)
        })


        if (tf.ENV.get('WEBGL_VERSION') === 0) {
            console.log('WebGL is not supported in this environment.');
        } else {
            // Enable WebGL backend
            tf.setBackend('webgl');
            console.log('WebGL backend enabled.');
        }

        const req = indexedDB.open("main")
        req.onupgradeneeded = e => {
            const target = e.target as IDBOpenDBRequest
            const db = target.result;
            db.createObjectStore("weights")
        }
    }, [])

    const onShowInspector = (v: boolean) => {
        if (!autoShowInspectorRef.current) { return }
        const split = document.querySelector('.split') as HTMLDivElement
        const sidebar = document.querySelector('.sidebar') as HTMLDivElement
        let inspectorWidth = rightSidebarRef.current!.clientWidth + 2.5
        let totalWidth = split.clientWidth
        let libraryWidth = sidebar.clientWidth + 2.5
        let contentWidth = Math.max(0, totalWidth - inspectorWidth - libraryWidth)
        const newSizes = [
            libraryWidth / totalWidth * 100,
            contentWidth / totalWidth * 100,
            inspectorWidth / totalWidth * 100
        ]
        hsplitSizesRef.current = newSizes
        setShowInspector(v)
    }

    let inspectorContent: React.ReactNode = <Fragment />
    if (inspectorTab === 0) {
        inspectorContent = inspectorView?.docs ?? <div className='doc'>
            <h3>Documentation</h3>
            <MarkdownTextView rawText={`No documentation available.`}/>
        </div>
    } else if (inspectorTab === 1) {
        inspectorContent = <Fragment>
            <div style={{margin: '15px 3px'}}>Settings for <b>{inspectorView?.title}</b></div>
            {inspectorView?.settings}
            <div className='alert-button-group'>
                {inspectorView?.buttons?.map((buttonInfo, i) => {
                    return <button onClick={ e => {
                        if (buttonInfo.onClick) {
                            buttonInfo.onClick()
                            saveToCloud()
                        }
                    }} className={`alert-button alert-button-${buttonInfo.type} ${buttonInfo.disabled ? "alert-button-disabled" : ""}`} disabled={buttonInfo.disabled} key={i}>{buttonInfo.title}</button>
                })}
            </div>
        </Fragment>
    }
 
    let inspectorMain: JSX.Element
    if (inspectorView === undefined) {
        inspectorMain = <div className='center-content' style={{textAlign: "center", width: "100%", userSelect: "none"}}>
            No Element Selected.
        </div> 
    } else if (Object.keys(valueStoreRef.current!.selection).length > 1) {
        inspectorMain = <div className='center-content' style={{textAlign: "center", width: "100%", userSelect: "none"}}>
            Multiple Elements Selected.
        </div>
    } else {
        inspectorMain = <div className='inspector-view'>
            <InspectorMenuBar index={inspectorTab} onIndexChanged={(i) => setInspectorTab(i)} />
            <div className='inspector-content'>
                {inspectorContent}
            </div>
        </div>
    }

    return (   
        <div className='exercise' onMouseDown={() => isFocused.current = false}>
            <Split className='split'
                sizes={hsplitSizesRef.current}
                minSize={[0, 400, 0]}
                maxSize={[LIBRARY_MAX_WIDTH, 10000, INSPECTOR_MAX_WIDTH]}
                gutterSize={5}
                ref={(r) => {
                    splitRef.current = r
                    const sidebar = document.querySelector('.sidebar') as HTMLDivElement
                    // Only run once during initialization!
                    if (r !== null && Math.max(...hsplitSizesRef.current!) === 0) {
                        //@ts-ignore
                        let [a, b, c] = r.split.getSizes()
                        const split = document.querySelector('.split') as HTMLDivElement
                        const newA = LIBRARY_DEFAULT_SIZE / split.clientWidth * 100
                        const newSizes = [newA, 100 - newA - c, c]
                        //@ts-ignore
                        // splitRef.current!.split.setSizes(newSizes)
                        hsplitSizesRef.current = newSizes
                    } else if (r !== null) {
                        sidebar.style.width = `${sidebar.clientWidth}px`
                    }
                }}
                snapOffset={0}
                onDrag={oldSizes => {
                    const split = document.querySelector('.split') as HTMLDivElement
                    let inspectorWidth = rightSidebarRef.current!.clientWidth
                    if (inspectorWidth < INSPECTOR_MIN_WIDTH / 2) {
                        inspectorWidth = 0;
                        (split.children.item(3) as HTMLDivElement).style.display = 'none'
                        autoShowInspectorRef.current = false
                    } else if (inspectorWidth < INSPECTOR_MIN_WIDTH) {
                        inspectorWidth = INSPECTOR_MIN_WIDTH;
                        (split.children.item(3) as HTMLDivElement).style.display = ''
                    } else {
                        (split.children.item(3) as HTMLDivElement).style.display = ''
                    }
                    inspectorWidth += 2.5

                    const sidebar = document.querySelector('.sidebar') as HTMLDivElement
                    let libraryWidth = sidebar.clientWidth
                    if (libraryWidth < LIBRARY_MIN_WIDTH / 2) {
                        libraryWidth = 0
                    } else if (libraryWidth < LIBRARY_MIN_WIDTH) {
                        libraryWidth = LIBRARY_MIN_WIDTH
                    }
                    libraryWidth += 2.5
                    let totalWidth = split.clientWidth
                    let contentWidth = Math.max(0, totalWidth - inspectorWidth - libraryWidth)
                    
                    const newSizes = [
                        libraryWidth / totalWidth * 100,
                        contentWidth / totalWidth * 100,
                        inspectorWidth / totalWidth * 100
                    ]
                    hsplitSizesRef.current = newSizes
                    // @ts-ignore
                    splitRef.current.split.setSizes(newSizes)
                }}
                onDragEnd={newSizes => {
                    const split = document.querySelector('.split') as HTMLDivElement
                    const sidebar = document.querySelector('.sidebar') as HTMLDivElement
                    const libraryWidth = newSizes[0] / 100 * split.clientWidth - 2.5

                    const inspectorWidth = newSizes[2] / 100 * split.clientWidth - 2.5
                    if (showInspector !== inspectorWidth > 1) {
                        setShowInspector(inspectorWidth > 1)
                    }
                    rightSidebarRef.current!.style.width = `${inspectorWidth}px`

                    sidebar.style.width = `${libraryWidth}px`
                    hsplitSizesRef.current = newSizes
                }}
                >
                <Split className='sidebar' sizes={vsplitSizesRef.current} direction='vertical' gutterSize={5} snapOffset={0} minSize={[200, 180]} ref={sidebarRef}>
                    <div className='exercise-info'>
                        {props.data.exercise_id !== "demo" && <button className='back-button' onClick={() => window.location.assign(`/chapters/${params.chapter}`)}><ArrowBack fontSize='small' /> Back to Chapter Home</button>}
                        {props.data.instructions}
                        <TestTable testCases={testCases} classLabels={classLabels} exercise_id={props.data.exercise_id} chapterId={params.chapter} selectionChanged={(cases) => {
                            props.data.activeExampleIndices = cases
                            props.data.onTestCasesUpdated()
                        }} showDisplayOptions={props.data.showDisplayOptions} enableMultiselect={props.data.enableCaseMultiselect} />
                    </div>
                    <div className='block-library'>
                        <h3>Block Library</h3>
                        <div className="subtitle">Choose blocks to drag into the crafting table.</div>
                        {props.data.getLibraryViewWithQuota(currentQuota)}
                    </div>
                </Split>
                <div className='blockspace-container'>
                    <Stage width={stageWidth} height={2000} className='blockspace-canvas' ref={stageRef} onMouseDown={e => {
                        isFocused.current = true
                        e.evt.stopPropagation()
                    }}>
                        <Layer ref={mainLayerRef} />
                    </Stage>
                    <div style={{height: 0, overflow: "visible", position: "relative", userSelect: "none", pointerEvents: "none"}}>
                        <div style={{backgroundColor: "transparent", color: "gray", fontSize: 14, display: "flex", alignItems: "center", justifyContent: "flex-end", position: "absolute", right: 5, bottom: 0, userSelect: "none", columnGap: 3, height: "20px"}}>
                            {savingStatus === 0 ?
                            <><div>Saving...</div><Loading size={15} /></>
                            :
                            (savingStatus === 1 ? <><div>Saved to Cloud</div>{checkmark}</> : <><div>Saving Failed</div><img src="/assets/failed.png" width={17} /></>)
                            }
                        </div>
                    </div>
                    <BottomBar exerciseId={props.data.exercise_id} isExpanded={toolbarExpanded} testResult={testResult} initialStars={null} toggleExpand={() => setToolbarExpanded(!toolbarExpanded)} onRun={() => {
                        if (testResult?.status === "running") {
                            props.data.stop()
                            let newResult = Object.assign({}, testResult)
                            newResult.status = "ready"
                            setTestResult(newResult)
                        } else {
                            props.data.assess((result) => setTestResult(result))
                        }
                    }} onHeightChanged={(height) => {
                        const cv = document.querySelector(".blockspace-canvas") as HTMLDivElement
                        cv.style.bottom = `${height}px`
                    }} nextLink={props.next ? `/chapters/${params.chapter}/${props.next.type}s/${props.next.index+1}` : undefined} />
                </div>
                <div className='right-sidebar' ref={rightSidebarRef} style={{
                    width: 0, // will be overriden
                    // pointerEvents: showInspector ? "all" : "none"
                }}>
                    <img src="/assets/close.svg" width={15} className='close' onClick={() => {
                        const split = document.querySelector('.split') as HTMLDivElement
                        const sidebar = document.querySelector('.sidebar') as HTMLDivElement
                        let inspectorWidth = 2.5

                        let libraryWidth = sidebar.clientWidth + 2.5
                        let totalWidth = split.clientWidth
                        let contentWidth = Math.max(0, totalWidth - inspectorWidth - libraryWidth)
                        const newSizes = [
                            libraryWidth / totalWidth * 100,
                            contentWidth / totalWidth * 100,
                            inspectorWidth / totalWidth * 100
                        ]
                        hsplitSizesRef.current = newSizes
                        autoShowInspectorRef.current = false
                        setShowInspector(false)
                    }} />
                    {inspectorMain}
                </div>
            </Split>
            {!showInspector && <img src="/assets/info.svg" className='show-inspector-icon' width={25} height={25} onClick={() => {
                const split = document.querySelector('.split') as HTMLDivElement
                const sidebar = document.querySelector('.sidebar') as HTMLDivElement
                let inspectorWidth = 300
                let totalWidth = split.clientWidth
                let libraryWidth = sidebar.clientWidth + 2.5
                let contentWidth = Math.max(0, totalWidth - inspectorWidth - libraryWidth)
                const newSizes = [
                    libraryWidth / totalWidth * 100,
                    contentWidth / totalWidth * 100,
                    inspectorWidth / totalWidth * 100
                ]
                hsplitSizesRef.current = newSizes
                setShowInspector(true)
            }} />}
        </div>
    );
}

