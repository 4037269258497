import * as React from 'react';
import { ChapterPreview } from '../../../Data Structures';
import "./ChapterPreviewCell.css"
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';

interface Props {
    data: ChapterPreview
    index: number
    locked: boolean
}

export default function ChapterPreviewCell(props: Props) {
    return <a className='chapter-preview-cell' href={props.locked ? "#" : `/chapters/${props.index}`}>
        <h3>{props.data.title}</h3>
        <div style={{padding: "5px", fontSize: 15}}>
            {props.data.description}
        </div>
        <br />
        <br />
        <div style={{padding: '0 10px'}}>
            <ProgressBar value={props.data.exercise_completed} max={props.data.exercise_total} name="Exercises"/>
        </div>
        <div style={{padding: '0 10px', marginTop: '20px'}}>
            <ProgressBar value={props.data.star_completed} max={props.data.exercise_total * 3} name="Stars"/>
        </div>
        <div style={{height: "100px", position: 'relative'}}>
            {props.locked && <img src="/assets/lock.svg" width="60" className='center-content' />}
        </div>
    </a>
}