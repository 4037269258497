import { Layer } from "konva/lib/Layer";
import TabView from "../../../Components/TabView/TabView";
import { ValueStore, InspectorProps, TestResult, TestCaseResult } from "../../Interfaces";
import LibraryItem from "../../../Components/LibraryItem/LibraryItem";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { adjustOffset, createBlockFromString } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { randomInt } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { NegationLayer } from "../../Layers/Arithmetics";
import Literal from "../../Nodes/Inputs/Literal";

class Chapter0_ScalarNegation extends ExerciseData {

    title = "Tutorial Exercise"
    exercise_id = "plus_one_negate"
    enableCaseMultiselect = false
    showDisplayOptions = false

    defaultBlocks: {
        inputBlock?: SymbolicInput
        constant?: Literal
        outputBlock?: OutputBlock
    } = {}

    constructor() {
        super()

        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`In the previous exercise, you built a model that always outputs 3. But that's not very useful. In real life, we'd like an AI model's behavior to change depending on the information given to it. For instance, a good chatbot shouldn't always reply with the same thing. *Intelligence is about making appropriate decisions in a variety of situations.*\n\nTo simulate different inputs for your model, we will introduce the *Input* block, denoted by a purple arrow. An input block can take on different values depending on which ones you select from the example list below. **A good model is one that works well on all provided examples**.\n\nLet's now practice learning from examples. What pattern do you notice in the examples below? Build a model that matches this pattern.`} />
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    // async generateTestCases() {
    //     const inputs = randomInt([5], -999, 999)
    //     this.currentTestCases = inputs.map(x => { return { input: tf.tensor(x), output: tf.tensor(x + 1) } })
    //     return this.currentTestCases
    // }

    async generateTestCases(count?: number) {
        const inputs = randomInt([count ?? 5], -100, 100)
        this.trainingCases = inputs.map(x => { return {
            input: [tf.tensor(x)],
            output: tf.tensor(-(x+1)),
            inputLabels: ["Input"],
            outputLabel: "Output"
        } })
        return this.trainingCases
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "negate": {count: 1, displayedTitle: "Negate"},
            "add": { count: 1 },
            "multiply": { count: 1 },
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        // Test
        this.defaultBlocks.outputBlock = createBlockFromString("tensor_viewer", { customName: "Output", value: { enableToggle: false } }, "outputBlock") as OutputBlock
        this.defaultBlocks.outputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.outputBlock, 700, 320)

        this.defaultBlocks.inputBlock = createBlockFromString("symbolic", { customName: "Input" }, "inputBlock") as SymbolicInput
        this.defaultBlocks.inputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.inputBlock, 220, 300)

        this.defaultBlocks.constant = createBlockFromString("constant", { customName: "Constant", value: { value: 1, enableToggle: false } }, "constant") as Literal
        // this.defaultBlocks.constant.editable = false
        this.addBlockAtPosition(this.defaultBlocks.constant, 225, 430)
    }

    onTestCasesUpdated(): void {
        if (this.defaultBlocks.inputBlock === undefined || this.trainingCases === undefined) {
            return
        }
        if (this.activeExampleIndices.length === 0) {
            this.defaultBlocks.inputBlock!.applyConcreteValue(undefined)
        } else {
            const filteredInputs = this.activeExampleIndices.map(i => this.trainingCases![i].input[0])
            const combined = tf.stack(filteredInputs, 0)
            this.defaultBlocks.inputBlock!.applyConcreteValue(combined)
        }
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        if (!this.defaultBlocks.inputBlock || !this.defaultBlocks.outputBlock) {
            console.warn("Exercise blocks not initialized", this)
            return
        }

        // Get model by passing in symbolic input
        this.defaultBlocks.inputBlock.applyConcreteValue(undefined)
        const symbolicOutput = this.defaultBlocks.outputBlock.inputs[0].currentValue as tf.SymbolicTensor
        this.onTestCasesUpdated()
        try {
            var model = tf.model({ inputs: this.defaultBlocks.inputBlock.value, outputs: symbolicOutput }) 
            tf.serialization.registerClass(NegationLayer)
            await model.save(`indexeddb://models/${this.exercise_id}`)
            // console.log(model.call(tf.tensor([1]), {}))
        } catch {
            alert("Output is not connected to inputs")
            return
        }
        

        this.worker = new Worker(new URL("./ScalarNegationGrader.ts", import.meta.url))
        this.worker.onmessage = e => {
            onProgressUpdated(e.data)
        }
        this.worker.postMessage({ save_path: `indexeddb://models/${this.exercise_id}` })
    }
}

export default Chapter0_ScalarNegation;