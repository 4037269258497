import Konva from 'konva';
import PrivateComponent from '../../PrivateComponent/PrivateComponent';
import ExerciseView from './Exercise';
import ExerciseData from './ExerciseData';
import chapters from '../../Pages/Content/Chapters';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { axiosInstance, fetcher } from '../../Utils';
import LoadingScreen from '../../Components/LoadingScreen';
import NotFound from '../../DefaultPages/NotFound';
import { EXERCISE_MAP } from '../../Pages/Content/Maps';
import Chapter0_ScalarNegation from './Chapter0/ScalarNegation';
import Chapter0_Tutorial from './Chapter0/C0_tutorial';
import { useEffect, useState } from 'react';
import { useBoolean } from '../../use-boolean';

interface ExerciseProps {
    chapter: number
    exercise_index: number
}

function ExerciseScreen(props: ExerciseProps) {
    const { chapter, exercise } = useParams()
    // const { data, isLoading } = useSWR(`/chapters/${chapter}/exercises/${exercise}`, fetcher)

    const [data, setData] = useState<any | undefined>()
    const isLoading = useBoolean(true)

    useEffect(() => {
        axiosInstance.get(`/chapters/${chapter}/exercises/${exercise}`).then(response => {
            setData(response.data)
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            isLoading.setValue(false)
        })
    }, [])

    if (isLoading.value) {
        return <LoadingScreen />
    }
    else if (!data) {
        return <NotFound />
    }
    const exerciseClass = EXERCISE_MAP[data.data.id]
    if (exerciseClass === undefined) {
        return <NotFound />
    }

    const exData = new exerciseClass()
    return <div className='full-screen'>
        <ExerciseView data={exData} exerciseId={data.data.id} saveState={data.data.playground_state ?? null} next={data.next} />
    </div>
}

export default ExerciseScreen;