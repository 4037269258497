import { useParams } from "react-router-dom";
import chapter1 from "./Chapter1";
import NotFound from "../../DefaultPages/NotFound";
import CHAPTERS from "./Chapters";
import LESSON_MAP from "./Maps";
import useSWR from "swr";
import { fetcher } from "../../Utils";
import LoadingScreen from "../../Components/LoadingScreen";
import React from "react";

export default function Lesson() {
    const { chapter, index } = useParams()
    const { data, isLoading } = useSWR(`/chapters/${chapter}/lessons/${index}`, fetcher)

    if (isLoading) {
        return <LoadingScreen />
    }

    const indexNumber = Number.parseInt(index!)
    if (Number.isNaN(indexNumber) || indexNumber <= 0 || !data) {
        return <NotFound />
    }

    return React.cloneElement(
        LESSON_MAP[data.lesson_id], 
        { interactionStates: data.saved_states, title: data.lesson_title }
    );
    
}