import * as Konva from "konva"
import Block from '../Block';
import ContentBlock from '../ContentBlock'
import Receptor from '../Receptor'
import * as tf from "@tensorflow/tfjs"
import { tensorDescription } from '../../Utils';
import TensorDisplay from "../Inputs/TensorDisplay";
import { InspectorProps } from "../../Interfaces";

class OutputBlock extends TensorDisplay {

    type_id = "tensor_viewer"
    get blockName(): string {
        return "Output"
    }
    get displayedName() { return this.customName ?? "Tensor Viewer" }
    
    constructor(id: string) {
        super(id)
        this.titleLabel.text(this.displayedName)
        this.container.fill("#efefef")

        this.inputs = [
            new Receptor(this, 0, 0, this.titleBar.height() + this.contentHeight / 2)
        ]
    }

    getDocumentation(): string {
        return `Output blocks are where the data in a neural network are read. `
    }

    onInputUpdated(index: number): boolean {
        const newValue = this.inputs[0].currentValue
        if (newValue === null) {
            this.value = undefined
        } else if (newValue === undefined) {
            this.value = undefined
        } else if (newValue instanceof tf.SymbolicTensor) {
            this.value = undefined
            this.updateReceptorsAndEndpoints()
            return !this.globalState.isTraining
        } else {
            this.value = newValue
            this.nativeValue = this.value.dataSync()
        }
        this.currentValue = this.value
        this.updateReceptorsAndEndpoints()
        return true
    }

    updateReceptorsAndEndpoints(): void {
        super.updateReceptorsAndEndpoints()
        
        if (this.isSelected) {
            this.setInspectorFunction?.(this.onClickMenu())
        }
        
        if (this.inputs[0].currentValue instanceof tf.SymbolicTensor) {
            const shape = this.inputs[0].currentValue.shape.map(x => x === null ? "B" : x.toString())
            // if (this.inputs[0].currentValue.shape.length === 1) {
            //     this.text.text(`Waiting for data...\n(Length: ${shape[0]})`)
            // } else {
            //     this.text.text(`Waiting for data...\n(Shape: ${shape.join(" x ")})`)
            // }
        }

        this.inputs[0].element.y(this.titleBar.height() + this.contentHeight / 2)
        if (this.inputs[0].connection) { // doesn't exist when creating new
            const [x1, y1, x2, y2] = this.inputs[0].connection.line.points()
            this.inputs[0].connection.line.points([x1, y1, x2, this.inputs[0].element.absolutePosition().y])
            this.inputs[0].element.fire("mouseleave", {evt: { buttons: 0 }})
        }
    }

    onClickMenu(): InspectorProps {
        const table = <table className='info-table'>
            <tbody>
                <tr>
                    <th>Shapes</th>
                </tr>
                {this.inputs.map((receptor, i) => {
                    const shape = receptor.currentValue?.shape.map(x => x === null ? "B" : x.toString()).join(" x ") ?? "Not Set"
                    return <tr key={i}>
                        <td>{`Input ${i}`}</td>
                        <td>{shape}</td>
                    </tr>
                })}
                {this.outputs.map((endpoint, i) => {
                    const shape = endpoint.currentValue?.shape.map(x => x === null ? "B" : x.toString()).join(" x ") ?? "B"
                    return <tr key={-i}>
                        <td>{`Output ${i}`}</td>
                        <td>{shape}</td>
                    </tr>
                })}
            </tbody>
        </table>
        return {
            title: `${this.displayedName}`,
            settings: table,
            buttons: [],
            docs: this.documentation
        }
    }

    async getStateDict() {
        return {
            enableToggle: this.allowHidingValues
        }
    }

    async loadStateDict(data: Record<string, any>) {
        this.titleLabel.text(this.displayedName)
        this.setEnableToggle(data?.enableToggle ?? true)
    }
}

export default OutputBlock;