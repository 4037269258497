import { Layer } from "konva/lib/Layer";
import TabView from "../../../Components/TabView/TabView";
import { ValueStore, InspectorProps, TestResult, TestCaseResult } from "../../Interfaces";
import LibraryItem from "../../../Components/LibraryItem/LibraryItem";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { createBlockFromString } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { random, round } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { MultiplyConstantLayer, NegationLayer } from "../../Layers/Arithmetics";
import ParameterTuner from "../../Nodes/Training/Tuner";
import { v4 as uuid } from "uuid"
import Parameter from "../../Nodes/Training/Parameter";
import Trainer from "../../Nodes/Training/Trainer";


class Chapter2_LinearParameter2 extends ExerciseData {

    title = "Parameter Tuning"
    exercise_id = "train_single_parameter"
    
    defaultBlocks: {
        inputBlock?: SymbolicInput
        outputBlock?: OutputBlock
        A?: Parameter
        trainer?: Trainer
    } = {}

    constructor() {
        super()

        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`Use the training block to automatically search for the solution.`} />
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    async generateTestCases(count?: number) {
        const inputs = random([100], -50, 50)
        const correctValue = -0.427
        this.trainingCases = inputs.map(x => { return { input: [tf.tensor(x)], output: tf.tensor(x * correctValue) } })

        this.defaultBlocks.trainer!.inputs[0].currentDataset = {
            trainX: tf.stack(this.trainingCases.slice(0, 70).map(d => d.input[0])),
            trainY: tf.stack(this.trainingCases.slice(0, 70).map(d => d.output)),
            evalX: tf.stack(this.trainingCases.slice(70, 100).map(d => d.input[0])),
            evalY: tf.stack(this.trainingCases.slice(70, 100).map(d => d.output))
        }
        this.defaultBlocks.trainer?.onInputUpdated(0)

        return this.trainingCases.slice(0, count ?? 5)
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "multiply": {count: 1},
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        // Test
        this.defaultBlocks.outputBlock = createBlockFromString("tensor_viewer", { customName: "Output 1" }, "outputBlock") as OutputBlock
        this.defaultBlocks.outputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.outputBlock, 700, 250)

        this.defaultBlocks.inputBlock = createBlockFromString("symbolic", { customName: "Input 1" }, "inputBlock") as SymbolicInput
        this.defaultBlocks.inputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.inputBlock, 200, 300)

        this.defaultBlocks.A = createBlockFromString("parameter", { value: { value: 0}, customName: "A" }, "A") as Parameter
        this.defaultBlocks.A.editable = false
        this.addBlockAtPosition(this.defaultBlocks.A, 270, 200)

        this.defaultBlocks.trainer = createBlockFromString("trainer", undefined, "trainer") as Trainer
        this.defaultBlocks.trainer.editable = false
        this.addBlockAtPosition(this.defaultBlocks.trainer, 450, 450)
    }

    onTestCasesUpdated(): void {
        if (this.defaultBlocks.inputBlock === undefined || this.trainingCases === undefined) {
            return
        }
        if (this.activeExampleIndices.length === 0) {
            this.defaultBlocks.inputBlock!.applyConcreteValue(undefined)
        } else {
            const filteredInputs = this.activeExampleIndices.map(i => this.trainingCases![i].input[0])
            const combined = tf.stack(filteredInputs, 0)
            this.defaultBlocks.inputBlock!.applyConcreteValue(combined)
        }
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        if (!this.defaultBlocks.inputBlock || !this.defaultBlocks.outputBlock) {
            console.warn("Exercise blocks not initialized")
            return
        }

        // Get model by passing in symbolic input
        this.defaultBlocks.inputBlock.applyConcreteValue(undefined)
        const symbolicOutput = this.defaultBlocks.outputBlock.inputs[0].currentValue as tf.SymbolicTensor
        this.onTestCasesUpdated()
        try {
            var model = tf.model({ inputs: [this.defaultBlocks.inputBlock.value], outputs: symbolicOutput }) 
            tf.serialization.registerClass(MultiplyConstantLayer)
            await model.save(`indexeddb://models/${this.exercise_id}`)
        } catch {
            alert("Output is not connected to inputs")
            return
        }
        

        this.worker = new Worker(new URL("./Linear Parameter 1 Grader.ts", import.meta.url))
        this.worker.onmessage = e => {
            onProgressUpdated(e.data)
        }
        this.worker.postMessage({ save_path: `indexeddb://models/${this.exercise_id}` })
    }
}

export default Chapter2_LinearParameter2;