import Chapter4_CIFAR_Conv2D from "../../BlockSpace/Exercises/Chapter4/CIFAR Conv2D";
import C4_Convolutions from "../../BlockSpace/Exercises/Chapter4/Lessons/C4_Convolutions";
import Chapter4_MNIST_Conv2D from "../../BlockSpace/Exercises/Chapter4/MNIST Conv2D";
import Chapter4_PhysicalActivities from "../../BlockSpace/Exercises/Chapter4/Human Activities";
import ExerciseScreen from "../../BlockSpace/Exercises/ExerciseScreen";
import { ChapterData } from "../../Data Structures";
import C4_Convolutions_2D from "../../BlockSpace/Exercises/Chapter4/Lessons/C4_Convolutions_2D";
import Chapter4_PhysicalActivitiesPartial1 from "../../BlockSpace/Exercises/Chapter4/Human Activities partial 1";

const chapter4: ChapterData = {
    id: 3,
    title: "Computer Vision and Sequential Inference",
    exercises: [
        { data: new Chapter4_PhysicalActivitiesPartial1() },
        { data: new Chapter4_PhysicalActivities() },
        { data: new Chapter4_MNIST_Conv2D() },
        { data: new Chapter4_CIFAR_Conv2D() }
    ],
    lessons: [
        <C4_Convolutions />,
        <C4_Convolutions_2D />
    ],
    content: [
        { type: "lesson", itemIndex: 0, title: "Introduction to Convolutions" },
        { type: "exercise", itemIndex: 0, title: "Identifying Physical Activities with Conv1D" },
        { type: "exercise", itemIndex: 1, title: "Identifying Physical Activities with Conv1D (2)" },
        { type: "lesson", itemIndex: 1, title: "2D Convolutions" },
        { type: "exercise", itemIndex: 2, title: "MNIST Conv2D" },
        { type: "exercise", itemIndex: 3, title: "CIFAR-10 Conv2D" }
    ]
}

export default chapter4;