import * as tf from "@tensorflow/tfjs"
import Block from "../Block";
import Konva from "konva";
import Endpoint from "../Endpoint";
import Receptor from "../Receptor";
import { InspectorProps } from "../../Interfaces";
import { AddConstant, AddLayer, NegationLayer } from "../../Layers/Arithmetics";
import { KonvaEventObject } from "konva/lib/Node";
import ArithmeticBlock from "./ArithmeticBlock";
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";

class Subtract extends ArithmeticBlock {

    layer: tf.layers.Layer = new AddLayer()
    negationLayer = new NegationLayer()

    get blockName() { return "Subtract Block" }
    get displayedName() { return "Subtract Block" }
    type_id = "subtract"

    constructor(id: string) {
        super(id)

        Konva.Image.fromURL("/assets/blocks/minus.svg", img => {
            img.width(30)
            img.height(30)
            img.x(10)
            img.y(this.triangle.height() / 2)
            img.offsetY(img.height() / 2)
            this.element?.add(img)
        })

        this.inputs = [
            new Receptor(this, 0, 0, this.triangle.height() / 3),
            new Receptor(this, 1, 0, this.triangle.height() * 2 / 3)
        ]
        this.outputs = [
            new Endpoint(this, 0, this.triangle.width(), this.triangle.height() / 2)
        ]
    }

    getDocumentation(): string {
        return `Subtracts two tensors \`A\` and \`B\` elementwise, broadcasting if necessary.

$$
f(A, B) = A - B
$$`
    }

    onInputUpdated(index: number): boolean {
        if (this.allRequiredInputsProvided) {
            const a = this.inputs[0].currentValue!
            const b = this.inputs[1].currentValue!

            try {
                if (a instanceof tf.Tensor && b instanceof tf.Tensor) {
                    this.layer = new AddLayer()
                    this.currentValue = this.layer.apply([a, b.neg()]) as tf.Tensor
                } else if (a instanceof tf.Tensor && b instanceof tf.SymbolicTensor) {
                    this.layer = new AddConstant();
                    (this.layer as AddConstant).constantTerm = a
                    this.currentValue = this.layer.apply(this.negationLayer.apply(b)) as tf.SymbolicTensor
                } else if (a instanceof tf.SymbolicTensor && b instanceof tf.Tensor) {
                    this.layer = new AddConstant();
                    (this.layer as AddConstant).constantTerm = b.neg()
                    this.currentValue = this.layer.apply(a) as tf.SymbolicTensor
                } else {
                    this.layer = tf.layers.add()
                    this.currentValue = this.layer.apply([a, this.negationLayer.apply(b)] as tf.SymbolicTensor[]) as tf.SymbolicTensor
                }
            } catch (error) {
                this.triangle.stroke("#f01010")
                this.currentValue = undefined
            }

            if (this.currentValue instanceof Array) {
                this.currentValue = undefined
                this.triangle.stroke("#f01010")
            } else {
                this.triangle.stroke("black")
            }
        } else {
            this.currentValue = undefined
            this.triangle.stroke("black")
        }
        return this.outputs[0].propagate(this.currentValue)
    }

    async getStateDict(): Promise<Record<string, any>> {
        return {}
    }

    async loadStateDict(data: Record<string, any>) {
        
    }
}

export default Subtract;