import React, { useEffect, useRef, useState } from "react"
import { InputDataType, TestCase } from "../Interfaces"
import { tensorDescription, tensorToString } from "../Utils"
import "./TestTable.css"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import { useBoolean } from "../../use-boolean"
import Checkbox from "@mui/material/Checkbox"
import { styled } from "@mui/material/styles"
import BinaryMatrixImage from "./Chapter3/BinaryMatrixImage"
import { axiosInstance, getDataUrlFrom2DColorArr, getDataUrlFromArr } from "../../Utils"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import { useParams } from "react-router-dom"

interface Props {
    exercise_id?: string
    chapterId?: string
    testCases?: TestCase[]
    classLabels?: string[]
    currentSelection?: number[]
    showDisplayOptions: boolean
    enableMultiselect: boolean
    selectionChanged: (activeCases: number[]) => void
}

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    // Add your custom styles here
    color: '#e0e0e0', // Set your custom hex color
    '&.Mui-checked': {
      color: '#8aa8bb',
    },
    '&:hover': {
        backgroundColor: "#fbfaff10"
    }
}));

function TestCaseRow({x, classLabels, showDisplayOptions}: {x: TestCase, classLabels?: string[], showDisplayOptions: boolean}) {
    const [displayType, setDisplayType] = useState<("default" | InputDataType)[]>(x.inputTypes ?? x.input.map(_ => "default"))
    const rowRef = useRef<HTMLTableRowElement>(null)

    return <tbody>
        {x.input.map((input, i) => {
            let imgRef: HTMLImageElement | null = null
            input.array().then(array => {
                if (imgRef) {
                    if (displayType[i] === "grayscale_image") {
                        imgRef.src = getDataUrlFromArr((array as number[]), 28, 28) as string
                    } else if (displayType[i] === "rgb_image") {
                        imgRef.src = getDataUrlFrom2DColorArr(array as number[][][]) as string
                    }
                }
            })

            let inputDisplay: React.ReactNode
            switch (displayType[i]) {
                case "tensor":
                    inputDisplay = <textarea readOnly className='value-cell' style={{padding: "3px 4px", fontSize: 13}} value={tensorToString(input)} ref={r => {
                        if (r) { r.style.height = r.scrollHeight + "px" }
                    }}/>
                    break
                case "grayscale_image":
                case "rgb_image":
                    inputDisplay = <img src="" width={60} style={{imageRendering: "pixelated", borderRadius: 4}} ref={r => imgRef = r} />
                    break
                case "default":
                    // inputDisplay = tensorDescription(input)
                    inputDisplay = <textarea readOnly className='value-cell' style={{padding: "3px 4px", fontSize: 13}} value={tensorDescription(input)} ref={r => {
                        if (r) { r.style.height = 6 + r.value.split("\n").length * 15 + "px" }
                    }}/>
            }

            return <tr key={i} ref={rowRef}>
                <td>
                    {(x.inputLabels?.[i] ?? `Input ${i+1}`) + ":"}
                    {showDisplayOptions && <select className="data-type-select" value={displayType[i]} onChange={e => {
                        const newArray = displayType.slice()
                        newArray[i] = e.target.value as 'default'
                        setDisplayType(newArray)

                        setTimeout(() => rowRef.current?.parentElement?.parentElement?.parentElement?.scrollIntoView({ behavior: "smooth", block: "nearest" }), 100)
                    }}>
                        <option value="default">as overview</option>
                        <option value="tensor">as tensor</option>
                        {x.inputTypes?.[i] === "grayscale_image" && <option value="grayscale_image">as image</option>}
                        {x.inputTypes?.[i] === "rgb_image" && <option value="rgb_image">as image</option>}
                    </select>}
                </td>
                <td onClick={e => e.stopPropagation()} style={{cursor: "text"}}>{inputDisplay}</td>
            </tr>
        })}
        <tr>
            <td>{(x.outputLabel ?? "Target") + ":"}</td>
            <td>
                <textarea readOnly className='value-cell' style={{padding: "3px 4px", fontSize: 13}} value={tensorToString(x.output)} ref={r => {
                        if (r) { r.style.height = 6 + r.value.split("\n").length * 15 + "px" }
                }}/>
                {classLabels && ` (${classLabels[x.output.arraySync() as number]})`}
            </td>
        </tr>
    </tbody>
}

export default function TestTable(props: Props) {
    const showResetAlert = useBoolean(false)
    const [selectedRows, setSelectedRows] = useState<number[]>(props.currentSelection ?? [0])
    const isResetting = useBoolean(false)
    
    useEffect(() => {
        props.selectionChanged(selectedRows)
    }, [selectedRows])

    useEffect(() => {
        setSelectedRows(props.currentSelection ?? [0])
    }, [props.currentSelection])

    return <div className="test-table">
        {props.testCases && props.testCases.length > 0 && <div className="heading">
            <div>Examples (select to test):</div>
            {props.enableMultiselect && <button onClick={() => selectedRows.length === props.testCases?.length ? setSelectedRows([]) : setSelectedRows(Array(props.testCases?.length ?? 0).fill(0).map((_, i) => i))}>
                {selectedRows.length === 5 ? "Deselect All" : "Select All"}
            </button>}
        </div>}
        {props.testCases ? props.testCases.map((x, i) => {
            const onSelect = () => {
                if (props.enableMultiselect) {
                    const index = selectedRows.indexOf(i)
                    let newRows = selectedRows.slice()
                    if (index === -1) {
                        newRows.push(i)
                    } else {
                        newRows.splice(index, 1)
                    }
                    newRows.sort()
                    setSelectedRows(newRows)
                } else {
                    setSelectedRows([i])
                }
            }
            return <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "-8px"}} key={i}>
                <CustomCheckbox checked={selectedRows.includes(i)} onChange={onSelect} sx={{flexGrow: 0, p: 1}} />
                <table className={"test-case" + (selectedRows.includes(i) ? " selected" : "")} key={i} onClick={onSelect}>
                    <TestCaseRow x={x} classLabels={props.classLabels} showDisplayOptions={props.showDisplayOptions} />
                </table>
            </div>
        }) : <div>
            Loading Examples...
        </div>}
        {<div style={{
            display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",
            margin: "15px 0px 10px"
        }}>
            <span>Reset Exercise</span>
            <button onClick={showResetAlert.onTrue}>Reset</button>
        </div>}
        <Dialog open={showResetAlert.value} onClose={showResetAlert.onFalse} className="reset-dialog">
            <DialogTitle>Reset Exercise Progress?</DialogTitle>
            <DialogActions>
                <Button onClick={showResetAlert.onFalse}>Cancel</Button>
                {/* <button onClick={() => {
                    localStorage.removeItem(`saved_data/${props.exercise_id}`)
                    const req = indexedDB.open("main")
                        req.onsuccess = e => {
                        const db = (e.target as IDBOpenDBRequest).result
                        const tx = db.transaction("weights", "readwrite")
                        const store = tx.objectStore("weights")
                        store.getAllKeys().onsuccess = e => {
                            const keys = (e.target as any).result as string[]
                            keys.forEach(key => {
                                if (key.startsWith(props.exercise_id ?? "global")) {
                                    store.delete(key)
                                }
                            })
                            setTimeout(() => window.location.reload(), 100)
                        }
                    }

                }}>Reset</button> */}
                <LoadingButton loading={isResetting.value} onClick={() => {
                    isResetting.setValue(true)
                    const weightDeletePromise = new Promise<void>((r, _) => {
                        const req = indexedDB.open("main")
                            req.onsuccess = e => {
                            const db = (e.target as IDBOpenDBRequest).result
                            const tx = db.transaction("weights", "readwrite")
                            const store = tx.objectStore("weights")
                            store.getAllKeys().onsuccess = e => {
                                const keys = (e.target as any).result as string[]
                                keys.forEach(key => {
                                    if (key.startsWith(props.exercise_id ?? "global")) {
                                        store.delete(key)
                                    }
                                })
                                r()
                            }
                        }
                    })
                    const serverDeleteStatePromise = axiosInstance.delete(`/chapters/${props.chapterId}/exercises/${props.exercise_id}`)
                    Promise.all([weightDeletePromise, serverDeleteStatePromise]).then(() => {
                        isResetting.setValue(false)
                        window.location.reload()
                    })
                }}>Reset</LoadingButton>
            </DialogActions>
        </Dialog>
    </div>
}
