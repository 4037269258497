import { JSX } from "react/jsx-runtime";
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { Fragment } from "react";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import BinaryMatrixImage from "../../Chapter3/BinaryMatrixImage";
import Stack from "@mui/material/Stack"
import { MNIST_Image } from "../../../Interfaces";
import { mnistData } from "../../../../Datasets/MNIST_Dataset";
import "./Number Representation.css"

class C1_NumberRepresentation extends LessonTemplate {
    examples: MNIST_Image[] = []

    constructor(props: LessonTemplateProps) {
        super(props, 4, "How is Information Represented on a Computer?")

        this.loadImages()
    }

    loadImages() {
        mnistData(1).then(images => {
            this.examples = images
            this.forceUpdate()
        })
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            return <Fragment>
                <MarkdownTextView rawText={"### Numbers\nArtificial Intelligence, despite seemingly complicated, is essentially about using and manipulating numbers in meaningful ways. A computer represents all information — whether it be images, sounds, or text — as a bunch of numbers. Numbers are like the blood that flows through AI systems. To begin our journey in AI, we must first understand how numbers are organized internally in an AI system to represent different types of information, and be able to reason about high dimensional data."} />
                
                <MarkdownTextView rawText={"### Scalars\nLet us start with one number:"} />

                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <table className="generic-table">
                        <tbody>
                            <tr>
                                <td>36.5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <MarkdownTextView rawText={"A lot of information in the world can be represented by one number. The technical name for this kind of representation is *scalar*. Most, if not all, of math you have done is on scalars. For example, your body temperature is a scalar, your age is a scalar, the area of a shape is a scalar, and the speed of a car is a scalar. Even one number has the power to capture meaningful information in many situations."} />

                <img src="/assets/chapter1/thermometer.jpg" width={400} className="centered" />

            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <MarkdownTextView rawText={"### Vectors\nFor more complex information, we may need more than one number to fully describe them. For example, we need both a *latitude* and a *longitude* to specify a geographical coordinate on Earth. For example, here is the location of Stanford University:"}/>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <table className="generic-table">
                        <tbody>
                            <tr>
                                <td>37.3</td>
                                <td>-122.1</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <img src="/assets/chapter1/map.png" width={450} className="centered" />

                <MarkdownTextView rawText={"The *latitude* is how North the place is relative to the equator, and the *longitude* is how East the place is relative to Greenwich, England, which also determines the time zone. These two numbers always show up together because they are much more meaningful together than apart. And of course, we always write the latitude first, then the longitude. Having a consistent ordering is crucial."} />

                <MarkdownTextView rawText={"The representation of information as a sequence of numbers is called **vector**. In a vector, each component has a distinct meaning. The number of components (sometimes called *dimensions*) in a vector is its length. So we can say that geographical coordinates are *length-2 vectors*."} />

                <MultipleChoiceQuestion prompt="Which of the following information is not a vector?" options={["The velocity of an object in 3D space.", "The color of a pixel.", "The price of a stock in the last 5 business days.", "The mass of each apple in a bag of 5 apples."]} correctIndex={3} explanation={"Velocity is a vector because velocity has a direction in addition to its magnitude.\n\nA pixel is a length-3 vector because it is represented by 3 colors, the red, green, and blue values.\n\nThe price of a stock in the 5 business days is a length-5 vector because it represents five distinct values, each with a different meaning.\n\nThe masses of apples is not appropriate to be represented by a vector because the order of those values carries no information.\n\nIn contrast, the mass of 5 apples in **ascending order** would be a vector because each component now has a distinct meaning. For example, the first component would be *the mass of the lightest apple among 5 apples*, the second component would be *the mass of the second lighest apple among 5 apples*, etc."} />

                <MarkdownTextView rawText={"### Using vectors in a Playground\nYou can set the value of a Constant block to a vector by entering its textual representation in the input field, which is a standard format called **JSON (Javascript Object Notation)**. In JSON, a vector starts and ends with a pair of square brackets, with values inside that are separated by commas."} />

                <div className="centered" style={{display: "flex", justifyContent: "center", columnGap: 12}}>
                    <img src="/assets/chapter1/vector.png" width={200} style={{objectFit: "contain"}} />
                    <img src="/assets/chapter1/constant_input.png" width={260} />
                </div>

                <MarkdownTextView rawText={"### Sounds\nDid you know that a sound is just a sequence of numbers capturing the vibration of air? Most of the audio files use a sample rate of 44100 Hz, which means that each second of the audio is represented by 44100 numbers. Now you know that to the computer, each second of an audio recording is just a length-44100 vector."} />

                {/* <MarkdownTextView rawText={`
                | Tables   |      Are      |  Cool |
                |----------|:-------------:|------:|
                | col 1 is |  left-aligned | $1600 |
                | col 2 is |    centered   |   $12 |
                | col 3 is | right-aligned |    $1 |

                `} /> */}
            </Fragment>
        } else if (index === 2) {
            return <Fragment>
                <MarkdownTextView rawText={"### Matrices\nYou might see where this is going. Scalars are single numbers and vectors are sequences of numbers extending in one direction. How about *grids* of numbers? Yes they exist, and they have a special name — **matrices**.\n\nA matrix is a 2D grid of numbers. The following is a 2 by 3 (denoted $2 \\times 3$) matrix:"}/>

                <div className="inline-table">
                    <MarkdownTextView rawText="$M = \phantom{ }$"/> <table className="generic-table">
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <MarkdownTextView rawText={"The equivalent textual representation of this matrix in JSON format is\n```json\n[\n    [1, 2, 3],\n    [4, 5, 6]\n]\n```"} />

                <MarkdownTextView rawText={"A number in a matrix is referenced by its row and column. For example, the number 4 is at row 2 column 1. In mathematical notation, $M_{21} = 4$."} />

                <MultipleChoiceQuestion prompt="What is the value of $M_{13}$ for the above matrix?" correctIndex={2} options={["1", "2", "3", "5", "6"]} explanation="$M_{13}$ is the value at row 1 column 3, which is 3."/>

                <br />
                <MarkdownTextView rawText={"#### Black & white images as matrices\n\nAn important type of data that can be represented by matrices is monochrome images. The value of each pixel for a black & white image is a number from 0-255. Thus, a black & white image is just a 2D grid of numbers."} />

                <Stack direction="row" justifyContent="center" my={3}>
                    <BinaryMatrixImage imageWidth={28} pixelSize={20} matrix={this.examples[0]?.image} showPixelValue />
                </Stack>
            </Fragment>
        } else if (index === 3) {
            return <Fragment>
                <MarkdownTextView rawText={"### Tensors\nWe are now moving to 3D and above representations of numbers. Because it's tiring to give a name to each number of dimensions, computer scientists instead decided to call everything **tensors**. A scalar is a 0D tensor, a vector is a 1D tensor, a matrix is a 2D tensor, and beyond that we have 3D, 4D, 5D tensors, etc.\n\nOne of the most common uses of 3D tensors is color images. A color pixel consists of a red, green, and blue value, and an image contains a 2D grid of those pixels, so a color image is represented by $C \\times H \\times W$ numbers (where $C=3$ means number of color channels, $H$ is height, $W$ is width). The length of all the dimensions of a tensor is called the **shape** of the tensor (which is a 1D tensor itself). By convention, RGB images have a shape of $(3, H, W)$ However, some images support transparency, in which case, a fourth channel (called the *alpha channel*) is included, making the shape $(4, H, W)$."}/>
                <img src="/assets/cifar-10.png" width={360} style={{imageRendering: "pixelated", borderRadius: "4px"}} className="centered"/>
                <div className="image-caption">A 32x32 color image is represented by a tensor of shape (3, 32, 32).</div>

                <MarkdownTextView rawText={"#### 4D Tensors\nSuppose that instead of having a single image, we have a video where each frame is a 32x32 RGB image. How would you represent a video numerically? We need a 4th dimension to capture time. For a video of length $T$ consisting of RGB images of height $H$ and width $W$, the shape of its tensor representation would be $(T, 3, H, W)$."}/>
            </Fragment>
        }

        return <Fragment />
    }
}

export default C1_NumberRepresentation;