import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";
import Markdown from "react-markdown";
import EmbeddedPlayground from "../../../EmbeddedPlayground/EmbeddedPlayground";

interface State {
    currentPage: number
}

class C0_Unary extends LessonTemplate {
    constructor(props: LessonTemplateProps) {
        super(props, 2, props.title ?? "Unary Operations")   
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            return <Fragment>
                <MarkdownTextView rawText={"Not all operations require two inputs. Here, we will see another type of operations that transform a single input to one or more outputs. We call such operations **unary operations**. Although you might not have heard this term, you actually use unary operations all the time when doing math."} />

                <MarkdownTextView rawText={"The first unary operation we will see is **negation**. Negation flips the sign of a number, so that a positive number become negative and a negative number become positive. You can think of it as multiplying its input by $-1$.\n\nLet's give it a try. How would you get $-7$? You should rearrange the blocks to position them in a way that makes sense. You don't have to use all operations."} />

                <EmbeddedPlayground height={350} id="negate" title="Using negation" savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 100, y: 75 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 1
                            },
                            customName: "A",
                        },
                        {
                            position: { x: 100, y: 175 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 2
                            },
                            customName: "B",
                        },
                        {
                            position: { x: 100, y: 275 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 3
                            },
                            customName: "C",
                        },
                        {
                            position: { x: 230, y: 100 },
                            quotaId: "negate",
                        },
                        {
                            position: { x: 230, y: 210 },
                            quotaId: "negate",
                        },
                        {
                            position: { x: 350, y: 75 },
                            quotaId: "add"
                        },
                        {
                            position: { x: 350, y: 190 },
                            quotaId: "multiply"
                        },
                        {
                            position: { x: 520, y: 175 },
                            quotaId: "tensor_viewer",
                            customName: "Output",
                            value: {
                                enableToggle: false
                            }
                        }
                    ]
                }} passCriterion={output => output === -7} />
            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <MarkdownTextView rawText={"Next up, we are introducing the **square** and **square root** operations. These two operations frequently appear together, such as in the Pythagorean theorem, so it's a good idea to see them at once.\n\nSuppose we have a right-angled triangle with sides $a$ and $b$ known. Can you build a model that calculates the hypotenuse $c$ using the formula $c = \\sqrt{a^2 + b^2}$?"} />

                <img src="/assets/chapter0/triangle.png" width={320} alt="Right-angled triangle with sides a, b, c" className="centered" />
    
                <EmbeddedPlayground height={320} id="hypotenuse" title="Calculating the hypotenuse using square and square root" savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 100, y: 75 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 6
                            },
                            customName: "a",
                        },
                        {
                            position: { x: 100, y: 225 },
                            typeId: "constant",
                            quotaId: "constant",
                            value: {
                                enableToggle: false,
                                value: 8
                            },
                            customName: "b",
                        },
                        {
                            position: { x: 220, y: 70 },
                            quotaId: "square"
                        },
                        {
                            position: { x: 320, y: 70 },
                            quotaId: "square"
                        },
                        {
                            position: { x: 420, y: 70 },
                            quotaId: "sqrt"
                        },
                        {
                            position: { x: 310, y: 185 },
                            quotaId: "add"
                        },
                        {
                            position: { x: 520, y: 130 },
                            quotaId: "tensor_viewer",
                            customName: "c",
                            value: {
                                enableToggle: false
                            }
                        }
                    ],
    
                }} passCriterion={output => output === 10} />
            </Fragment>
        } else if (index === 2) {
            return <Fragment>
                <MarkdownTextView rawText={"### Rounding\nRounding is the operation of zeroing out the decimal places of a real number so that it becomes an integer."} />
            </Fragment>
        } else {
            return <Fragment />
        }
    }
}

export default C0_Unary;