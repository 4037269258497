import Konva from "konva"
import Endpoint from "../Endpoint"
import Block from '../Block';
import ContentBlock from '../ContentBlock'
import * as tf from "@tensorflow/tfjs"
import { tensorToString } from '../../Utils';
import { InspectorProps } from "../../Interfaces";
import Source from "../SourceBlock";

class Ones extends ContentBlock implements Source {

    type_id = "ones"
    text: Konva.Text
    get displayedName() { return "Ones" }

    constructor(id: string, shape: number[]) {
        super(id, 130, 100)

        this.currentValue = tf.ones(shape)

        this.text = new Konva.Text({
            width: this.container.width(),
            height: this.container.height() - this.titleBar.height(),
            verticalAlign: "middle",
            align: "center",
            fontSize: 15,
            y: this.titleBar.height(),
            fill: "#101010",
            text: "Shape: " + shape.join(" x ")
        })
        this.element.add(this.text)

        this.outputs = [
            new Endpoint(this, 0, this.element.width(), this.titleBar.height() + this.contentHeight / 2),
        ]
        this.outputs[0].currentValue = this.currentValue
    }

    propagate() {
        this.outputs.forEach(o => o.propagate(this.currentValue))
    }

    onClickMenu(): InspectorProps {
        let ref: HTMLInputElement | null = null
        const editArea = <input type="text" placeholder="Shape as 1D Array" defaultValue={JSON.stringify(this.currentValue!.shape.slice(1))} className="custom-textfield" style={{border: "1px solid #f0f0f0", minWidth: '200px'}} ref={(e) => {
            ref = e
            if (e) { e.value = e.defaultValue }
        }} />
        return {
            title: this.displayedName,
            settings: editArea,
            buttons: [
                {
                    title: "Cancel",
                    type: "normal"
                },
                {
                    title: "Save",
                    type: "normal",
                    onClick: () => {
                        try {
                            const shape = JSON.parse(ref!.value)
                            this.text.text("Shape: " + shape.join(" x "))
                            this.currentValue = tf.ones(shape)
                            this.outputs[0].propagate(this.currentValue)
                            this.globalState.visitedReceptorCount.clear()
                            return true
                        } catch (error) {
                            this.globalState.visitedReceptorCount.clear()
                            alert("Invalid shape.")
                            return false
                        }
                    }
                },
                {
                    title: "Delete",
                    type: "destructive",
                    onClick: this.destroy
                }
            ]
        }
    }
}

export default Ones;