import * as Konva from "konva"
import Block from '../Block';
import ContentBlock from '../ContentBlock'
import Receptor from '../Receptor'
import * as tf from "@tensorflow/tfjs"
import { tensorToString } from '../../Utils';

class OutputShape extends ContentBlock {

    type_id = "tensor_info"
    tensorValue?: tf.Tensor
    text: Konva.default.Text

    get displayedName() { return "Tensor Info" }
    
    constructor(id: string) {
        super(id, 180, 150, {
            backgroundColor: "#f0f0f0"
        })

        this.text = new Konva.default.Text({
            width: this.container.width(),
            height: this.container.height() - this.titleBar.height(),
            verticalAlign: "middle",
            align: "center",
            fontSize: 15,
            y: this.titleBar.height(),
            fill: "#101010",
            text: "No Value"
        })
        this.element.add(this.text)
        this.titleLabel.text(this.displayedName)

        this.inputs = [
            new Receptor(this, 0, 0, this.titleBar.height() + this.contentHeight / 2)
        ]
        
    }

    onInputUpdated(index: number): boolean {
        this.currentValue = this.inputs[0].currentValue
        if (this.currentValue === null) {
            this.text.text("Computing...")
        } else if (this.currentValue === undefined) {
            this.text.text("No Value")
        } else {
            const shape = this.currentValue.shape.map(x => x === null ? "?" : x.toString())
            this.text.text("Shape: " + shape.join(" x ") + "\n" + `Type: ${this.currentValue.dtype}`)
        }
        return true
    }
}

export default OutputShape;