import * as React from 'react';
import "./ProgressBar.css"

interface Props {
    height?: number,
    name?: string,
    value: number // 0 - 1
    max: number
}
export default function ProgressBar(props: Props) {
    return <div>
        {props.name && <div className='progress-bar-status'>
            <div>{props.name}</div>
            <div>{`${props.value} / ${props.max}`}</div>
        </div>}
        <div className='progress-bar-track' style={{height: props.height}}>
            <div className='progress-bar-progress' style={{ width: `calc(100% * ${(props.value ?? 0) / Math.max(1, props.max)})` }} />
        </div>
    </div>
}