import React from "react"
import { colorInterpolate } from "../../../Utils"
import { round } from "../../Utils"

interface Props {
    matrix?: number[] // flattened binary image
    imageWidth: number
    pixelSize: number
    showPixelValue?: boolean
    convolvedValues?: (number | undefined)[]
    offset?: { x: number, y: number }
    onCellConvolved?: (x: number, y: number) => void
    activeFilter?: number[][]
}

interface State {
  region?: {x: number, y: number}
}

class BinaryMatrixImage extends React.Component<Props, State> {

  grandparentContainer: HTMLDivElement | null = null
  parentContainer: HTMLDivElement | null = null

  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  render() {
    const { matrix, imageWidth, pixelSize, showPixelValue, activeFilter } = this.props;

    const containerStyle: React.CSSProperties = {
      display: 'grid',
      width: "fit-content",
      gridTemplateColumns: `repeat(${imageWidth}, ${pixelSize}px)`,
      boxShadow: "0x 2px 8px 2px rgba(0, 0, 0, 0.15)",
      borderRadius: "6px",
      overflow: "hidden",
      backgroundColor: "#151515",
      overflowX: "auto",
    };

    const pixelStyle: React.CSSProperties = {
      width: pixelSize,
      height: pixelSize,
      position: "relative",
      userSelect: "none",
      boxSizing: "border-box"
    };
    return (
      <div style={{display: "flex", justifyContent: "center", padding: "10px 0px"}} ref={r => this.grandparentContainer = r} onDragOver={e => {
        if (this.props.offset) {
          const rect = this.parentContainer!.getBoundingClientRect()
          // const grandRect = this.grandparentContainer!.getBoundingClientRect()
          const centerX = e.clientX - this.props.offset.x - rect.left
          const centerY = e.clientY - this.props.offset.y - rect.top
          const rawX = centerX / this.props.pixelSize
          const rawY = centerY / this.props.pixelSize
          const roundedX = Math.round(rawX)
          const roundedY = Math.round(rawY)
          this.props.onCellConvolved?.(roundedX, roundedY)
          if (!this.state.region || this.state.region.x !== roundedX || this.state.region.y !== roundedY) {
            this.setState({ region: { x: roundedX, y: roundedY } })
          }
        }
      }}>
        <div style={containerStyle} ref={r => this.parentContainer = r}>
          {matrix?.map((pixel, pixelIndex) => {
            const pixelRow = Math.floor(pixelIndex / imageWidth)
            const pixelColumn = pixelIndex % imageWidth
            let centerX: number | undefined
            let centerY: number | undefined
            if (this.state.region && activeFilter) {
              centerX = this.state.region.x + Math.floor((activeFilter[0].length - 1) / 2)
              centerY = this.state.region.y + Math.floor((activeFilter.length - 1) / 2)
            }
            const withinRange = (this.state.region && activeFilter && pixelColumn >= this.state.region.x && pixelColumn < this.state.region.x + activeFilter[0].length && pixelRow >= this.state.region.y && pixelRow < this.state.region.y + activeFilter.length && Math.abs(this.state.region.x - pixelColumn) < activeFilter[0].length)
            const convolvedValue = this.props.convolvedValues?.[pixelIndex]
            if (convolvedValue !== undefined) {
              const squashed = 1 / (1 + Math.exp(-convolvedValue))
              const {r, g, b} = colorInterpolate([5, 18, 38], [215, 231, 255], squashed)
              return <div key={pixelIndex}
                style={{
                  ...pixelStyle,
                  backgroundColor: (centerX !== undefined && centerY !== undefined && pixelRow === centerY && pixelColumn === centerX && Math.abs(this.state.region!.x - pixelColumn) < activeFilter![0].length) ? "#50a050" : `rgb(${r}, ${g}, ${b})`,
                  border: (this.state.region && activeFilter && pixelColumn >= this.state.region.x && pixelColumn < this.state.region.x + activeFilter[0].length && pixelRow >= this.state.region.y && pixelRow < this.state.region.y + activeFilter.length) ? "1px dashed #20c020" : "none"
                }}>
                {showPixelValue && <div className="center-content" style={{fontSize: Math.min(9, pixelSize / 2.2), color: squashed > 0.5 ? 'rgba(0,0,0,0.4)' : 'rgba(255,255,255,0.4)'}}>{round(convolvedValue, 2)}</div>}
              </div>
            }
            return <div
                key={`${pixelIndex}`}
                style={{
                ...pixelStyle,
                backgroundColor: `rgb(${pixel}, ${pixel}, ${pixel})`,
                border: withinRange ? "1px dashed #30c030a0" : "none"
                }}
            >
                {showPixelValue && <div className="center-content" style={{fontSize: Math.min(10, pixelSize / 2), color: pixel > 100 ? 'rgba(0,0,0,0.4)' : 'rgba(255,255,255,0.4)'}}>{pixel}</div>}
            </div>
          })}
        </div>
      </div>
    );
  }
}

export default BinaryMatrixImage;