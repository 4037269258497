import { Layer } from "konva/lib/Layer";
import TabView from "../../../Components/TabView/TabView";
import { ValueStore, InspectorProps, TestResult, TestCaseResult } from "../../Interfaces";
import LibraryItem from "../../../Components/LibraryItem/LibraryItem";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { createBlockFromString, round } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { randomInt } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { NegationLayer, SumLayer } from "../../Layers/Arithmetics";

class Chapter1_DotProduct extends ExerciseData {

    title = "Calculating Student Grades"
    exercise_id = "dot_product"

    defaultBlocks: {
        inputBlock?: SymbolicInput
        outputBlock?: OutputBlock
    } = {}

    constructor() {
        super()

        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`Help Ms. Mary calculate raw grades for students in her class. As a reminder, the three grade components are worth 20%, 30%, and 50% of the total grade.

**Hint**: represent the grade weights with a Constant block.`} />
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    // async generateTestCases() {
    //     const inputs = randomInt([5], -999, 999)
    //     this.currentTestCases = inputs.map(x => { return { input: tf.tensor(x), output: tf.tensor(x + 1) } })
    //     return this.currentTestCases
    // }

    async generateTestCases(count?: number) {
        const weights = tf.tensor([0.2, 0.3, 0.5])
        this.trainingCases = randomInt<number[][]>([[count ?? 5], [3]], 1, 101).map(x => {
            const in1 = tf.tensor(x)
            const output = in1.dot(weights)
            return { input: [in1], output: output, inputLabels: ["Component Grades"], outputLabel: "Final Grade (%)" }
        })
        return this.trainingCases
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "multiply": {count: 1},
            "sum": {count: 1},
            "mean": {count: 1},
            "square": {count: 1},
            "add": {count: 1},
            "constant": {count: 1}
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        // Test
        this.defaultBlocks.outputBlock = createBlockFromString("tensor_viewer", { customName: "Final Grade" }, "outputBlock") as OutputBlock
        this.defaultBlocks.outputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.outputBlock, 700, 300 + this.defaultBlocks.outputBlock.contentHeight / 2)

        this.defaultBlocks.inputBlock = createBlockFromString("symbolic", { customName: "Grades" }, "inputBlock") as SymbolicInput
        this.defaultBlocks.inputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.inputBlock, 200, 300)
    }

    onTestCasesUpdated(): void {
        if (this.defaultBlocks.inputBlock === undefined || this.trainingCases === undefined) {
            return
        }
        if (this.activeExampleIndices.length === 0) {
            this.defaultBlocks.inputBlock!.applyConcreteValue(undefined)
        } else {
            const filteredInputs = this.activeExampleIndices.map(i => this.trainingCases![i].input[0])
            console.log(filteredInputs)
            const combined = tf.stack(filteredInputs, 0)
            this.defaultBlocks.inputBlock!.applyConcreteValue(combined)
        }
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        super.assess(onProgressUpdated)
        if (!this.defaultBlocks.inputBlock || !this.defaultBlocks.outputBlock) {
            console.warn("Exercise blocks not initialized")
            return
        }

        // Get model by passing in symbolic input
        this.defaultBlocks.inputBlock.applyConcreteValue(undefined)
        const symbolicOutput = this.defaultBlocks.outputBlock.inputs[0].currentValue as tf.SymbolicTensor
        this.onTestCasesUpdated()
        try {
            var model = tf.model({ inputs: this.defaultBlocks.inputBlock.value, outputs: symbolicOutput }) 
            tf.serialization.registerClass(SumLayer)
            tf.serialization.registerClass(NegationLayer)
            await model.save(`indexeddb://models/${this.exercise_id}`)
            // console.log(model.call(tf.tensor([1]), {}))
        } catch {
            alert("Output is not connected to inputs")
            return
        }
        

        this.worker = new Worker(new URL("./DotProduct Grader.ts", import.meta.url))
        this.worker.onmessage = e => {
            onProgressUpdated(e.data)
        }
        this.worker.postMessage({ save_path: `indexeddb://models/${this.exercise_id}` })
    }
}

export default Chapter1_DotProduct;