import Chapter2_Darts1 from "../../BlockSpace/Exercises/Chapter2/Darts 1";
import C2_Errors from "../../BlockSpace/Exercises/Chapter2/Lessons/Introduction_Errors";
import C3_MNIST from "../../BlockSpace/Exercises/Chapter3/Lessons/MNIST";
import Chapter3_MNIST1 from "../../BlockSpace/Exercises/Chapter3/MNIST";
import Chapter3_MNIST2 from "../../BlockSpace/Exercises/Chapter3/MNIST 2";
import Chapter4_MNIST_Conv2D from "../../BlockSpace/Exercises/Chapter4/MNIST Conv2D";
import ExerciseScreen from "../../BlockSpace/Exercises/ExerciseScreen";
import { ChapterData } from "../../Data Structures";

const chapter3: ChapterData = {
    id: 3,
    title: "Neural Networks",
    exercises: [
        { data: new Chapter3_MNIST1() },
        { data: new Chapter3_MNIST2() },
        { data: new Chapter4_MNIST_Conv2D() }
    ],
    lessons: [
        <C3_MNIST />
    ],
    content: [
        { type: "lesson", itemIndex: 0, title: "Handwritten Digits" },
        { type: "exercise", itemIndex: 0, title: "MNIST" },
        { type: "exercise", itemIndex: 1, title: "MNIST 2" },
    ]
}

export default chapter3;