import { Rank, SymbolicTensor, Tensor, layers } from "@tensorflow/tfjs"
import Block from "../Block";
import Konva from "konva";
import Endpoint from "../Endpoint";
import Receptor from "../Receptor";
import { MeanLayer, NegationLayer, SumLayer } from "../../Layers/Arithmetics";
import { InspectorProps } from "../../Interfaces";
import { KonvaEventObject } from "konva/lib/Node";
import AggregationBlock from "./AggregationBlock";

class Mean extends AggregationBlock {

    type_id = "mean"
    layer = new MeanLayer({ axis: null })

    get blockName() { return "Mean" }
    get displayedName() { return "Mean Block" }
    get description() {
        return `Ax${this.layer?.axis instanceof Array && this.layer.axis.length > 1 ? "e" : "i"}s: ${this.layer?.axis ?? "all"}`
    }

    constructor(id: string) {
        super(id)

        this.title.text("Mean")
        this.subtitleLabel.text(this.description)
    }

    getDocumentation(): string {
        return `Takes average over elements in the specified dimensions. By default, averages all the numbers in the input. Like Sum, negative dimensions count backward.`
    }
}

export default Mean;