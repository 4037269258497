import * as Konva from "konva"
import Endpoint from "../Endpoint"
import Block from '../Block';
import ContentBlock from '../ContentBlock'
import * as tf from "@tensorflow/tfjs"
import { tensorDescription, tensorToString } from '../../Utils';
import { InspectorProps } from "../../Interfaces";
import TensorDisplay from "./TensorDisplay";
import Source from "../SourceBlock";

class Literal extends TensorDisplay implements Source {

    type_id = "constant"
    get displayedName() { return this.customName ?? "Constant" }
    get blockName(): string {
        return "Constant"
    }

    getDocumentation(): string {
        return `Constant blocks can hold any numeric value that you assign, and cannot be changed on its own.

- To represent a single number, just enter it into the textbox. For example, \`-1.23\`.
- To represent a (1D) vector, use square brackets ([]). For example, \`[1, 2, 3, 4]\` turns into the vector \n$$\n\\left[\\begin{array}{c}1 \\\\ 2 \\\\ 3 \\\\ 4\\end{array}\\right].\n$$
- To represent a 2D grid of numbers (called a *matrix*), use two layers of brackets. Layer 1 is the row, layer 2 is the column. For example,
\`\`\`python
[ [1, 2], [3, 4] ]
\`\`\`
becomes the matrix \n$$\n\\left[\\begin{array}{c}1 & 2 \\\\ 3 & 4\\end{array}\\right].\n$$
`
    }

    constructor(id: string) {
        super(id)

        this.titleLabel.text(this.displayedName)

        this.outputs = [
            new Endpoint(this, 0, this.container.width(), this.titleBar.height() + this.contentHeight / 2)
        ]
    }

    updateReceptorsAndEndpoints(): void {
        super.updateReceptorsAndEndpoints()

        this.outputs[0].element.y(this.titleBar.height() + this.contentHeight / 2)
        this.outputs[0].element.x(this.container.width())
        this.outputs[0].connections.forEach(c => {
            const [x1, y1, x2, y2] = c.line.points()
            c.line.points([this.outputs[0].element.absolutePosition().x, this.outputs[0].element.absolutePosition().y, x2, y2])
        })
    }

    async getStateDict(): Promise<Record<string, any>> {
        return {
            value: await this.value?.array(),
            dtype: this.value?.dtype,
            enableToggle: this.allowHidingValues
        }
    }

    async loadStateDict(data: Record<string, any>): Promise<void> {
        try {
            this.setNewValue(tf.tensor(data.value, undefined, data.dtype ?? "float32"), true)
            this.titleLabel.text(this.displayedName)
            this.setEnableToggle(data?.enableToggle ?? true)
        } catch (error) {
            console.warn("Failed to load state dict for constant", data, error)
        }
    }

    propagate() {
        this.outputs.forEach(o => o.propagate(this.value))
    }
}

export default Literal;