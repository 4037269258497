import React, { Component } from 'react'
import './ExerciseCard.css'

interface ExerciseDetailProps {
    index: number
    chapter_id: number
    question_id: number
    score: number
    title: string
}

// auxiliary function that takes a two-digit number as input and returns an object containing images of digits that represent that number
function getDigits (id: number): { digit_0: string; digit_1: string } {
    id += 1; // Note: 1-based indexing, so 0 will return '01'
    const path = process.env.PUBLIC_URL + '/assets/'
    const digits: { [key: number]: string } = {
        0: 'number_0.png',
        1: 'number_1.png',
        2: 'number_2.png',
        3: 'number_3.png',
        4: 'number_4.png',
        5: 'number_5.png',
        6: 'number_6.png',
        7: 'number_7.png',
        8: 'number_8.png',
        9: 'number_9.png'
    };

    const digit_0 = digits[Math.floor(id / 10)]; // Tens Digit 
    const digit_1 = digits[id % 10]; // Ones digit

    return {
        digit_0: path + digit_0,
        digit_1: path + digit_1
    }
}

function renderStars(score: number | null) {
    const emptyStar = "/assets/star_empty.svg";
    const filledStar = "/assets/star_filled.svg";

    let stars = [emptyStar, emptyStar, emptyStar];

    if (score !== null) {
        for (let i = 0; i < score; i++) {
            stars[i] = filledStar;
        }
    }

    return stars.map((src, index) => <img key={index} style={{width: "20px"}} src={src} alt="star"/>);
}

export class ExerciseCard extends Component<ExerciseDetailProps> {
    constructor(props: ExerciseDetailProps) {
        super(props);
        this.state = {};
        console.log(props)
    }

    render() {
        const { index, chapter_id, question_id, score, title } = this.props;
        const { digit_0, digit_1 } = getDigits(index);
        return (
            <a href={ `/chapters/${chapter_id}/exercises/${question_id + 1}`} style={{display: 'inline-block', margin: '10px'}}>
                <div className="exercise-card">
                    <div className="digit-container">
                        <img className="digit_0" src={digit_0} alt="digit_0"></img>
                        <img className="digit_1" src={digit_1} alt="digit_1"></img>
                    </div>
                    <div className="exercise-details">
                        <div className="exercise-title">{title}</div>
                        <div className="exercise-score">{renderStars(score)}</div>
                    </div>
                </div>
            </a>
        )
    }
}

export default ExerciseCard