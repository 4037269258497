import ContentBlock from "../ContentBlock";
import * as tf from "@tensorflow/tfjs"
import Receptor from "../Receptor";
import { getDataUrlFrom2DColorArr, getDataUrlFromArr } from "../../../Utils";
import Konva from "konva";

class ImageViewer extends ContentBlock {
    type_id = "image_viewer"
    get blockName() {
        return "Image Viewer"
    }
    image: Konva.Image
    mainLabel: Konva.Text

    constructor(id: string) {
        super(id, 160, 160 + 20, { backgroundColor: "#f2e8f5", titleBarColor: "#fbefff" })

        this.titleLabel.text("Image Viewer")
        this.image = new Konva.Image({
            image: undefined,
            cornerRadius: 4,
            width: this.container.width() - 10,
            height: this.contentHeight - 10,
            x: 5,
            y: this.titleBar.height() + 5
        })
        this.element.add(this.image)

        this.mainLabel = new Konva.Text({
            width: this.container.width() - 10,
            x: 5,
            y: this.titleBar.height(),
            fontSize: 15,
            fill: "#101010",
            height: this.contentHeight,
            align: "center",
            verticalAlign: "middle",
            text: "No Data"
        })
        this.element.add(this.mainLabel)

        this.inputs = [
            new Receptor(this, 0, 0, this.titleBar.height() + this.contentHeight / 2)
        ]
    }

    getDocumentation(): string {
        return `Visualizes an image. Supports both color and black & white images.`
    }

    onInputUpdated(index: number): boolean {
        const newValue = this.inputs[0].currentValue
        if (newValue === null) {
            this.currentValue = undefined
        } else if (newValue === undefined) {
            this.currentValue = undefined
        } else if (newValue instanceof tf.SymbolicTensor) {
            this.currentValue = undefined
        } else {
            this.currentValue = newValue.squeeze()
        }
        this.updateDisplay()
        return true
    }

    updateDisplay() {
        if (this.currentValue instanceof tf.Tensor) {
            if (this.currentValue.shape.length === 3) {
                this.currentValue.array().then(value => {
                    const canvas = getDataUrlFrom2DColorArr(value as number[][][], true) as HTMLCanvasElement
                    this.image.image(canvas)
                    this.image.cache({ imageSmoothingEnabled: false })
                    this.mainLabel.visible(false)
                    this.image.visible(true)
                })
                this.mainLabel.visible(true)
                this.mainLabel.text("Loading image...")
            } else if (this.currentValue.shape.length === 2) {
                const [h, w] = this.currentValue.shape
                this.currentValue.flatten().array().then(value => {
                    const canvas = getDataUrlFromArr(value as number[], h, w, true) as HTMLCanvasElement
                    this.image.image(canvas)
                    this.image.cache({ imageSmoothingEnabled: false })
                    this.mainLabel.visible(false)
                    this.image.visible(true)
                })
                this.mainLabel.visible(true)
                this.mainLabel.text("Loading image...")
            } else if (this.currentValue.shape.length === 1) {
                const length = this.currentValue.shape[0]
                this.currentValue.array().then(value => {
                    const canvas = getDataUrlFromArr(value as number[], Math.sqrt(length), Math.sqrt(length), true) as HTMLCanvasElement
                    this.image.image(canvas)
                    this.image.cache({ imageSmoothingEnabled: false })
                    this.mainLabel.visible(false)
                    this.image.visible(true)
                })
                this.mainLabel.visible(true)
                this.mainLabel.text("Loading image...")
            } else {
                this.mainLabel.visible(true)
                this.mainLabel.text("Unsupported shape:\n" + this.currentValue.shape.join(" x "))
                this.image.visible(false)
            }
        } else {
            this.mainLabel.text("No Data")
            this.mainLabel.visible(true)
            this.image.visible(false)
        }
    }

    async getStateDict() {
        return {}
    }

    async loadStateDict(data: Record<string, any>) {
        
    }
}

export default ImageViewer;