import { Layer } from "konva/lib/Layer";
import TabView from "../../../Components/TabView/TabView";
import { ValueStore, InspectorProps, TestResult, TestCaseResult } from "../../Interfaces";
import LibraryItem from "../../../Components/LibraryItem/LibraryItem";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { createBlockFromString } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { randomInt } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { AddConstant, MultiplyConstantLayer, NegationLayer } from "../../Layers/Arithmetics";
import Literal from "../../Nodes/Inputs/Literal";

class Chapter1_AverageTotalScore extends ExerciseData {

    title = "Average Quiz Score"
    exercise_id = "average_total_score"
    enableCaseMultiselect = false

    defaultBlocks: {
        inputBlock1?: SymbolicInput
        coefficient?: Literal
        intercept?: Literal
        outputBlock?: OutputBlock
    } = {}

    constructor() {
        super()

        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`You are the teacher of a class and have graded all 10 students on a quiz consisting of 4 questions, each worth 25 points. The scores are organized into a $10 \\times 4$ table, with each row being a student and each column being a question. You would like to calculate the average score of the class as a number out of 100. Build a model to accomplish this.\n\n*Hint: each student's total score is the sum of their scores for the 4 questions.*`} />
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    async generateTestCases(count?: number) {
        const input1 = randomInt([count ?? 5, 10, 4], 0, 26)
        this.trainingCases = input1.map(x => {
            const in1 = tf.tensor(x)
            const out = in1.sum(-1).mean(0)
            return { input: [in1], output: out, inputLabels: ["Scores by Question"], outputLabel: "# Average Score" }
        })
        return this.trainingCases
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "mean": {count: 2},
            "sum": {count: 2},
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        // Test
        this.defaultBlocks.outputBlock = createBlockFromString("tensor_viewer", { customName: "Average Total" }, "outputBlock") as OutputBlock
        this.defaultBlocks.outputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.outputBlock, 700, 250 + this.defaultBlocks.outputBlock.contentHeight / 2)

        this.defaultBlocks.inputBlock1 = createBlockFromString("symbolic", {customName: "Scores"}, "inputBlock1") as SymbolicInput
        this.defaultBlocks.inputBlock1.editable = false
        this.addBlockAtPosition(this.defaultBlocks.inputBlock1, 200, 250)
    }

    onTestCasesUpdated(): void {
        if (this.defaultBlocks.inputBlock1 === undefined || this.trainingCases === undefined) {
            return
        }
        if (this.activeExampleIndices.length === 0) {
            this.defaultBlocks.inputBlock1!.applyConcreteValue(undefined)
        } else {
            let inputs: tf.Tensor[][] = [[]]
            const filteredInputs = this.activeExampleIndices.map(i => this.trainingCases![i].input)
            filteredInputs.forEach((tsArray, i) => {
                tsArray.forEach((ts, j) => {
                    inputs[j].push(ts)
                })
            })
            this.defaultBlocks.inputBlock1!.applyConcreteValue(this.trainingCases![this.activeExampleIndices[0]].input[0])
        }
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        super.assess(onProgressUpdated)
        if (!this.defaultBlocks.inputBlock1 || !this.defaultBlocks.outputBlock) {
            console.warn("Exercise blocks not initialized")
            return
        }

        // Get model by passing in symbolic input
        const inputList = [this.defaultBlocks.inputBlock1]
        inputList.forEach(input => input.applyConcreteValue(undefined))
        const symbolicOutput = this.defaultBlocks.outputBlock.inputs[0].currentValue as tf.SymbolicTensor
        this.onTestCasesUpdated()
        try {
            var model = tf.model({ inputs: inputList.map(x => x.value), outputs: symbolicOutput }) 
            tf.serialization.registerClass(AddConstant)
            tf.serialization.registerClass(MultiplyConstantLayer)
            await model.save(`indexeddb://models/${this.exercise_id}`)
            // console.log(model.call(tf.tensor([1]), {}))
        } catch {
            alert("Output is not connected to inputs")
            return
        }

        this.worker = new Worker(new URL("./AverageTotalScoreGrader.ts", import.meta.url))
        this.worker.onmessage = e => {
            onProgressUpdated(e.data)
        }
        this.worker.postMessage({ save_path: `indexeddb://models/${this.exercise_id}` })
    }
}

export default Chapter1_AverageTotalScore;