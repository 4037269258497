import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./auth-provider";
import LoadingScreen from "../Components/LoadingScreen";

const AuthGuard = () => {
    const { profile, loading } = useAuth()
    if (loading) {
        return <LoadingScreen />
    }
    if (!profile) {
        return <Navigate to="/login" />
    }
    return <Outlet />
}

export default AuthGuard;