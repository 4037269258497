import { Rank, SymbolicTensor, Tensor, layers } from "@tensorflow/tfjs"
import Block from "../Block";
import Konva from "konva";
import Endpoint from "../Endpoint";
import Receptor from "../Receptor";
import { NegationLayer, SumLayer } from "../../Layers/Arithmetics";
import { InspectorProps } from "../../Interfaces";
import { KonvaEventObject } from "konva/lib/Node";
import AggregationBlock from "./AggregationBlock";

class Sum extends AggregationBlock {

    type_id = "sum"
    layer = new SumLayer({ axis: null })

    get blockName() { return "Summation" }
    get displayedName() { return "Summation Block" }
    get description() {
        return `Ax${this.layer?.axis instanceof Array && this.layer.axis.length > 1 ? "e" : "i"}s: ${this.layer?.axis ?? "all"}`
    }

    constructor(id: string) {
        super(id)

        this.title.text("Sum")
        this.subtitleLabel.text(this.description)
    }

    getDocumentation(): string {
        return `Add up all elements in the specified dimensions. By default, adds up all the numbers. Use negative numbers to count backward (for example, -1 means last dimension, -2 means second last dimension)`
    }
}

export default Sum;