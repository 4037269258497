import { useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../Utils";
import LoadingScreen from "../../Components/LoadingScreen";
import NotFound from "../../DefaultPages/NotFound";
import { ChapterData2 } from "../../Data Structures";
import ExerciseCard from "../../Components/ExerciseCard/ExerciseCard";
import "../ChapterHome_old/ChapterHome.css"
import { ArrowBack } from "@mui/icons-material";

function ChapterHome() {
    const { chapter } = useParams()
    const { data, isLoading } = useSWR(`/chapters/${chapter}`, fetcher)

    if (isLoading) {
        return <LoadingScreen />
    } else if (!data) {
        return <NotFound />
    }

    document.title = data.chapter.title

    return <div className='full-screen' style={{padding: '20px'}}>
        <title>{data.chapter.title}</title>
        <div style={{margin: "5px"}}>
            <button className='back-button' onClick={() => window.location.assign('/')}><ArrowBack fontSize='small' /> Back to Home</button>
            <h3>{`Chapter ${chapter}: ${data.chapter.title}`}</h3>
        </div>
        <div className='exercise-grid'>
            {(data as ChapterData2).content.map((item, i) => {
                if (item.type === "exercise") {
                    return <ExerciseCard 
                        key={item.index}
                        index={item.index} // Number to display
                        chapter_id={Number(chapter)}
                        question_id={item.index} 
                        score={0} 
                        title={item.title ?? "Exercise"}
                    />
                } else {
                    return <a href={`/chapters/${chapter}/lessons/${item.index+1}`} style={{margin: '10px', textAlign: "center"}}>
                    <div className='exercise-card'>
                        <div className='center-content'>
                            <b>{item.title}</b>
                        </div>
                    </div>
                </a>
                }
            })}
        </div>
    </div>
}

export default ChapterHome;