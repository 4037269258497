import { Layer } from "konva/lib/Layer";
import TabView from "../../../Components/TabView/TabView";
import { ValueStore, InspectorProps, TestResult, TestCaseResult } from "../../Interfaces";
import LibraryItem from "../../../Components/LibraryItem/LibraryItem";
import ExerciseData, { ItemInfo } from "../ExerciseData";
import { createBlockFromString } from "../../Utils";
import * as tf from "@tensorflow/tfjs"
import MarkdownTextView from "../../../Components/MarkdownTextView/MarkdownTextView";
import SymbolicInput from "../../Nodes/Inputs/SymbolicInput";
import { randomInt } from "mathjs";
import OutputBlock from "../../Nodes/Outputs/OutputBlock";
import { NegationLayer } from "../../Layers/Arithmetics";
import Literal from "../../Nodes/Inputs/Literal";

class Chapter0_Tutorial extends ExerciseData {

    title = "Tutorial Exercise"
    exercise_id = "tutorial_exercise"

    defaultBlocks: {
        inputBlock?: Literal
        outputBlock?: OutputBlock
    } = {}

    constructor() {
        super()

        this.instructions = <div className="instructions">
            <h3>{this.title}</h3>
            <MarkdownTextView rawText={`Welcome to the (full) AI Playground! It's now time to show what you've learned through a practice exercise. In this screen, you will find the following features:
- A Block library from which you can drag blocks into the playground
- A Inspector which contains documentation for the block you selected and settings for that block.
- An autograder which checks the performance of your model.

In this practice exercise, your goal is to output **3**. You have access to 2 Add blocks and 2 Multiply blocks from the block library. Once you're done, press “Run” to check your answer. Again, remember that you can connect a block to multiple receptors.
`} />
        </div>

        this.onTestCasesUpdated = this.onTestCasesUpdated.bind(this);
    }

    // async generateTestCases() {
    //     const inputs = randomInt([5], -999, 999)
    //     this.currentTestCases = inputs.map(x => { return { input: tf.tensor(x), output: tf.tensor(x + 1) } })
    //     return this.currentTestCases
    // }

    async generateTestCases(count?: number) {
        return []
    }

    getInitialQuota(): Record<string, ItemInfo> {
        return {
            "multiply": {count: 2 },
            "add": {count: 2 }
        }
    }

    setup(layer: Layer, store: ValueStore, setShowInspector: (value: boolean) => void, setInspectorView: (view?: InspectorProps | undefined) => void): void {
        super.setup(layer, store, setShowInspector, setInspectorView)

        // Test
        this.defaultBlocks.outputBlock = createBlockFromString("tensor_viewer", { customName: "Output", value: { enableToggle: false } }, "outputBlock") as OutputBlock
        this.defaultBlocks.outputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.outputBlock, 700, 320)

        this.defaultBlocks.inputBlock = createBlockFromString("constant", { customName: "Input", value: { value: -1, enableToggle: false } }, "inputBlock") as Literal
        this.defaultBlocks.inputBlock.editable = false
        this.addBlockAtPosition(this.defaultBlocks.inputBlock, 200, 300)
    }

    onTestCasesUpdated(): void {
        
    }

    async assess(onProgressUpdated: (result: TestResult) => void) {
        if (!this.defaultBlocks.inputBlock || !this.defaultBlocks.outputBlock) {
            console.warn("Exercise blocks not initialized")
            return
        }

        // Get model by passing in symbolic input
        const output = this.defaultBlocks.outputBlock.inputs[0].currentValue as tf.Tensor
        const outputValue = output.arraySync() as number
        this.onTestCasesUpdated()

        onProgressUpdated({
            current: 1,
            result: [{
                case: {
                    input: [],
                    output: 3,
                    inputNames: ["A", "B", "C"],
                    
                },
                pred: { value: outputValue },
                status: outputValue === 3 ? "correct" : "incorrect"
            }],
            status: "done",
            passed: outputValue === 3 ? 1 : 0
        })
    }
}

export default Chapter0_Tutorial;