import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";
import Markdown from "react-markdown";
import * as tf from "@tensorflow/tfjs"
import EmbeddedPlayground from "../../../EmbeddedPlayground/EmbeddedPlayground";

class C1_SumAndMean extends LessonTemplate {
    
    constructor(props: LessonTemplateProps) {
        super(props, 4, "Sum and Mean")
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            return <Fragment>
                <MarkdownTextView rawText={"### Sum\nFor the rest of this chapter, we will continue to learn about the various operations and computations that can be done on tensors. Only then will you be able to understand what's going on inside an AI model. In the last lesson we learned about the *Shape* operation. Now, we will learn a new operation called **Sum**, which you should have used in your math class, but works a bit differently for tensors.\n\nWe'll again use the familiar example tensor from before:"}/>
                <div className="inline-table">
                    <MarkdownTextView rawText="$M = \phantom{ }$"/> <table className="generic-table">
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <MarkdownTextView rawText={"Summation is the operation of adding all the numbers together. For tensors, the *Sum* block, by default, adds all the elements in a tensor together. When applied to $M$, it returns $1+2+3+4+5+6 = 21$. Hopefully there's nothing suprising about that. We can verify this with the mini playground below."} />
                <EmbeddedPlayground id="sum_all" title="Summing Everything" height={260} savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 80, y: 120 },
                            quotaId: "constant",
                            customName: "M",
                            value: {
                                value: [[1,2,3],[4,5,6]]
                            }
                        },
                        {
                            position: {x: 350, y: 93 },
                            quotaId: "sum"
                        },
                        {
                            position: { x: 530, y: 120 },
                            quotaId: "tensor_viewer",
                            customName: "Output"
                        }
                    ]
                }} passCriterion={output => typeof output === "number"} />
            </Fragment>
        } else if (index === 1) {
            return <Fragment>
                <MarkdownTextView rawText={"### Sum by dimension\nWhen summing a tensor, we don't necessarily want to sum everything together. For example, we might want to sum each row separately. AI Playground offers a way to do that by specifying which axis (or axes) to sum. For example, summing by dimension 0 means summing values along the first dimension, collapsing each column into one number.\n\nTry with the example below. Do you see how the output is calculated? Find the Sum block that leads to an output of length 2."} />
                <EmbeddedPlayground id="sum_by_dimension" title="Summing by dimension" height={320} savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 80, y: 146 },
                            quotaId: "constant",
                            customName: "M",
                            value: {
                                value: [[1,2,3],[4,5,6]]
                            }
                        },
                        {
                            position: {x: 350, y: 30 },
                            quotaId: "sum",
                            value: { axis: 0 }
                        },
                        {
                            position: {x: 350, y: 120 },
                            quotaId: "sum",
                            value: { axis: 1 }
                        },
                        {
                            position: {x: 350, y: 210 },
                            quotaId: "sum",
                            value: { axis: [0, 1] }
                        },
                        {
                            position: { x: 530, y: 146 },
                            quotaId: "tensor_viewer",
                            customName: "Output"
                        }
                    ]
                }} passCriterion={output => output instanceof Array && output.length === 2} />
                
                <MarkdownTextView rawText="Notice how the output shape is shorter than the input shape. This is because the summation operation “collapses” the dimensions being summed. If you have a 5D tensor and sum by two of its dimensions, you will get a 3D tensor back. Also, notice how summing by dimensions 0 and 1 for a 2D tensor like $M$ is the same as summing by all dimensions. " />

                <MultipleChoiceQuestion prompt="Is first summing by dimension 0 then by dimension 1 the same as summing by dimensions 0 and 1 together?" correctIndex={1} options={["Yes", "No"]} explanation="Summing by dimension 0 then 1 will cause an error because once dimension 0 is summed, it's taken out of the shape of the tensor, so by the time you sum by dimension 1, the tensor only has one dimension left, so 1 is out of range. Because the shape of the tensor changes after each summation operation, it's recommended to sum all the needed dimensions at once." />
            </Fragment>
        } else if (index === 2) {
            return <Fragment>
                <MarkdownTextView rawText={"### Summing by negative dimensions\n\nPreviously, you learned that negative integers can represent dimensions counting backward. They can indeed be used on *Sum* to specify dimensions. Try predicting the effect of the following Sum operations on the input. Do the outputs agree with your predictions?"} />
                <EmbeddedPlayground id="sum_by_neg_dimension" title="Summing by negative dimensions" height={320} savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 80, y: 146 },
                            quotaId: "constant",
                            customName: "M",
                            value: {
                                value: [[1,2,3],[4,5,6]]
                            }
                        },
                        {
                            position: {x: 350, y: 30 },
                            quotaId: "sum",
                            value: { axis: -1 }
                        },
                        {
                            position: {x: 350, y: 120 },
                            quotaId: "sum",
                            value: { axis: -2 }
                        },
                        {
                            position: {x: 350, y: 210 },
                            quotaId: "sum",
                            value: { axis: [-1, -2] }
                        },
                        {
                            position: { x: 530, y: 146 },
                            quotaId: "tensor_viewer",
                            customName: "Output"
                        }
                    ]
                }} passCriterion={output => true} />
            </Fragment>
        } else if (index === 3) {
            return <Fragment>
                <MarkdownTextView rawText={"### The Mean operation\n\nNow we are moving on to a related operation called *Mean* (or *Average*). Computing the mean is very similar to computing the sum. It simply divides each element being summed by the number of elements in its group. Sum and Mean have the same blue octagon appearance, because they are both **aggregation** operations. Aggregation means condensing the information in a tensor by collapsing some of its dimensions."} />

                <EmbeddedPlayground id="sum_by_mean" title="Aggregating a tensor by mean" height={320} savedStates={this.props.interactionStates} initialState={{
                    connections: [],
                    objects: [
                        {
                            position: { x: 80, y: 146 },
                            quotaId: "constant",
                            customName: "M",
                            value: {
                                value: [[1,2,3],[4,5,6]]
                            }
                        },
                        {
                            position: {x: 350, y: 30 },
                            quotaId: "mean",
                            value: { axis: 0 }
                        },
                        {
                            position: {x: 350, y: 120 },
                            quotaId: "mean",
                            value: { axis: -1 }
                        },
                        {
                            position: {x: 350, y: 210 },
                            quotaId: "mean",
                            value: { axis: null }
                        },
                        {
                            position: { x: 530, y: 146 },
                            quotaId: "tensor_viewer",
                            customName: "Output"
                        }
                    ]
                }} passCriterion={output => true} />
            </Fragment>
        } else {
            return <Fragment />
        }
    }
}

export default C1_SumAndMean;