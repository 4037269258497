import { Rank, SymbolicTensor, Tensor, layers } from "@tensorflow/tfjs"
import Konva from "konva";
import Endpoint from "../Endpoint";
import Receptor from "../Receptor";
import { SquareLayer, SquareRootLayer } from "../../Layers/Arithmetics";
import ArithmeticBlock from "./ArithmeticBlock";

class Square extends ArithmeticBlock {

    type_id = "square"
    layer = new SquareLayer()

    get blockName() { return "Square Block" }
    get displayedName() { return "Square Block" }

    constructor(id: string) {
        super(id)

        this.triangle.points([0, 0, 50, 25, 0, 50])
        this.triangle.strokeWidth(2.5)

        Konva.Image.fromURL("/assets/blocks/square.svg", img => {
            img.width(20)
            img.height(20)
            img.x(5)
            img.y(this.triangle.height() / 2)
            img.offsetY(img.height() / 2)
            this.element?.add(img)
        })

        this.inputs = [
            new Receptor(this, 0, 0, this.triangle.height() / 2),
        ]
        this.outputs = [
            new Endpoint(this, 0, this.triangle.width(), this.triangle.height() / 2)
        ]
    }

    getDocumentation(): string {
        return `Computes squares of the input values elementwise. Mathematically:    
$$
f(x) = x^2.
$$`
    }

    onInputUpdated(index: number): boolean {
        if (this.allRequiredInputsProvided) {
            this.currentValue = this.layer.apply(this.inputs[0].currentValue!) as Tensor | SymbolicTensor
        } else {
            this.currentValue = this.inputs[0].currentValue
        }
        return this.outputs[0].propagate(this.currentValue)
    }

    async loadStateDict(data: Record<string, any>): Promise<void> {
            
    }

    async getStateDict(): Promise<Record<string, any>> {
        return {}
    }
}

export default Square;