import * as React from 'react';
import Loading from './Loading';

interface LoadingScreenProps {
    
}
 
interface LoadingScreenState {
    
}
 
class LoadingScreen extends React.Component<LoadingScreenProps, LoadingScreenState> {
    constructor(props: LoadingScreenProps) {
        super(props);
        this.state = { };
    }
    render() { 
        return <div className='full-screen'>
            <div className='center-content'>
                <Loading />
            </div>
        </div>;
    }
}
 
export default LoadingScreen;