import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useParams } from "react-router-dom"
import HomePage from './Pages/HomePage/HomePage';
import VisitorHome from './VisitorHome/VisitorHome';
import NotFound from './DefaultPages/NotFound';
import ChapterHome from './Pages/ChapterHome/ChapterHome';
import ExerciseScreen from './BlockSpace/Exercises/ExerciseScreen';
import ExerciseView from './BlockSpace/Exercises/Exercise';
import Lesson from './Pages/Content/Lesson';
import PlaygroundView from './BlockSpace/Playground';
import { samplePlaygroundQuota } from './BlockSpace/Interfaces';
import Demo from './BlockSpace/Exercises/Chapter4/Demo';
import DemoExerciseScreen from './BlockSpace/Exercises/DemoExerciseScreen';
import { AuthProvider } from './Auth';
import AuthGuard from './Auth/auth-guard';
import LoginPage from './Auth/Login';

function App() {

    const [loginToken, setLoginToken] = useState<string | null | undefined>(undefined);

    useEffect(() => {
        const storedToken = localStorage.getItem("token")
        if (storedToken) {
            setLoginToken(storedToken)
        }
    }, [])

    if (loginToken || true) {
        return <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element={<AuthGuard />}>
                        <Route path="/" element={<Navigate to="/learning" />} />
                        <Route path='/learning' element={<HomePage />} />
                    </Route>
                    <Route path="/chapters/:chapter" element={<ChapterHome />} />
                    <Route path="/chapters/:chapter/lessons/:index" element={<Lesson />} />
                    <Route path="/chapters/:chapter/exercises/:exercise" element={<GetExerciseHome />} />
                    <Route path="/demo" element={<GetDemoPage />} />
                    <Route path="*" element={<NotFound />} /> 
                    <Route path="/playground/new" element={<PlaygroundView initialQuota={samplePlaygroundQuota} />} />
                    <Route path="/login" element={<LoginPage />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    } else if (loginToken === null) {
        return <BrowserRouter>
            <Routes>
                <Route path='/' element={<VisitorHome />} />
                <Route path="*" element={<Navigate to={"/"}/>} />
            </Routes>
        </BrowserRouter>
    } else {
        return <Fragment />
    }

}

/** Functional wrapper for chapter home. */
function GetExerciseHome(): JSX.Element {
    const { chapter, exercise } = useParams()
    return <ExerciseScreen chapter={Number.parseInt(chapter!)} exercise_index={Number.parseInt(exercise!)} />
}

function GetDemoPage(): JSX.Element {
    return <DemoExerciseScreen />
}

export default App;
