import { Container } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack";
import MultipleChoiceQuestion from "../../../../Components/MultipleChoiceQuestion/MultipleChoiceQuestion";
import LessonTemplate, { LessonTemplateProps } from "../../../../Components/LessonTemplate/LessonTemplate";
import { JSX } from "react/jsx-runtime";
import MarkdownTextView from "../../../../Components/MarkdownTextView/MarkdownTextView";

interface State {
    currentPage: number
}

class C1_DotProduct extends LessonTemplate {
    
    currentError = Number.POSITIVE_INFINITY

    constructor(props: LessonTemplateProps) {
        super(props, 1, "Dot Product")    
    }

    getPageData(index: number): JSX.Element {
        if (index === 0) {
            const weightColor = "#6085bf"
            const scoreColor = "#f7b020"
            return <Fragment>
                <MarkdownTextView rawText={`
Ms. Mary, a physics teacher at XYZ High School, needs your help implementing a system for calculating her students' raw grades.\n\nIn her class, a student's grade is determined by 3 factors: homework (20%), midterm (30%), and final (50%). To calculate a student's raw grade for the semester, she would multiply their score in each category by the weight and add them together. For example, if a student gets 90% on homework, 85% on midterm and 80% on final, their raw grade would be

$$
\\large{\\color{${scoreColor}} 90\\%} \\times {\\color{${weightColor}}0.2} + {\\color{${scoreColor}}85\\%} \\times {\\color{${weightColor}}0.3} + {\\color{${scoreColor}}80\\%} \\times {\\color{${weightColor}} 0.5} = 83.5\\%.
$$

This is equivalent to a *dot product* between the student grade vector $\\left<{\\color{${scoreColor}}90\\%}, {\\color{${scoreColor}}85\\%}, {\\color{${scoreColor}}80\\%}\\right>$ and the weight vector $\\left<{\\color{${weightColor}} 0.2}, {\\color{${weightColor}} 0.3}, {\\color{${weightColor}} 0.5}\\right>$. A dot product is simply the sum of the elementwise products of two vectors. That is, you align the two vectors, multiply each pair of numbers, and add them together.

Can you build a model that helps Ms. Mary compute all students' raw grades in her class?`} />


                <MarkdownTextView rawText="#### Working with Constants" />
                <img src="/assets/chapter1/constant.png" width="40%" className="centered"/>

                <a href="/chapters/1/exercises/2" style={{textDecoration: "none"}}>
                    <button className="next-button">Next</button>
                </a>
            </Fragment>
        } else {
            return <Fragment />
        }
    }
}

export default C1_DotProduct;