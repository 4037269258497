import * as React from 'react';
import * as ReactKonva from "react-konva"
import Konva from "konva"
import Endpoint from "./Endpoint"
import Receptor from "./Receptor"
import { SymbolicTensor, Tensor } from "@tensorflow/tfjs"
import { Stage } from 'konva/lib/Stage';
import { InspectorProps, SaveEntry, ValueStore } from '../Interfaces';
import "./Block.css"
import { KonvaEventObject } from 'konva/lib/Node';
import MarkdownTextView from '../../Components/MarkdownTextView/MarkdownTextView';
import { adjustOffset, distance } from '../Utils';

/** Base class for all block types. */
class Block {

    id: string
    globalState!: ValueStore
    element: Konva.Group | null
    inputs: Receptor[] = []
    outputs: Endpoint[] = []
    currentValue?: Tensor | SymbolicTensor | null
    selectionCallback?: (props?: InspectorProps) => void
    setInspectorFunction?: (props: InspectorProps) => void
    editable = true
    shadowColor = "#70a5fd"
    documentation: JSX.Element
    type_id = "block"
    
    /** Some block types support a custom name for a block instance. */
    customName?: string

    /** To be displayed in documentation */
    get blockName() { return "Generic Block" }

    /** To be displayed in the block library */
    get displayedName() { return "Block" }

    /** The ID used for keeping counts of quotas. It can be more specific than type. For example, MSE loss is for quota ID, loss is for type id. */
    get quotaId() { return this.type_id }

    preparationActions: (() => Promise<void>)[] = []

    constructor(id: string) {
        this.id = id
        this.element = null
        this.destroy = this.destroy.bind(this)
        this.onClickMenu = this.onClickMenu.bind(this)

        this.documentation = <div className='doc'>
            <h3>{this.blockName}</h3>
            <MarkdownTextView rawText={this.getDocumentation()}/>
        </div>
    }

    getDocumentation(): string {
        return `Place introduction for the block type here.
$$
f(x) = \\sigma(xW + b)
$$
`
    }
    
    finishSetup(onSelect: (props?: InspectorProps) => void, setInspectorView: (props: InspectorProps | undefined) => void) {
        this.setInspectorFunction = setInspectorView
        this.element?.on("click", (e) => {
            //@ts-ignore
            if (e.train === 1) {
                return
            }

            if (!e.evt.shiftKey) {
                // If shift is not pressed, first unselect all selected elements
                for (const uuid in this.globalState.selection) {
                    this.globalState.everything[uuid]?.unselect()
                }
            }

            // If selected, unselect self
            if (e.evt.shiftKey && this.isSelected) {
                this.unselect()
            } else {
                this.select(e)
            }
        })

        this.element?.on("dragstart", e => {
            if (!e.evt.shiftKey) {
                this.globalState.stage?.fire("click", {
                    target: this.globalState.stage,
                    evt: { shiftKey: false }
                })
            }
            this.select(e)
        })

        this.inputs.forEach(i => this.globalState?.availableReceptors?.push(i))
        this.outputs.forEach(o => this.globalState?.availableEndpoints?.push(o))


        this.inputs.forEach(x => this.element?.add(x.element)) 
        this.outputs.forEach(x => this.element?.add(x.element))
        // Move connections on drag
        this.element?.on("dragmove", e => {
            if (e.target !== this.element) { return }
            this.redrawConnections()

            // Detect auto-connect
            let closestReceptor: Receptor | undefined
            let closestEndpoint: Endpoint | undefined
            let selectedReceptor: Receptor | undefined
            let closestReceptorDistance = Number.POSITIVE_INFINITY
            let closestEndpointDistance = Number.POSITIVE_INFINITY
            if (this.outputs.length === 1 && this.globalState.availableReceptors.length > 0) {
                const endpoint = this.outputs[0]
                // Associate endpoint with some receptor
                closestReceptor = this.globalState.availableReceptors.reduce((a, b) => {
                    if (a.parent === this) { return b }
                    if (b.parent === this) { return a }
                    const distA = distance(a.element.absolutePosition(), endpoint.element.absolutePosition())
                    const distB = distance(b.element.absolutePosition(), endpoint.element.absolutePosition())
                    return distA < distB ? a : b
                })
                closestReceptorDistance = distance(endpoint.element.absolutePosition(), closestReceptor.element.absolutePosition())
                if (!(e.target instanceof Konva.Circle)) {
                    closestReceptor.ring.visible(false)
                }
            }

            // Connecting receptor to endpoint
            if (this.inputs.length > 0 && this.globalState.availableEndpoints.length > 0) {
                for (const receptor of this.inputs) {
                    for (const endpoint of this.globalState.availableEndpoints) {
                        const dist = distance(receptor.element.absolutePosition(), endpoint.element.absolutePosition())
                        if (dist < closestEndpointDistance) {
                            selectedReceptor = receptor
                            closestEndpointDistance = dist
                            closestEndpoint = endpoint
                            endpoint.ring.visible(false)
                            receptor.ring.visible(false)
                        }
                    }
                }
            }

            if (closestReceptorDistance < 12) {
                closestReceptor!.ring.visible(true)
                this.outputs[0]?.ring.visible(true)
            } else if (!(e.target instanceof Konva.Circle)) {
                closestReceptor?.ring.visible(false)
                this.outputs[0]?.ring.visible(false)
            }
            if (closestEndpointDistance < 12) {
                closestEndpoint!.ring.visible(true)
                selectedReceptor!.ring.visible(true)
            } else if (!(e.target instanceof Konva.Circle)) {
                closestEndpoint?.ring.visible(false)
                selectedReceptor?.ring.visible(false)
            }

        })

        this.element?.on("dragend", e => {
            adjustOffset(this.globalState)
            let closestReceptor: Receptor | undefined
            let closestEndpoint: Endpoint | undefined
            let selectedReceptor: Receptor | undefined
            let closestReceptorDistance = Number.POSITIVE_INFINITY
            let closestEndpointDistance = Number.POSITIVE_INFINITY
            if (this.outputs.length === 1 && this.globalState.availableReceptors.length > 0) {
                const endpoint = this.outputs[0]
                // Associate endpoint with some receptor
                closestReceptor = this.globalState.availableReceptors.reduce((a, b) => {
                    if (a.parent === this) { return b }
                    if (b.parent === this) { return a }
                    const distA = distance(a.element.absolutePosition(), endpoint.element.absolutePosition())
                    const distB = distance(b.element.absolutePosition(), endpoint.element.absolutePosition())
                    return distA < distB ? a : b
                })
                closestReceptorDistance = distance(endpoint.element.absolutePosition(), closestReceptor.element.absolutePosition())
                
            }

            // Connecting receptor to endpoint
            if (this.inputs.length > 0 && this.globalState.availableEndpoints.length > 0) {
                for (const receptor of this.inputs) {
                    for (const endpoint of this.globalState.availableEndpoints) {
                        const dist = distance(receptor.element.absolutePosition(), endpoint.element.absolutePosition())
                        if (dist < closestEndpointDistance) {
                            selectedReceptor = receptor
                            closestEndpointDistance = dist
                            closestEndpoint = endpoint
                            endpoint.ring.visible(false)
                            receptor.ring.visible(false)
                        }
                    }
                }
            }

            let movedCurrent = false

            if (closestEndpointDistance < 12) {
                const { x: rx, y: ry } = closestEndpoint!.element.absolutePosition()
                const { x: ex, y: ey } = selectedReceptor!.element.absolutePosition()
                const { x, y } = this.element!.position()
                this.element?.position({ x: x + rx - ex, y: y + ry - ey })
                closestEndpoint!.addConnectionToReceptor(selectedReceptor!, true, false)
                this.element?.dispatchEvent({
                    type: "dragmove"
                })
                closestEndpoint!.ring.visible(false)
                selectedReceptor!.ring.visible(false)
                movedCurrent = true
            }

            if (closestReceptorDistance < 12) {
                // move to the receptor position (magnetically)
                const { x: rx, y: ry } = closestReceptor!.element.absolutePosition()
                const { x: ex, y: ey } = this.outputs[0].element.absolutePosition()
                const { x, y } = this.element!.position()
                const { x: px, y: py } = closestReceptor!.parent.element!.position()

                if (movedCurrent) {
                    closestReceptor?.parent.element?.position({ x: px + ex - rx, y: py + ey - ry })
                } else {
                    this.element?.position({ x: x + rx - ex, y: y + ry - ey })
                }
                this.outputs[0].addConnectionToReceptor(closestReceptor!, true, false)
                this.element?.dispatchEvent({
                    type: "dragmove"
                })
                closestReceptor!.ring.visible(false)
                this.outputs[0].ring.visible(false)
                if (movedCurrent) {
                    closestEndpoint?.ring.visible(false)
                }
            }
            
            

        })

        this.selectionCallback = onSelect
        
        this.preparationActions.forEach(action => action())
    }

    redrawConnections() {
        this.inputs.forEach(input => {
            input.connectionList.forEach(conn => {
                const [x1, y1, x2, y2] = conn.line.points()
                const currentPos = input.element.absolutePosition()
                conn.line.points([
                    x1,
                    y1,
                    currentPos.x + this.globalState.stage!.offsetX(),
                    currentPos.y + this.globalState.stage!.offsetY(),
                ])
                conn.updateVisibility()        
            })
        })
        this.outputs.forEach(output => {
            const currentPos = output.element.absolutePosition()
            output.connections.forEach(conn => {
                const [x1, y1, x2, y2] = conn.line.points()
                conn.line.points([
                    currentPos.x + this.globalState.stage!.offsetX(),
                    currentPos.y + this.globalState.stage!.offsetY(),
                    x2, y2])
                conn.updateVisibility()
            })
        })

    }

    destroy(force = false) {
        if (!this.editable && !force) { return false }
        this.inputs.forEach(receptor => {
            if (receptor.connection) {
                receptor.connection.start.connections.delete(receptor.connection.id)
                receptor.connection.line.remove()
                this.globalState.connections.delete(receptor.connection.id)
            }
        })
        this.outputs.forEach(endpoint => {
            endpoint.connections.forEach(c => {
                c.end.connection = undefined
                c.line.remove()
                c.end.propagate(undefined)
                this.globalState.connections.delete(c.id)
            })
            endpoint.connections.clear()
        })
        this.element?.remove()
        this.globalState.availableEndpoints = this.globalState.availableEndpoints.filter(endpoint => endpoint.parent.id !== this.id)
        this.globalState.availableReceptors = this.globalState.availableReceptors.filter(receptor => receptor.parent.id !== this.id)
        if (this.id in this.globalState.selection) {
            this.selectionCallback!(undefined)
            delete this.globalState.selection[this.id]
        }
        delete this.globalState.everything[this.id]
        return true
    }

    // onClick(event: Konva.KonvaEventObject<MouseEvent>) {
    //     console.log(this.id)
    // }

    onClickMenu(e?: KonvaEventObject<MouseEvent>): InspectorProps {
        const table = <table className='info-table'>
            <tbody>
                <tr>
                    <th>Shapes</th>
                </tr>
                {this.inputs.map((receptor, i) => {
                    const shape = receptor.currentValue?.shape.map(x => x === null ? "B" : x.toString()).join(" x ") ?? "Not Set"
                    return <tr key={i}>
                        <td>{`Input ${i}`}</td>
                        <td>{shape}</td>
                    </tr>
                })}
                {this.outputs.map((endpoint, i) => {
                    const shape = endpoint.currentValue?.shape.map(x => x === null ? "B" : x.toString()).join(" x ") ?? "B"
                    return <tr key={-i}>
                        <td>{`Output ${i}`}</td>
                        <td>{shape}</td>
                    </tr>
                })}
            </tbody>
        </table>
        return {
            title: this.displayedName,
            settings: table,
            buttons: [],
            docs: this.documentation
        }
    }

    /** Recursively propagate updates. Returns whether the update was successful (true = acyclic). */
    onInputUpdated(index: number): boolean {
        // console.log(`${this.id} received input ${this.inputs[index].currentValue} at index ${index}`)
        /* update currentValue */
        return !this.outputs.some(e => e.propagate(this.currentValue))
    }

    /** Checks that the current node has all the required inputs needed to perform its computation. */
    get allRequiredInputsProvided() {
        return !this.inputs.some(receptor => {
            if (!receptor.isRequired) { return false }
            if (receptor.type === "dataset") {
                return !receptor.currentDataset
            } else if (receptor.type === "model") {
                return !receptor.currentModel
            } else if (!receptor.allowMultiple) {
                return !receptor.currentValue || Math.min(...receptor.currentValue.shape.filter(s => s !== null) as number[]) === 0
            } else {
                return receptor.connectionList.filter(c => !!c.start.currentValue).length === 0
            }
        })
    }

    updateReceptorsAndEndpoints() {
        
    }

    select(e?: KonvaEventObject<MouseEvent>) {
        this.globalState.selection[this.id] = {
            position: this.element!.position(),
            state: {}
        }
        if (this.selectionCallback) this.selectionCallback(this.onClickMenu(e))
    }

    unselect() {
        delete this.globalState.selection[this.id]
    }

    get isSelected() {
        return this.id in this.globalState.selection
    }

    /**
     * Implements serialization of the block data. Can only be called after `finishSetup()`. Subclasses shouldn't override this directly.
     * @returns `SaveEntry`
     */
    async serialize(): Promise<SaveEntry> {
        return {
            position: this.element!.position(),
            typeId: this.type_id,
            quotaId: this.quotaId,
            value: await this.getStateDict(),
            customName: this.customName
        }
    }

    async getStateDict(): Promise<Record<string, any>> { 
        console.warn(`${this.type_id} did not override getStateDict()`)
        return {}
    }

    /** Restores object state from a `SaveEntry`. Subclasses only need to override `loadFromData` instead. */
    async deserialize(obj: SaveEntry) {
        this.element?.visible(false)
        if (obj.position) {
            this.element!.position(obj.position)
        }
        this.customName = obj.customName
        if (this.globalState !== undefined) {
            await this.loadStateDict(obj.value)
            this.element?.visible(true)
        } else {
            this.preparationActions.push(async () => {
                await this.loadStateDict(obj.value)
                this.element?.visible(true)
            })
        }
    }

    /** Every Block subclass should override this method to implement custom deserialization procedure. */
    async loadStateDict(data: Record<string, any>) {
        console.error(`${this.blockName} did not override loadStateDict()!`)
    }

    // Blocks that don't contain trainable weights don't need to implement this function.
    async saveWeights() {
    }

    // Blocks that don't contain trainable weights don't need to implement this function.
    async loadWeights() {
    }
}
 
export default Block;