import { useState } from "react";
import { RHFTextField } from "../hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuthContext } from "./auth-provider";
import { useParams, useSearchParams } from "react-router-dom";
import FormProvider from "../hook-form/form-provider";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Iconify from "../iconify";
import { useBoolean } from "../use-boolean";

const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
});

function LoginPage() {
    const { login } = useAuthContext();
    const password = useBoolean();
    const [errorMsg, setErrorMsg] = useState('');

    const { returnTo, originalParams } = useParams();

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues: {
            email: "",
            password: ""
        }
    });
    
    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
          await login?.(data.email, data.password);
          const newPath = returnTo?.concat(originalParams ?? "") || "/";
          window.location.replace(newPath);
        } catch (error: any) {
          console.error(error);
          // reset();
          setErrorMsg(typeof error === 'string' ? error : error.response?.data?.message ?? "Login failed.");
        }
    });

    return <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2} p={3}>
            {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            <Typography variant="h4">Login to AI Playground</Typography>
            <RHFTextField name="email" label="Email" />
            <RHFTextField
                name="password"
                label="Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                            <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                        </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
            >
                Log in
            </LoadingButton>
        </Stack>
    </FormProvider>
}

export default LoginPage;