import React, { Fragment } from 'react';
import AlertSheet from '../Components/AlertSheet/AlertSheet';
import { CONFIG } from '../Constants';
import Loading from '../Components/Loading';
import { localizedString } from '../Localization';
import Maintenance from '../DefaultPages/Maintenance';
import NavigationBar from '../Components/NavigationBar/NavigationBar';
import NetworkDown from '../DefaultPages/Maintenance';
import * as Utils from '../Utils';
import LoadingScreen from '../Components/LoadingScreen';


class PrivateComponent<P, S> extends React.Component<P, S> {
    needsMaintenance = false;
    networkError = false;
    isLoading = true;
    
    /** If true, will display a loading animation before the data is fetched on the page. */
    showLoadingScreen = true;
    
    /** If true, a navigation bar will not be included at the top of the screen. */
    viewOnly = false;

    /** Whether the page requires authentication. */
    requiresAuthentication = true;
    
    /** Can be used to retrieve user login information. */
    loginStatus?: Utils.UserResponse | null
    
    private isUnmounted = false;
    private errorMessage?: string = undefined;
    private alertSheet?: JSX.Element

    /**
     * The function that fetches data to render the view. To confirm that the view is loaded, you either call `resolve()` or update the state.
     */
    async loadData(resolve: (statusCode: number) => void) {
        // Should override
    }

    constructor(props: P) {
        super(props);

        this.loadData = this.loadData.bind(this);
        this.showAlertSheet = this.showAlertSheet.bind(this);
        this.dismissAlertSheet = this.dismissAlertSheet.bind(this);
        this.safeRender = this.safeRender.bind(this);
        document.title = localizedString("Loading...")
    }

    componentDidMount() {
        if (this.errorMessage) { return }

        let promise = new Promise<number>((resolve, reject) => {
            Utils.authenticate().then((loginStatus) => {
                this.loginStatus = loginStatus
                this.forceUpdate()
            }).catch((err) => {
                this.loginStatus = null
                if (err && err.response?.status === 500) {
                    this.needsMaintenance = true
                }
                if (this.requiresAuthentication) window.location.assign('/login')
            }).finally(() => {
                this.loadData(resolve)
            })
        })
            
        // Handle non-200 status codes
        promise.then(statusCode => {
            if (this.isUnmounted || this.errorMessage) { return }
            this.isLoading = false
            if (statusCode === -1) {
                this.networkError = true;
            } else if (200 <= statusCode && statusCode < 300) {
                this.needsMaintenance = false
            } else if (statusCode === 401) {
                window.location.assign('/login')
            } else if (statusCode >= 400) {
                this.needsMaintenance = true
            } else if (statusCode >= 500) {
                this.needsMaintenance = true
            }
            this.forceUpdate()
        })
    }

    componentWillUnmount() {
        this.isUnmounted = true
    }

    failWithMessage(message: string | undefined | null) {
        this.isLoading = false
        this.errorMessage = message || "An unknown error occurred while loading this page."
        document.title = "Error"
        this.forceUpdate()
    }

    refreshData() {
        this.componentDidMount()
    }

    render(): React.ReactNode {
        if (this.loginStatus === undefined && !this.errorMessage && this.requiresAuthentication) {
             return <Fragment />
        }
        let innerView: React.ReactNode
        if (this.needsMaintenance || CONFIG.site_is_down) {
            innerView = <Maintenance />
        } else if (this.networkError) {
            innerView = <NetworkDown />
        } else if (this.isLoading) {
            innerView = this.showLoadingScreen ? <LoadingScreen /> : <Fragment />
        } else if (this.errorMessage) {
            innerView = <Fragment>
                <div className="center-screen">
                    <h2 className="center-content">{"Error: " + this.errorMessage}</h2>
                </div>
            </Fragment>
        } else {
            innerView = this.safeRender()
        }
        if (this.viewOnly) {
            return <div className='center-screen'>
                {this.alertSheet}
                {innerView}
            </div>
        } else {
            return <Fragment>
                <NavigationBar loginStatus={this.loginStatus} />
                {this.alertSheet}
                <div className='full-screen-with-navbar'>
                    {innerView}
                </div>
            </Fragment>
        }
    }
    
    setState<K extends keyof S>(state: S | ((prevState: Readonly<S>, props: Readonly<P>) => S | Pick<S, K> | null) | Pick<S, K> | null, callback?: () => void): void {
        this.needsMaintenance = false
        this.isLoading = false
        super.setState(state)
    }

    /** Subclasses should overwrite this method. */
    safeRender(): React.ReactNode {
        return <Fragment />
    }

    /** Show the provided alert sheet. */
    showAlertSheet(alert: JSX.Element) {
        this.alertSheet = alert
        this.forceUpdate()
    }

    /** Dismiss any alert sheet that is showing. */
    dismissAlertSheet() {
        this.alertSheet = undefined
        this.forceUpdate()
    }
}

export default PrivateComponent;