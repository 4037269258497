import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import { Fragment, useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { DatasetMetadata, dataSource as fetchPublicDatasets } from "./DataSource"

interface Props {
    open: boolean
    onClose: () => void
    selectedDatasetId?: string
    onSelectedDataset: (dataset: DatasetMetadata) => void
}

function DatasetBrowser(props: Props) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [searchString, setSearchString] = useState("")
    const [allDatasets, setAllDatasets] = useState<DatasetMetadata[] | undefined>()
    const [myDatasets, setMyDatasets] = useState<DatasetMetadata[] | undefined>()

    useEffect(() => {
        fetchPublicDatasets().then(d => {
            setAllDatasets(d)
            setMyDatasets([])
        })
    }, [])

    const currentDataset = [allDatasets, myDatasets][selectedTabIndex]
    
    return <Dialog open={props.open} onClose={props.onClose} PaperProps={{sx: { height: "100%", maxWidth: "100%", width: "100%" }}}>
        <DialogTitle>Dataset Library</DialogTitle>
        <Stack direction="column" sx={{backgroundColor: "#f9f9f9", height: "100%"}}>
            <TextField placeholder="Search" fullWidth size="small" value={searchString} onChange={e => setSearchString(e.target.value)} />
            <Stack direction="row" flexGrow={1}>
                <Box sx={{
                    "& .MuiBox-root": {p: 1.5, borderBottom: "1px solid #e8e8e8"}, width: "200px",
                    "& .MuiBox-root.selected": {backgroundColor: "#e2e2e2"}
                }}>
                    <Box className={selectedTabIndex === 0 ? "selected" : ""} onClick={() => setSelectedTabIndex(0)}>All Datasets</Box>
                    <Box className={selectedTabIndex === 1 ? "selected" : ""} onClick={() => setSelectedTabIndex(1)} >My Datasets</Box>
                </Box>
                <Box flexGrow={1} position="relative" sx={{
                    borderLeft: "1px solid #e2e2e2"
                }}>
                    {currentDataset === undefined && <Typography className="center-content">Loading Datasets...</Typography>}
                    {currentDataset?.length === 0 && <Typography className="center-content">No Datasets</Typography>}
                    {currentDataset !== undefined && currentDataset.length > 0 && <Box width="100%" height="100%" position="absolute" sx={{backgroundColor: "#f0f0f0"}}>
                        {currentDataset!.map((ds, i) => {
                            if (!ds.name.toLowerCase().includes(searchString.toLowerCase())) {
                                return <Fragment />
                            }
                            return <Stack direction="row" sx={{ p: 2, backgroundColor: "white", m: 2, borderRadius: 3 }} alignItems="center" key={i}>
                                <Stack maxWidth="120px" maxHeight="120px" justifyContent="center">
                                    {ds.preview}
                                </Stack>
                                <Stack flexGrow={1} px={2} rowGap={"2px"}>
                                    <Typography variant="h5">{ds.name}</Typography>
                                    <Typography variant="caption"><b>Training Examples: </b>{`${ds.trainSize}`}</Typography>
                                    {ds.evalSize && <Typography variant="caption"><b>Validation Examples: </b>{`${ds.evalSize}`}</Typography>}
                                </Stack>
                                <Stack justifyContent="center">
                                    {props.selectedDatasetId === ds.id ?
                                    <Button disabled>Selected</Button>
                                    : <Button variant="soft" onClick={() => {
                                        props.onClose()
                                        setTimeout(() => props.onSelectedDataset(ds), 500)
                                    }}>Use</Button>
                                    }
                                </Stack>
                            </Stack>
                        })}
                    </Box>}
                </Box>
            </Stack>
        </Stack>
    </Dialog>
}

export default DatasetBrowser;