import { Rank, SymbolicTensor, Tensor, layers } from "@tensorflow/tfjs"
import Konva from "konva";
import Endpoint from "../Endpoint";
import Receptor from "../Receptor";
import { SquareRootLayer } from "../../Layers/Arithmetics";
import ArithmeticBlock from "./ArithmeticBlock";

class SquareRoot extends ArithmeticBlock {

    type_id = "sqrt"
    layer = new SquareRootLayer()

    get blockName() { return "Square Root Block" }
    get displayedName() { return "Square Root Block" }

    constructor(id: string) {
        super(id)
        
        this.triangle.points([0, 0, 50, 25, 0, 50])
        this.triangle.strokeWidth(2.5)

        Konva.Image.fromURL("/assets/blocks/sqrt.svg", img => {
            img.width(21)
            img.height(17)
            img.x(5)
            img.y(this.triangle.height() / 2)
            img.offsetY(img.height() / 2)
            this.element?.add(img)
        })

        this.inputs = [
            new Receptor(this, 0, 0, this.triangle.height() / 2),
        ]
        this.outputs = [
            new Endpoint(this, 0, this.triangle.width(), this.triangle.height() / 2)
        ]
    }

    getDocumentation(): string {
        return `Computes square root of the input values elementwise. Mathematically:    
$$
f(x) = \\sqrt{x}.
$$`
    }

    onInputUpdated(index: number): boolean {
        if (this.allRequiredInputsProvided) {
            this.currentValue = this.layer.apply(this.inputs[0].currentValue!) as Tensor | SymbolicTensor
        } else {
            this.currentValue = this.inputs[0].currentValue
        }
        return this.outputs[0].propagate(this.currentValue)
    }

    async loadStateDict(data: Record<string, any>): Promise<void> {
            
    }

    async getStateDict(): Promise<Record<string, any>> {
        return {}
    }
}

export default SquareRoot;