import React, { Fragment } from "react"

interface Props {
    index: number
    onIndexChanged: (i: number) => void
}

export default function InspectorMenuBar(props: Props): JSX.Element {
    const iconNames = ["description", "settings"]
    return <div className="menu-bar">
        {iconNames.map((n, i) => {
            return <img src={`/assets/${n}.svg`} key={i} className={i === props.index ? "selected" : ""} onClick={() => props.onIndexChanged(i)} />
        })}
    </div>
}