import * as tf from "@tensorflow/tfjs"
import Block from "../Block";
import Konva from "konva";
import Endpoint from "../Endpoint";
import Receptor from "../Receptor";
import { InspectorProps } from "../../Interfaces";
import { AddConstant, AddLayer } from "../../Layers/Arithmetics";
import { KonvaEventObject } from "konva/lib/Node";

class ArithmeticBlock extends Block {

    triangle: Konva.Line
    layer: tf.layers.Layer = new AddLayer()

    get displayedName() { return "Arithmetic Block" }

    constructor(id: string) {
        super(id)

        this.element = new Konva.Group({
            draggable: true,
            width: 70,
            height: 80
        })
        this.triangle = new Konva.Line({
            points: [0, 0, 80, 40, 0, 80],
            fill: "#f0f0f0",
            stroke: "black",
            strokeWidth: 3,
            closed: true
        })
        this.element.add(this.triangle)

        // Must run this after child initializes
        setTimeout(() => this.layer.name = this.type_id + "-" + id, 1)
    }

    select(e: KonvaEventObject<MouseEvent>): void {
        super.select(e)

        this.triangle.shadowColor("#70a5fd")
        this.triangle.shadowBlur(7)
        this.triangle.shadowOpacity(0.9)
    }

    unselect(): void {
        super.unselect()

        this.triangle.shadowOpacity(0)
    }

}

export default ArithmeticBlock;