import * as Konva from "konva"
import Block from './Block';
import { KonvaEventObject } from "konva/lib/Node";

class ContentBlock extends Block {
    container: Konva.default.Rect
    titleBar: Konva.default.Rect
    titleLabel: Konva.default.Text
    element: Konva.default.Group

    constructor(id: string, width: number, height: number, config?: {
        backgroundColor?: string,
        titleBarColor?: string,
        titleColor?: string
    }) {
        super(id)

        this.element = new Konva.default.Group({
            draggable: true,
            width: width,
            height: height,
            offsetY: 12
        })
        this.container = new Konva.default.Rect({
            width: width, height: height,
            cornerRadius: 8,
            fill: config?.backgroundColor ?? "#ecf0d0"
        })
        this.titleBar = new Konva.default.Rect({
            width: this.container.width(),
            height: 25,
            cornerRadius: [8, 8, 0, 0],
            fill: config?.titleBarColor ?? "#202020",
            opacity: config?.titleBarColor === undefined ? 0.15 : 1.0
        })
        this.titleLabel = new Konva.default.Text({
            width: this.container.width() - 10,
            x: 5,
            fontSize: 13,
            fill: config?.titleColor ?? "#101010",
            height: this.titleBar.height(),
            verticalAlign: "middle",
        })
        this.element.add(this.container)
        this.element.add(this.titleBar)
        this.element.add(this.titleLabel)

        this.titleLabel.text(this.displayedName)
    }

    get contentHeight(): number {
        return this.container.height() - this.titleBar.height()
    }

    select(e: KonvaEventObject<MouseEvent>): void {
        super.select(e)

        this.container.shadowColor("#70a5fd")
        this.container.shadowBlur(8)
        this.container.shadowOpacity(0.9)
    }

    unselect(): void {
        super.unselect()

        this.container.shadowOpacity(0)
    }

}

export default ContentBlock;