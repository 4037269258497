import * as React from 'react';
import * as Utils from "../../Utils"
import "./NavigationBar.css"

interface NavigationBarProps {
    loginStatus?: Utils.UserResponse | null
}
 
interface NavigationBarState {
    
}
 
class NavigationBar extends React.Component<NavigationBarProps, NavigationBarState> {
    constructor(props: NavigationBarProps) {
        super(props);
        this.state = {};
    }

    logoAndIcon(): JSX.Element {
        return (
            <a href='/' style={{display: 'flex', alignItems: 'center', textDecoration: 'none', 'userSelect': 'none'}}>
                <img src={'/assets/icon.svg'} alt='icon' height={43} width={43}/>
                <h3 className='title-text' style={{marginLeft: '10px', whiteSpace: 'nowrap'}}>{"AI Playground"}</h3>
            </a>
        )
    }

    render() { 
        return <div className='navbar-container'>
            <span style={{width: '20px'}} />
            {<this.logoAndIcon />}
        </div>;
    }
}
 
export default NavigationBar;