import { mnistData } from "../Datasets/MNIST_Dataset"
import BinaryMatrixImage from "./Exercises/Chapter3/BinaryMatrixImage"
import * as tf from "@tensorflow/tfjs"

export interface DatasetMetadata {
    id: string
    name: string
    description?: string
    trainSize: number
    evalSize?: number
    preview: JSX.Element
    inputs: { shape: tf.Shape, label: string }[]
    outputs: { shape: tf.Shape, label: string }[]
}

/* Resource: https://archive.ics.uci.edu */

export async function dataSource(): Promise<DatasetMetadata[]> {
    const mnistPreview = await mnistData(1, false)
    
    return [
        {
            id: 'mnist',
            name: "MNIST Database of Handwritten Digits",
            description: "28x28 black & white images of digits from 0-9",
            trainSize: 60000,
            evalSize: 10000,
            preview: <BinaryMatrixImage imageWidth={28} pixelSize={3.5} matrix={mnistPreview[0].image} />,
            inputs: [ {label: "Input", shape: [784]} ],
            outputs: [ {label: "Digit", shape: []} ]
        },
        {
            id: 'cifar-10',
            name: "CIFAR-10",
            description: "32x32 color images in 10 classes (airplane, automobile, bird, cat, deer, dog, frog, horse, ship, truck).",
            trainSize: 50000,
            evalSize: 10000,
            preview: <img src="/assets/cifar-10.png" width={100} height={100} style={{imageRendering: "pixelated", borderRadius: 5}} />,
            inputs: [ {label: "Input Image", shape: [3, 32, 32]} ],
            outputs: [ {label: "Class ID", shape: []}]
        }
    ]
}