import React, { Fragment, useEffect, useRef, useState } from 'react';
import "./BottomBar.css"
import { TestResult } from '../../BlockSpace/Interfaces';
import { round, sleep, tensorDescription } from '../../BlockSpace/Utils';
import { tensor } from '@tensorflow/tfjs';
import Loading from '../Loading';
import { useParams } from 'react-router-dom';
import chapters from '../../Pages/Content/Chapters';
import BinaryMatrixImage from '../../BlockSpace/Exercises/Chapter3/BinaryMatrixImage';
import { axiosInstance, getDataUrlFrom2DColorArr, getDataUrlFromArr } from '../../Utils';
import { useBoolean } from '../../use-boolean';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface BottomBarProps {
    exerciseId: string
    isExpanded: boolean
    testResult?: TestResult
    toggleExpand: () => void
    onRun: () => void
    onHeightChanged?: (height: number) => void
    nextLink?: string
    initialStars: number | null
}

const defaultBarHeight = 300
 
const BottomBar = (props: BottomBarProps) => {
    const [isResizing, setIsResizing] = useState(false)
    const lastBarHeight = useRef(defaultBarHeight)
    const [barHeight, setBarHeight] = useState(60)

    // Stars
    const showSuccessDialog = useBoolean(false)
    const [stars, setStars] = useState(props.initialStars)
    const [starAnimationState, setStarAnimationState] = useState(0)

    const { chapter, exercise } = useParams()
    const chapterData = chapters[Number(chapter) - 1]

    // Function to handle the mouse down event on the resize handle
    const handleMouseDown = (e: React.MouseEvent | MouseEvent) => {
        e.stopPropagation()
        setIsResizing(true);
    };

    // Function to handle the mouse up event to stop resizing
    const handleMouseUp = (e: React.MouseEvent | MouseEvent) => {
        e.stopPropagation()
        setIsResizing(false);
    };

    // Function to handle mouse move to resize the div
    const handleMouseMove = (e: React.MouseEvent | MouseEvent) => {
        e.stopPropagation()
        if (isResizing) {
            const newHeight = window.innerHeight - e.clientY;
            setBarHeight(Math.max(60, newHeight))

            const bar = document.querySelector(".bottom-bar") as HTMLDivElement
            if (bar) {
                props.onHeightChanged?.(bar.clientHeight)
                console.log(bar.clientHeight)
            }
        }
    };
    // Attach mousemove and mouseup event listeners when resizing
    useEffect(() => {
        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);

    // Upload autograder result when done
    useEffect(() => {
        if (props.testResult?.status === "done") {
            axiosInstance.post(`/chapters/${chapter}/exercises/${props.exerciseId}/grade`, {
                correct_count: props.testResult.current
            }).then(response => {
                console.log(response.data)

                if (response.data.original === null || response.data.stars > response.data.original) {
                    showSuccessDialog.setValue(true)
                    setStars(response.data.stars)
                    new Promise<void>(async (r, _) => {
                        for (let i = 1; i <= response.data.stars; i++) {
                            await sleep(0.8)
                            setStarAnimationState(i)
                        }
                    })
                }
            })
        }
    }, [props.testResult?.status])

    let statusMessage = "Press 'Run' to to check your answer" // `${props.totalTestCases} total test cases`
    if (props.testResult) {
        if (props.testResult.status === "running") {
            statusMessage = `Running tests ${props.testResult.current} / ${props.testResult.result.length} ...`
        } else if (props.testResult.status === "done") {
            if (props.testResult.current === props.testResult.passed) {
                statusMessage = `Yay! You passed all ${props.testResult.current} / ${props.testResult.current} tests.`
            } else {
                statusMessage = `Ran ${props.testResult.current} test cases, ${props.testResult.passed} passed`
            }
        } else if (props.testResult.status === "waiting") {
            statusMessage = "Preparing for tests..."
        }
    }
    
    let buttonName = "Run"
    if (props.testResult?.status === "done") {
        buttonName = "Run Again"
    } else if (props.testResult?.status === "running") {
        buttonName = "Stop"
    }
    return <div className='bottom-bar' style={{height: barHeight}}>
        <div className='handle' onMouseDown={(e) => handleMouseDown(e)} onMouseUp={handleMouseUp} title="Hover"/>
        <div className='status-container' onClick={() => {
            if (barHeight <= 61) {
                setBarHeight(Math.max(defaultBarHeight, lastBarHeight.current))
                props.onHeightChanged?.(Math.max(defaultBarHeight, lastBarHeight.current))
            } else {
                lastBarHeight.current = barHeight
                setBarHeight(60)
                props.onHeightChanged?.(60)
            }
        }}>
            <div style={{flexShrink: 1}}>{statusMessage}</div>
            <div style={{flexGrow: 1}} />
            <button id="submit" className={props.testResult?.status === "running" ? "running" : "normal"} disabled={props.testResult?.status === "waiting"} onClick={(e) => {
                props.onRun()
                setBarHeight(Math.max(defaultBarHeight, lastBarHeight.current))
                props.onHeightChanged?.(Math.max(defaultBarHeight, lastBarHeight.current))
                e.stopPropagation()
            }}>{buttonName}</button>
            
            {props.testResult?.passed && (props.nextLink || chapter) ? <button className='next-button' onClick={(e) => {
                e.stopPropagation()
                if (props.nextLink) {
                    window.location.assign(props.nextLink!)
                } else {
                    window.location.assign(`/chapters/${chapter}`)
                }
            }}>
                {props.nextLink ? "Next" : "Back to Chapter"}
            </button> : undefined}
        </div>
        <div className='test-cases'>
            {props.testResult?.result.map((r, i) => {
                if (r.status === "waiting" || r.status === "running") {
                    if (props.testResult?.status === "ready") { return <Fragment />}
                    return <div className='test-case' key={i}>
                        <div className='test-status'>
                            <div style={{width: 40, display: 'flex', justifyContent: 'center'}}>
                                <Loading size={22}/>
                            </div>
                            <div>{`Test ${i + 1}`}</div>
                        </div>
                   </div>
                }

                const outputString = tensorDescription(tensor(r.case.output))
                const targetString = tensorDescription(tensor(r.pred!.value))
                return <div className='test-case' key={i}>
                    <div className='test-status'>
                        <div>{`Test ${i + 1}`}</div>
                        <div style={{width: 40, display: 'flex', justifyContent: 'center'}}>
                            <img src={  r.status === "correct" ? "/assets/correct.png" : "/assets/incorrect.png"} width={25} />
                        </div>
                    </div>
                    
                    <table className='test-detail'>
                        <tbody>
                            {Array(r.case.input.length).fill(0).map((_, j) => {
                                if (r.case.inputTypes && r.case.inputTypes[j] === "grayscale_image") {
                                    return <tr key={j}>
                                        <td>{(r.case.inputNames ? r.case.inputNames[j] : `Input ${j+1}`) + " (Visual):"}</td>
                                        
                                        <td>
                                            <div className='value-cell'>
                                                {/* {<BinaryMatrixImage imageWidth={28} pixelSize={2} matrix={(r.case.input[i] as number[][])[0]}/>} */}
                                                <img src={getDataUrlFromArr((r.case.input[j] as number[]), 28, 28) as string} width={60} style={{imageRendering: "pixelated", borderRadius: 4}} />
                                            </div>
                                        </td>
                                    </tr>
                                } else if (r.case.inputTypes && r.case.inputTypes[j] === "rgb_image") {
                                    return <tr key={j}>
                                        <td>{(r.case.inputNames ? r.case.inputNames[j] : `Input ${j+1}`) + " (Visual):"}</td>
                                        
                                        <td>
                                            <div className='value-cell'>
                                                {/* {<BinaryMatrixImage imageWidth={28} pixelSize={2} matrix={(r.case.input[i] as number[][])[0]}/>} */}
                                                <img src={getDataUrlFrom2DColorArr(r.case.input[j] as number[][][]) as string} width={60} style={{imageRendering: "pixelated", borderRadius: 4}} />
                                            </div>
                                        </td>
                                    </tr>
                                }
                                return <tr key={j}>
                                    <td>{r.case.inputNames ? r.case.inputNames[j] : `Input ${j+1}:`}</td>
                                    
                                    <td>
                                        <textarea readOnly className='value-cell' value={tensorDescription(tensor(r.case.input[j]))} ref={r => {
                                            if (r) { r.style.height = 10 + r.value.split("\n").length * 16 + "px" }
                                        }}/>
                                    </td>
                                </tr>
                            })}
                            {<tr>
                                <td>Expected:</td>
                                <td>
                                    <textarea readOnly className='value-cell' value={targetString + (props.testResult?.classLabels ? ` (${props.testResult.classLabels[r.case.output as number]})` : "")} ref={r => {
                                        if (r) { r.style.height = 10 + r.value.split("\n").length * 16 + "px" }
                                    }}/>
                                </td>
                                    
                            </tr>}
                            <tr>
                                <td>Predicted:</td>
                                <td>
                                <textarea readOnly className='value-cell' style={{color: r.status === "correct" ? "var(--correct)" : "var(--incorrect)"}} value={outputString + (props.testResult?.classLabels ? ` (${props.testResult.classLabels[r.pred!.value as number]})` : "")} ref={r => {
                                        if (r) { r.style.height = 10 + r.value.split("\n").length * 16 + "px" }
                                    }}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
            }) ?? <div className='test-prompt'>Press "Run Test Cases" to run tests</div>}
        </div>

        <Dialog open={showSuccessDialog.value} onClose={showSuccessDialog.onFalse} PaperProps={{sx: {
                borderRadius: 4,
                p: 1.5
            }}} fullWidth maxWidth="sm">
                <DialogTitle sx={{pb: 0}}><b>Congratulations! You completed the challenge.</b></DialogTitle>
                <DialogContent sx={{overflow: "visible"}}>
                    <Typography pb={1}>{`Tests passed: ${round((props.testResult?.passed ?? 0) * 100 / (props.testResult?.current ?? 1), 1)}%`}</Typography>
                    <div style={{display: "flex", columnGap: "20px", justifyContent: "center", overflow: "visible"}}>
                        <AnimatedStar filled={starAnimationState >= 1} />
                        <AnimatedStar filled={starAnimationState >= 2} />
                        <AnimatedStar filled={starAnimationState >= 3} />
                    </div>
                </DialogContent>
                <DialogActions sx={{ "& button": {
                    borderRadius: 2,
                    textTransform: "none",
                    px: 2,
                    fontWeight: 500,
                    opacity: starAnimationState === stars ? 1 : 0
                }, columnGap: "8px" }}>
                    <Button sx={{
                        color: "#4f9fea",
                        border: "1px solid #7cc0ff",
                        "&:hover": { backgroundColor: "#67B5FF10" }
                    }} onClick={showSuccessDialog.onFalse}>Back to Exercise</Button>
                    {/* <Button sx={{
                        color: "white",
                        backgroundColor: "#67B5FF",
                        "&:hover": { backgroundColor: "#4f9fea" } 
                    }} onClick={this.nextChallenge}>Next Challenge</Button> */}
                </DialogActions>
            </Dialog>
    </div>;
}

function AnimatedStar(props: { filled: boolean }) {
    return <div style={{position: 'relative', width: 55, height: 55}}>
        <img src="/assets/star_filled.svg" className={`fading-star filled ${props.filled ? '' : 'hidden'}`} />
        <img src='/assets/star_empty.svg' className={`fading-star ${props.filled ? 'hidden' : ''}`} />
    </div>
}
 
export default BottomBar;