import { Container, Link } from "@mui/material";
import { round, randomInt } from "mathjs";
import React, { Fragment, LegacyRef, RefObject, useEffect, useRef } from "react"
import { createPortal } from "react-dom";
import Arrow from "@mui/icons-material/ArrowBackIosNew"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack";
import MultipleChoiceQuestion from "../MultipleChoiceQuestion/MultipleChoiceQuestion";
import { ArrowBack } from "@mui/icons-material";
import "./LessonTemplate.css"

export interface LessonTemplateProps {
    lessonNumber?: number
    title?: string
    interactionStates?: Record<string, any>
}

export interface LessonState {
    currentPage: number
}

class LessonTemplate<S extends LessonState = LessonState> extends React.Component<LessonTemplateProps, S> {
    container?: HTMLDivElement

    constructor(props: LessonTemplateProps, public pageCount: number, public lessonTitle: string) {
        super(props)
        //@ts-ignore
        this.state = { currentPage: 1 }
        this.getPageData = this.getPageData.bind(this)
        document.title = lessonTitle
    }

    getPageData(index: number) {
        return <Fragment />
    }

    render(): React.ReactNode {
        const chapter = /chapters\/(\d+)\//.exec(window.location.href)?.at(1)
        return <div className="full-screen" style={{backgroundColor: "#f3f3f3", overflowY: "auto"}} ref={r => this.container = r ?? undefined}>
            <Container maxWidth="md" sx={{position: "relative", minHeight: "100vh", paddingTop: "50px"}}>
                <Link href={`/chapters/${chapter}`} underline="none">
                    <button className="lesson-back-button"><ArrowBack fontSize="small"/> Back to Chapter Home</button>
                </Link>
                <div className="instructional-text">
                    <div className="header">
                        <h3>{this.props.lessonNumber ? `Lesson ${this.props.lessonNumber}: ` : undefined}{this.lessonTitle} {`(Page ${this.state.currentPage} of ${this.pageCount})`}</h3>
                    </div>
                    <div className="body">
                        {Array(this.pageCount).fill(0).map((_, i) => {
                            return <div key={i} style={{display: this.state.currentPage - 1 === i ? "block" : "none"}}>
                                {this.getPageData(i)}
                            </div>
                        })}

                        <Stack alignItems="center" pt={1}>
                            <Pagination count={this.pageCount} defaultValue={1} onChange={(_, page) => { 
                                this.setState({ currentPage: page }, () => {
                                    setTimeout(() => this.container!.scrollTo({top: 0, behavior: "smooth"}), 0)
                                })
                            }} />
                        </Stack>
                    </div>
                </div>
            </Container>
        </div>
    }
}

export default LessonTemplate;