import { Rank, SymbolicTensor, Tensor, layers } from "@tensorflow/tfjs"
import Block from "../Block";
import Konva from "konva";
import Endpoint from "../Endpoint";
import Receptor from "../Receptor";
import { NegationLayer, SumLayer } from "../../Layers/Arithmetics";
import { InspectorProps } from "../../Interfaces";
import { KonvaEventObject } from "konva/lib/Node";
import * as tf from "@tensorflow/tfjs"

class AggregationBlock extends Block {

    type_id = "aggregator"

    title: Konva.Text
    subtitleLabel: Konva.Text
    octagon: Konva.RegularPolygon
    layer = new SumLayer()

    get blockName() { return "Aggregator" }
    get displayedName() { return "Aggregation Block" }
    get description() {
        return `Override this`
    }
    readonly strokeColor = "#b1d6f4"

    constructor(id: string) {
        super(id)

        this.element = new Konva.Group({
            draggable: true,
            width: 80,
            height: 80,
        })

        this.octagon = new Konva.RegularPolygon({
            stroke: this.strokeColor,
            sides: 8,
            rotation: 360 / 16,
            strokeWidth: 3,
            radius: this.element.width() / 2 + 1,
            fill: "#f5faff"
        })
        this.octagon.x(40)
        this.octagon.y(40)
        this.element.add(this.octagon)

        this.title = new Konva.Text({
            text: "Name",
            fontSize: 15,
            align: "center",
            fontVariant: "Bold",
            y: 26,
            width: this.element.width() + 1
        })
        this.element?.add(this.title)

        this.subtitleLabel = new Konva.Text({
            text: this.description,
            fontSize: 12,
            width: this.title.width(),
            y: 44,
            align: "center"
        })
        this.element.add(this.subtitleLabel)

        this.inputs = [
            new Receptor(this, 0, 2, this.element.height() / 2),
        ]
        this.outputs = [
            new Endpoint(this, 0, this.element.width() - 2, this.element.height() / 2)
        ]
    }

    onInputUpdated(index: number): boolean {
        if (this.allRequiredInputsProvided) {
            try {
                this.currentValue = this.layer.apply(this.inputs[0].currentValue!) as Tensor | SymbolicTensor
                this.octagon.stroke(this.strokeColor)
            } catch {
                this.currentValue = null
                this.octagon.stroke("#f02020")
            }
        } else {
            this.octagon.stroke(this.strokeColor)
            this.currentValue = this.inputs[0].currentValue
        }
        return this.outputs[0].propagate(this.currentValue)
    }

    onClickMenu(): InspectorProps {
        let ref: HTMLInputElement | null = null
        const editArea = <input type="text" placeholder="Blank, number, or array" defaultValue={this.layer.axis ? JSON.stringify(this.layer.axis) : ""} className="custom-textarea" style={{width: "100%"}} ref={(e) => {
            ref = e
            if (e) { e.value = e.defaultValue }
        }} />
        const table = <table className='info-table'>
            <tbody>
                <tr>
                    <td>{`Axes:`}</td>
                    <td>{editArea}</td>
                </tr>
            </tbody>
        </table>
        return {
            title: this.displayedName,
            settings: table,
            buttons: [
                {
                    title: "Save",
                    type: "normal",
                    onClick: () => {
                        try {
                            if (!ref!.value) {
                                this.layer.axis = undefined
                            } else {
                                const newShape = JSON.parse(ref!.value)
                                if (typeof newShape !== "number" && !(newShape instanceof Array && !newShape.some(x => typeof x !== "number"))) {
                                    throw new Error("Shape must be number, number array, or blank")
                                }
                                this.layer.axis = newShape
                                this.subtitleLabel.text(this.description)
                            }
                            // this.subtitleLabel.text(JSON.stringify(this.shape))
                            this.onInputUpdated(0)
                            this.outputs[0].propagate(this.currentValue)
                            this.updateReceptorsAndEndpoints()
                            this.globalState.visitedReceptorCount.clear()
                            return true
                        } catch (error: any) {
                            this.globalState.visitedReceptorCount.clear()
                            if (error instanceof Error) {
                                alert(error.message)
                            } else {
                                alert("Invalid shape.")
                            }
                            console.warn(error)
                            return false
                        }
                    }
                }
            ],
            docs: this.documentation
        }
    }

    select(e: KonvaEventObject<MouseEvent>): void {
        super.select(e)

        this.octagon.shadowColor("#70d0f0")
        this.octagon.shadowBlur(8)
        this.octagon.shadowOpacity(0.9)
    }

    unselect(): void {
        super.unselect()

        this.octagon.shadowOpacity(0)
    }

    async getStateDict(): Promise<Record<string, any>> {
        return {
            axis: this.layer.axis
        }
    }

    async loadStateDict(data: Record<string, any>): Promise<void> {
        try {
            this.layer.axis = data?.axis ?? null
            this.subtitleLabel.text(this.description)
        } catch (error) {
            console.warn(`Failed to load state dict for ${this.blockName}`, data, error)
        }
    }
}

export default AggregationBlock;